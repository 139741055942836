import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Bank } from '../issuers/shared/banks.types';
import { BankService } from '../shared/bank.service';
import { AccountModalConfigData, BankAccount } from './shared/accounts.types';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AccountModalComponent } from './account-modal/account-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit, OnDestroy {
    public banks: Bank[] = [];
    public bankControl = new FormControl<Bank | undefined>(undefined);
    public bankFilterCtrl: FormControl = new FormControl();
    public filteredBanks: BehaviorSubject<Bank[]> = new BehaviorSubject<Bank[]>([]);
    public showAccounts: boolean;
    private componentSubscriptions: Subscription[] = [];
    public availableBankAccounts: BankAccount[];

    public accountModal: MatDialogRef<AccountModalComponent>;
    public modalDialog: MatDialogRef<AccountModalComponent> | undefined;
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '300px',
        minHeight: '250px',
    };

    constructor(
        private bankService: BankService,
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
    ) {
        this.getBanks();
    }

    ngOnInit(): void {
        this.showAccounts = false;
        this.componentSubscriptions.push(
            this.bankFilterCtrl.valueChanges.subscribe((filterValue: string) => {
                this.filterBanks(filterValue);
            }),
        );
        this.componentSubscriptions.push(
            this.bankControl.valueChanges.subscribe((bank: Bank) => {
                this.showAccounts = true;
                this.updateAccountList(bank.id);
            }),
        );
    }

    ngOnDestroy() {
        for (let subscription of this.componentSubscriptions) {
            subscription.unsubscribe();
        }
    }

    private filterBanks(filterValue: string) {
        if (!this.banks) {
            return;
        }
        if (!filterValue) {
            this.filteredBanks.next(this.banks.slice());
            return;
        } else {
            filterValue = filterValue.toLowerCase();
        }
        // filter the banks
        this.filteredBanks.next(this.banks.filter((bank) => bank.display_name.toLowerCase().indexOf(filterValue) > -1));
    }

    private getBanks() {
        this.bankService.getBanks().subscribe({
            next: (res) => {
                res.forEach((bank) => {
                    this.banks.push(bank);
                });
                this.filteredBanks.next(this.banks);
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
            },
        });
    }

    private updateAccountList(bankID: number) {
        this.showAccounts = true;

        // Fetch the bank accounts for the selected bank
        this.bankService.getBankAccountsByBankID(bankID).subscribe({
            next: (bankAccounts) => {
                this.availableBankAccounts = bankAccounts;
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
            },
        });
    }

    protected addAccount() {
        const addAccountConfig: MatDialogConfig<AccountModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Add Account',
                selectedBank: this.bankControl.value.id,
                action: 'create',
            },
        };
        this.openModal(addAccountConfig);
    }

    protected editAccount(account) {
        const addAccountConfig: MatDialogConfig<AccountModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Edit Account',
                selectedBank: this.bankControl.value.id,
                selectedAccount: account,
                action: 'edit',
            },
        };
        this.openModal(addAccountConfig);
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.accountModal = this.matDialog.open(AccountModalComponent, modalConfig);
        this.accountModal.afterClosed().subscribe((res: { updateData: boolean; areChangesSaved: boolean }) => {
            if (!res.areChangesSaved) {
                this.snackBar.open('Last changes were not saved and have been discarded', undefined, {
                    duration: 1500,
                });
            }
            if (res.updateData) {
                let message;
                if (modalConfig.data.action === 'create') {
                    message = 'Account has been created successfully';
                } else if (modalConfig.data.action === 'edit') {
                    message =
                        'Account has been updated successfully. all currently associated issuers will have the updated bank account number';
                } else if (modalConfig.data.action === 'delete') {
                    message = 'Account has been deleted successfully';
                }

                this.snackBar.open(message, undefined, {
                    duration: 1500,
                });
                setTimeout(() => {
                    this.snackBar.open('Fetching Account list after most recent edits', undefined, {
                        duration: 3000,
                    });
                    this.updateAccountList(this.bankControl.value.id);
                }, 1500);
            }
        });
    }

    protected deleteAccount(account) {
        const deleteAccountConfig: MatDialogConfig<AccountModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Delete Account',
                selectedBank: this.bankControl.value.id,
                selectedAccount: account,
                action: 'delete',
            },
        };
        this.openModal(deleteAccountConfig);
    }
}
