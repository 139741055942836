export interface IConfiguration {
    envConfig: IEnvConfiguration;
    oidcConfig: IOidcConfiguration;
}

export interface IEnvConfiguration {
    host: string;
    bankPaymentsHost: string;
    bankValidationHost: string;
    mockHost: string;
    environment: string;
    flags: IFlagsConfiguration;
    brokerageAccountsHost: string;
    brokerageAccountsApiVer: string;
    vendorPaymentsHost: string;
    vendorPaymentsApiVer: string;
    internalAdminHost: string;
    nsCustomerUrl: string;
    nsContactUrl: string;
    sfOpportunityUrl: string;
    bfdFileExplorerUrl?: string;
    sessionTimeoutDestinationUrl?: string;
}

export interface IOidcConfiguration {
    issuer: string;
    clientId: string;
    showDebugInformation: boolean;
}

export interface IFlagsConfiguration {
    feature: {
        [featureName: string]: number; // 0: disabled, 1: enabled, 2: maintenance
    };
}

export enum FlagStatus {
    'Disabled',
    'Enabled',
    'Maintenance',
}
