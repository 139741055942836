<mat-card appearance="outlined" class="mat-card">
    <div id="title-bar" class="title-bar">
        <mat-card-title>
            {{ modalData.title }}
        </mat-card-title>
        <div class="dismiss-div">
            <span id="x-dismiss"
                  (click)="closeModal()"
                  class="material-symbols-outlined x-dismiss"
                  data-testid="x-dismiss">
                close
            </span>
        </div>
    </div>
    <div class="card__container">
        <ng-container
            *ngTemplateOutlet="reviewVendorSection"
        ></ng-container>
    </div>
</mat-card>

<ng-template #reviewVendorSection>
    <mat-card-content>
        <div id="subtitle" class="subtitle">{{ modalData.selected.payeeName}} / {{modalData.selected.accountNumber}}{{modalData.selected.abaSwift}}</div>
        <div id="button-frame" class="button-frame">
            <button *ngIf="inEditMode"
                class="modal-button"
                mat-flat-button
                data-testid="btnCancel"
                (click)="cancelEdit()"
            >
                <div class="icon-button">
                    <span class="modal-button-label">Cancel</span>
                </div>
            </button>
            <button
                class="modal-button"
                [class.modal-button-primary]="inEditMode"
                [class.modal-button-primary-disabled]="isPrimaryDisabled()"
                mat-flat-button
                data-testid="btnEdit"
                [disabled]="isPrimaryDisabled()"
                (click)="submit()"
            >
                <div class="icon-button">
                    <mat-icon *ngIf="!inEditMode">edit</mat-icon>
                    <span class="modal-button-label">{{primaryLabel}}</span>
                </div>
            </button>
        </div>
        <div id="content-area" class="content-area">
            <div id="banking-details-frame" class="details-frame">
                <div class="details-title">Banking Details</div>
                <div class="details-table">
                    <form id="banking-details-form" class="vendor-form" [formGroup]="form">
                        <table mat-table [dataSource]="bankingDetailsDataSource" class="inner-table">
                            <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of bankingDetailsColumns">
                                <th mat-header-cell *matHeaderCellDef> {{ColumnHeaders[innerColumn]}} </th>
                                <td mat-cell *matCellDef="let element; index as i;">
                                    <div *ngIf="inEditMode && i === 0 && !readOnlyFields.includes(innerColumn)"
                                         [attr.data-testid]="'input-' + innerColumn"
                                         class="vendor-info"
                                    >
                                        <mat-form-field class="vendor-input-field">
                                            <input [id]="innerColumn" matInput type="text" formControlName="{{innerColumn}}"/>
                                            <mat-error *ngIf="checkField(innerColumn).includes('required')">
                                                Required
                                            </mat-error>
                                            <mat-error *ngIf="checkField(innerColumn).includes('maxLength')">
                                                Max length exceeded
                                            </mat-error>
                                            <mat-error *ngIf="checkField(innerColumn).includes('minLength')">
                                                Minimum length not met
                                            </mat-error>
                                            <mat-error *ngIf="checkField(innerColumn).includes('pattern')">
                                                Invalid characters or length error
                                            </mat-error>
                                        </mat-form-field>
                   
                                    </div>
                                    <span *ngIf="!inEditMode || i > 0 || readOnlyFields.includes(innerColumn)"
                                          [matTooltip]="getRelatedValueCount(innerColumn, element[innerColumn])"
                                          matTooltipPosition="below"
                                    >{{element[innerColumn]}}</span>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="bankingDetailsColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: bankingDetailsColumns"></tr>
                        </table>
                    </form>
                </div>
            </div>
            <div id="payee-details-frame" class="details-frame">
                <div class="details-title">Payee Details</div>
                <div class="details-table">
                    <form id="payee-details-form" class="vendor-form" [formGroup]="form">
                        <table mat-table [dataSource]="payeeDetailsDataSource" class="inner-table">
                            <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of payeeDetailsColumns">
                                <th mat-header-cell *matHeaderCellDef> {{ColumnHeaders[innerColumn]}} </th>
                                <td mat-cell *matCellDef="let element; index as i;">
                                    <div *ngIf="inEditMode && i === 0 && !readOnlyFields.includes(innerColumn)"
                                         class="vendor-info"
                                    >
                                        <mat-form-field class="vendor-input-field">
                                            <input [id]="innerColumn" matInput type="text" formControlName="{{innerColumn}}"/>
                                            <mat-error *ngIf="checkField(innerColumn).includes('required')">
                                                Required
                                            </mat-error>
                                            <mat-error *ngIf="checkField(innerColumn).includes('maxLength')">
                                                Max length exceeded
                                            </mat-error>
                                            <mat-error *ngIf="checkField(innerColumn).includes('minLength')">
                                                Minimum length not met
                                            </mat-error>
                                            <mat-error *ngIf="checkField(innerColumn).includes('pattern')">
                                                Invalid characters or length error
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                    <span *ngIf="!inEditMode || i > 0 || readOnlyFields.includes(innerColumn)"
                                          [matTooltip]="getRelatedValueCount(innerColumn, element[innerColumn])"
                                          matTooltipPosition="below"
                                    >{{element[innerColumn]}}</span>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="payeeDetailsColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: payeeDetailsColumns"></tr>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button
            class="modal-button"
            mat-flat-button
            (click)="closeModal()"
            matTooltipPosition="before"
            data-testid="btnClose"
        >
            Close
        </button>
    </mat-card-actions>
</ng-template>
