import { Component, ViewChild, Input, TemplateRef, ElementRef, Output, EventEmitter, OnChanges } from '@angular/core';
import {
    IssuerAndAccountsListItem,
    TableDataTemplate,
    TableHeaderTemplate,
} from '../../lance/issuers/shared/issuers.types';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'primeng/table';
import { PortfolioTableListItem } from '../../lance/portfolios/shared/portfolios.types';

export type RowAction = {
    backgroundColor: 'primary' | 'accent' | 'warn';
    icon: string;
    action: Function;
};

export type TableAction = {
    backgroundColor: 'primary' | 'accent' | 'warn';
    text: string;
    action: Function;
};

@Component({
    selector: 'app-table-template',
    templateUrl: './table-template.component.html',
    styleUrls: ['./table-template.component.scss'],
})
export class TableTemplateComponent implements OnChanges {
    @Input() TableTitle: string;
    @Input() TableData: TableDataTemplate[] | IssuerAndAccountsListItem[] | PortfolioTableListItem[];
    @Input() TableHeaders: TableHeaderTemplate[];
    @Input() BankId: string;

    @Input() RowActions: RowAction[];
    @Input() TableActions: TableAction[];

    @Input() result: string;

    @ViewChild('tableContainer') tableContainer: TemplateRef<ElementRef>;
    @ViewChild('dt') tableDetail: Table;

    public selectedRows: TableDataTemplate[] = [];

    constructor(public matDialog: MatDialog) {}

    ngOnChanges(): void {
        if (this.tableDetail) {
            this.tableDetail.reset();
        }
    }

    deleteSelectedRows() {
        // this.dialogConfig.id = this.TableTitle;
        // this.dialogConfig.minHeight = '200px';
        // this.dialogConfig.minWidth = '800px';
        // this.dialogConfig.maxHeight = '800px';
        // this.dialogConfig.panelClass = 'mat-dialog';
        // this.modalDialog = this.matDialog.open(PortfolioModalComponent, this.dialogConfig);
    }
}
