<mat-card>
    <mat-card-title>{{ accountModalData.title }}</mat-card-title>
    <div class="card__container">
        <ng-container
            *ngTemplateOutlet="accountModalData.action === 'delete' ? deleteAccountSection : createEditAccountSection"
        ></ng-container>
    </div>
</mat-card>

<ng-template #createEditAccountSection>
    <mat-card-content>
        <div class="account-details">
            <h3>Account Details</h3>
            <form class="account-form" [formGroup]="accountForm">
                <div class="account-info">
                    <mat-form-field>
                        <mat-label>Currency</mat-label>
                        <mat-select [formControl]="accountForm.controls.accountCurrency">
                            <mat-option>
                                <ngx-mat-select-search
                                    placeholderLabel="Currency"
                                    [formControl]="currenciesFilterControl"
                                ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                *ngFor="let currency of filteredCurrencies | async"
                                [value]="currency"
                            >
                                {{currency.label}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="accountForm.controls.accountCurrency.hasError('required')">
                            Account Currency is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>                    
                    <mat-form-field>
                        <mat-label>Account Number</mat-label>
                        <input matInput type="text" formControlName="accountNumber" />
                        <mat-error *ngIf="accountForm.controls.accountNumber.hasError('required')">
                            Account Number is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="accountForm.controls.accountNumber.hasError('pattern')">
                            Account Number must be a <strong>number</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Account Name</mat-label>
                        <input matInput type="text" formControlName="accountName" />
                    </mat-form-field>
                </div>
                <div class="address">
                    <div class="address-street">
                        <mat-form-field>
                            <mat-label>Address 1</mat-label>
                            <input matInput type="text" formControlName="accountAddress1" />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Address 2</mat-label>
                            <input matInput type="text" formControlName="accountAddress2" />
                        </mat-form-field>
                    </div>
                    <div class="address-other">
                        <mat-form-field>
                            <mat-label>City</mat-label>
                            <input matInput type="text" formControlName="accountCity" />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>State</mat-label>
                            <mat-select [formControl]="accountForm.controls.accountState">
                                <mat-option>
                                    <ngx-mat-select-search
                                        placeholderLabel="State"
                                        [formControl]="statesFilterControl"
                                    ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option
                                    *ngFor="let state of filteredStates | async"
                                    [value]="state"
                                >
                                    {{state.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Zip Code</mat-label>
                            <input matInput type="text" formControlName="accountZipCode" />
                        </mat-form-field> 
                    </div>           
                </div>
                <div *ngIf="accountForm.errors?.addressError" class="address-error">{{accountForm.errors.addressError}}</div>
            </form>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button type="submit" (click)="saveAccount()" mat-flat-button color="primary" [disabled]="!accountForm.valid">Submit</button>
        <button
            mat-flat-button
            [color]="(areChangesSaved | async) ? 'primary' : 'warn'"
            (click)="closeModal()"
            [matTooltip]="modalTooltip"
            [matTooltipDisabled]="areChangesSaved | async"
            matTooltipPosition="before"
        >
            Close
        </button>
    </mat-card-actions>
</ng-template>

<!--TODO: Rely on this modal for deleting account -->
<ng-template #deleteAccountSection>
    <mat-card-content> 
        <div class="modal-body">
            Are you sure to delete the account: <span class="account-info">{{accountModalData.selectedAccount.name}}</span>
            (Account Number: {{accountModalData.selectedAccount.number}})
            <br><br> Please do note that the associated issuer relationships will be removed with this account deletion.
            <br><br>
        </div>
    </mat-card-content>
    <mat-card-actions class="modal-footer">
            <button mat-flat-button (click)="closeModal()"  mat-flat-button color="primary">Cancel</button>
            <button mat-flat-button (click)="deleteAccount()"  mat-flat-button color="primary">Submit</button>
    </mat-card-actions>
</ng-template>
