import { AbstractControl, FormControl } from '@angular/forms';

export interface UserRecord {
    id?: number;
    nsId?: number;
    nsLastModified?: number;
    firstName: string;
    lastName: string;
    status?: string;
    email: string;
    phoneNr: string;
    products?: ProductsUi[];
    auth0Id: string;
    role?: string;
}

export interface UserDeal {
    user: UserRecord;
    previousDealIds?: number[];
    dealIds: number[];
    roleId: number;
    byId: string;
    byName: string;
}

export interface UserDealRel {
    id: number;
    parent_id: number;
    parent_type: string;
    child_id: number;
    child_type: string;
    role_id: number;
}

export type ProductsUi = {
    id: number;
    name: string;
    abbr: string;
};

export const UserSearchColumns = ['email', 'firstName', 'lastName', 'status'];
export const UserSearchColumnsFromActivity = ['email', 'firstName', 'lastName'];

export enum UserStatus {
    Invited = 'invited',
    Active = 'active',
    Blocked = 'blocked',
    Deactivated = 'deactivated',
}

export enum UserActionEnum {
    activity = 'activity',
    resetMfa = 'resetMfa',
    sendPwReset = 'sendPwReset',
    deactivate = 'deactivate',
    resendInv = 'resentInv',
    revokeAccess = 'revokeAccess',
    revokeAllAccess = 'revokeAllAccess',
    block = 'block',
    unblock = 'unblock',
}

export enum ModalConfigButtonClick {
    Cancel = 'cancel',
    Confirm = 'confirm',
}

export type ModalConfigData = {
    title?: string;
    userName?: string;
    dealName?: string;
    messageHTML?: string;
    resetPwLink?: string;
    action?: UserActionEnum;
    id?: number;
    email?: string;
    deactivateFromUserTable?: boolean;
};

export type ModalCloseConfig = {
    action: UserActionEnum;
    buttonClick: ModalConfigButtonClick;
    id: number;
};

export type UserActionConfig = {
    arrayIndex: number;
    action: UserActionEnum;
};

export enum UserAuth0Actions {
    resetMfa = 'resetMfa',
    sendPwReset = 'sendPwReset',
    deactivate = 'deactivate',
    resendInv = 'resentInv',
    block = 'block',
}

export type Auth0APIConfig = {
    userId: number;
    action: UserAuth0Actions;
    byId: string;
    byName: string;
};

export type DealAccessFormType = AbstractControl<{
    deal: FormControl<string | null>;
    role: FormControl<number | null>;
}>;

export type ShareholderAccessFormType = AbstractControl<{
    shareholder: FormControl<string | null>;
    deal: FormControl<string | null>;
    role: FormControl<number | null>;
}>;
