import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    AdminRequestHelper,
    Auth0APIConfig,
    DealAccessFormType,
    DealRecord,
    DealSearchColumns,
    GetType,
    IntAdmPermissions,
    InternalAdminService,
    ModalCloseConfig,
    ModalConfigData,
    RequestDealUserRole,
    RoleRecord,
    ShareholderAccessFormType,
    ShareholderDealUser,
    ShareholderRecord,
    ShareholderSearchColumns,
    UserActionEnum,
    UserDeal,
    UserDealRel,
    UserHelperService,
    UserRecord,
    UserSearchColumns,
} from '../shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InternalAdminModalComponent } from '../internal-admin-modal/internal-admin-modal.component';
import { InternalAdminSnackbarComponent } from '../internal-admin-snackbar/internal-admin-snackbar.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from '../../core/services/configuration.service';
import { AccessTypes, AuthService } from '../../core/services/auth.service';
import { UserAvailableColumns } from '../user-table/user-table.column.types';
import { AvailableColumns } from '../shareholder-deal-user-table/shareholder-deal-user-table.columns.types';
import { DealAvailableColumns } from '../deal-table/deal-table.column.types';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
    @ViewChild('dealDashboardDealInput') dealDashboardDealInput: ElementRef<HTMLInputElement>;
    @ViewChild('shareholderInput') shareholderInput: ElementRef<HTMLInputElement>;
    @ViewChild('shareholderDealInput') shareholderDealInput: ElementRef<HTMLInputElement>;
    public userRecords: UserRecord[];
    public addExistingUserRecords: UserRecord[];
    public dealRecords: DealRecord[];
    public singleUserAllDealRecords: DealRecord[];
    public allUsersEditDealRecords: DealRecord[];
    public singleUserEditDealRecords: DealRecord[];
    public addExistingUserDealRecords: DealRecord[];
    public shareholderRecords: ShareholderRecord[];
    public userDealRelRecords: UserDealRel[];
    // Single User ng-component
    public selectedUserId: number = null;
    public selectedUser: UserRecord = null;
    public showCreateUser: boolean = false;
    public showRevokeAccess: boolean;
    public selectedDealId: number = null;
    public userToEdit: UserRecord;
    public editMode: boolean;
    public addExistingUserMode: boolean;
    public allUsersEditMode: boolean;
    public singleUserEditMode: boolean;

    public searchValue: string;
    public searchValueDeals: string;
    public page: number;
    public pageDeals: number;
    public pageDealsUsers: number;
    public pageSize: number;
    public pageSizeDeals: number;
    public pageSizeDealsUsers: number;
    public totalRecords: number;
    public totalShareholderRecords: number;
    public totalRecordsDeals: number;
    public totalRecordsDealsUsers: number;
    public internalAdminModal: MatDialogRef<InternalAdminModalComponent>;
    public permissionCreateUser = IntAdmPermissions.userCreate;
    public permissionAddUser = IntAdmPermissions.userAdd;
    public permissionEditUser = IntAdmPermissions.userEdit;
    public permissionBlockUser = IntAdmPermissions.userBlock;
    public permissionUnblockUser = IntAdmPermissions.userUnblock;
    public permissionDeactivateUser = IntAdmPermissions.userDeactivate;
    public permissionSendPwResetUser = IntAdmPermissions.userSendPwReset;
    public permissionResendInvUser = IntAdmPermissions.userResendInv;
    public permissionResetMfaUser = IntAdmPermissions.userResetMfa;
    public fetching = true;
    public fetchingDealsUsers = true;
    public shareholderDealUserRecords: ShareholderDealUser[];
    public searchValueDealsUsers: string;
    public userDisplayedColumns: string[] = [
        UserAvailableColumns.Email,
        UserAvailableColumns.User,
        UserAvailableColumns.Status,
        UserAvailableColumns.Products,
        UserAvailableColumns.Actions,
    ];
    public dealDisplayedColumns: string[] = [
        DealAvailableColumns.Name,
        DealAvailableColumns.Seller,
        DealAvailableColumns.Buyer,
        DealAvailableColumns.Role,
        DealAvailableColumns.ClosingDate,
    ];
    public shareholderDealUserDisplayedColumns: string[] = [
        AvailableColumns.Deal,
        AvailableColumns.Permission,
        AvailableColumns.Shareholder,
        AvailableColumns.Actions,
    ];
    public selectedIndex = 0;

    private requestHelper: AdminRequestHelper;
    private requestHelperDeals: AdminRequestHelper;
    private requestHelperDealsUsers: AdminRequestHelper;
    private requestHelperRoles: AdminRequestHelper;

    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '500px',
        minHeight: '200px',
        autoFocus: 'first-header',
    };

    public filteredDealDashboardDeals: Observable<string[] | DealRecord[]>;
    public filteredShareholderRecords: Observable<string[] | ShareholderRecord[]>;
    public filteredShareholderPortalDeals: Observable<string[] | DealRecord[]>;
    public allDealDashboardDeals: string[] = [];
    public allShareholderPortalDeals: string[] = [];
    public allShareholderRecords: string[] = [];
    public dealDashboardDealsRow: string[][] = [];
    public shareholderPortalAccessRow: string[][] = [];
    public shareholderPortalDeals: string[] = [];
    public shareholderPortalShareholders: string[] = [];
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    public removeDealChipCalled: boolean = false;
    public selectedCalled: boolean = false;

    identifyDeal(index, deal) {
        return deal;
    }
    identifyShareholder(index, shareholder) {
        return shareholder;
    }
    public currentDealDashboardRole: RoleRecord;
    public currentShareholderPortalRole: RoleRecord;
    public roleRecords: RoleRecord[];

    public dealDashboardCurrentRoles: RoleRecord[] = [];
    public shareholderPortalCurrentRoles: RoleRecord[] = [];

    // these roles will need to be entered into the database, or
    // if we are retrieving them from another source we should return them in the
    // roles services in ms-internal-admin
    public shareholderRoleRecords: RoleRecord[] = [
        {
            id: 1,
            name: 'Administrator',
            description: '',
        },
        {
            id: 2,
            name: 'Inviter',
            description: '',
        },
        {
            id: 3,
            name: 'Owner',
            description: '',
        },
        {
            id: 4,
            name: 'Viewer',
            description: '',
        },
    ];

    public userForm = new FormGroup({
        firstName: new FormControl<String>(null, [Validators.required]),
        lastName: new FormControl<String>(null, [Validators.required]),
        email: new FormControl<String>(null, [Validators.required, Validators.email]),
        phoneNumber: new FormControl<String>(null),
        dealAccess: new FormArray<DealAccessFormType>([]),
        shareholderAccess: new FormArray<ShareholderAccessFormType>([]),
    });

    get dealAccess() {
        return this.userForm.controls['dealAccess'] as FormArray;
    }

    get shareholderAccess() {
        return this.userForm.controls['shareholderAccess'] as FormArray;
    }

    constructor(
        private internalAdminService: InternalAdminService,
        private snackBar: MatSnackBar,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userHelperService: UserHelperService,
        private matDialog: MatDialog,
        private configurationService: ConfigurationService,
        private authService: AuthService,
    ) {}

    public filterDealDashboardDeals(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allDealDashboardDeals.filter((dealName) => dealName.toLowerCase().includes(filterValue));
    }

    public filterShareholderRecords(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allShareholderRecords.filter((shareholderName) =>
            shareholderName.toLowerCase().includes(filterValue),
        );
    }

    public filterShareholderPortalDeals(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allShareholderPortalDeals.filter((dealName) => dealName.toLowerCase().includes(filterValue));
    }

    ngOnInit(): void {
        this.showRevokeAccess = true;
        this.requestHelper = new AdminRequestHelper(GetType.users);
        this.requestHelperDeals = new AdminRequestHelper(GetType.deals);
        this.requestHelperDealsUsers = new AdminRequestHelper(GetType.shareholders);
        this.requestHelperRoles = new AdminRequestHelper(GetType.roles);
        this.page = this.requestHelper.page;
        this.pageDeals = this.requestHelperDeals.page;
        this.pageDealsUsers = this.requestHelperDealsUsers.page;
        this.pageSize = this.requestHelper.size;
        this.pageSizeDeals = this.requestHelperDeals.size;
        this.pageSizeDealsUsers = this.requestHelperDealsUsers.size;

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                if (!params) {
                    return;
                } else if (params['clear']) {
                    this.selectedUserId = null;
                    this.selectedUser = null;
                } else if (params['userId']) {
                    this.selectedUserId = params['userId'];
                    this.fetchSingleUser(this.selectedUserId);

                    this.requestHelperDeals.uid = params['userId'];
                    this.requestHelperDeals.page = 0;
                    this.fetchDeals();
                }
            },
            error: (error) => this.handleError(error),
        });

        this.fetchUsers();
        this.fetchDeals();
        // this.fetchSingleShareholder(1);
        this.fetchRoles();
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;

        if (this.searchValue && this.searchValue.trim().length > 0) {
            this.requestHelper.srchVal = this.searchValue;
            this.requestHelper.srchFields = UserSearchColumns;
        } else {
            this.requestHelper.srchVal = null;
            this.requestHelper.srchFields = null;
        }
        this.requestHelper.page = 0;

        this.fetchUsers();
    }

    public performSearchDeals(searchVal: string) {
        this.searchValueDeals = searchVal;

        if (this.searchValueDeals && this.searchValueDeals.trim().length > 0) {
            this.requestHelperDeals.srchVal = this.searchValueDeals;
            this.requestHelperDeals.srchFields = DealSearchColumns;
        } else {
            this.requestHelperDeals.srchVal = null;
            this.requestHelperDeals.srchFields = null;
        }
        this.requestHelperDeals.page = 0;
        this.requestHelperDeals.uid = this.selectedUser.id;

        this.fetchDeals();
    }

    public performSearchDealsUsers(searchVal: string) {
        this.searchValueDealsUsers = searchVal;

        if (this.searchValueDealsUsers && this.searchValueDealsUsers.trim().length > 0) {
            this.requestHelperDealsUsers.srchVal = this.searchValueDealsUsers;
            this.requestHelperDealsUsers.srchFields = ShareholderSearchColumns;
        } else {
            this.requestHelperDealsUsers.srchVal = null;
            this.requestHelperDealsUsers.srchFields = null;
        }
        // What if they had already initiated a search, paged to page N, then
        // adjusted the search value. Should it be reset to 0 again?
        this.requestHelperDealsUsers.page = 0;

        this.fetchShareholdersDealsUsers();
    }

    public handlePageEvent(e: PageEvent) {
        this.requestHelper.size = e.pageSize;
        this.requestHelper.page = e.pageIndex;

        this.fetchUsers();
    }

    public handlePageEventDeals(e: PageEvent) {
        this.requestHelperDeals.size = e.pageSize;
        this.requestHelperDeals.page = e.pageIndex;

        this.fetchDeals();
    }

    public handleSortChange(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelper.sortField = s.active;
            this.requestHelper.sortDir = s.direction;
            // Reset the page back to 0
            this.requestHelper.page = 0;

            this.fetchUsers();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelper.sortField = null;
            this.requestHelper.sortDir = null;
        }
    }

    public handleSortChangeDeals(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelperDeals.sortField = s.active;
            this.requestHelperDeals.sortDir = s.direction;
            // Reset the page back to 0
            this.requestHelperDeals.page = 0;

            this.fetchDeals();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperDeals.sortField = null;
            this.requestHelperDeals.sortDir = null;
        }
    }

    public handleUserSelected(uid: number) {
        this.selectedUser = this.userRecords.find((user) => user.id === uid);
        this.selectedUser.nsId = 2312000;
        this.selectedUserId = uid;

        this.requestHelperDeals.uid = uid;
        this.requestHelperDeals.page = 0;

        // AKA Shareholders
        this.requestHelperDealsUsers.uid = uid;
        this.requestHelperDealsUsers.page = 0;

        this.fetchDeals();
        this.fetchShareholders();
        this.router.navigate(['/internal-admin/users'], {
            queryParams: { clear: null },
            queryParamsHandling: 'merge',
        });
    }

    public handleDealSelected(did: number) {
        const temp = this.dealRecords.find((deal) => deal.id === did);

        this.router.navigate(['/internal-admin/deals/'], {
            queryParams: { dealId: did },
        });
    }

    /**
     * Here the $event is the index of the deal record.
     */
    public handleDealActivityNavigation($event) {
        const selectedDeal = this.dealRecords[$event];

        this.router.navigate(['/internal-admin/activity'], {
            queryParams: {
                dealName: selectedDeal.name,
            },
        });
    }

    public handleDealDashboardNavigation($event) {
        // const selectedDeal = this.dealRecords[$event];
        // this.dealDashboardClick(selectedDeal.id);
    }

    public handleUserAction($event) {
        switch ($event.action) {
            case UserActionEnum.activity: {
                const selectedUser = this.userRecords[$event.arrayIndex];

                this.handleUserActivityClick(selectedUser.firstName + ' ' + selectedUser.lastName);

                break;
            }
            case UserActionEnum.resetMfa: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Reset MFA Method',
                        messageHTML: `Would you like to reset the Multi-Factor Authentication method for <b>${name}</b>?`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.resetMfa,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.sendPwReset: {
                const userName =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const tempConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Reset Password',
                        messageHTML: `Would you like to send a password reset for <b>${userName}</b>?`,
                        userName,
                        dealName: null,
                        action: UserActionEnum.sendPwReset,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(tempConfig);

                break;
            }
            case UserActionEnum.resendInv: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Resend Invitation',
                        messageHTML: `Would you like to resend the Deal Dashboard invitation to <b>${name}</b>?`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.resendInv,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.deactivate: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Confirm Account Deactivation',
                        messageHTML: `This will revoke all access and deactivate <b>${name}</b>.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.deactivate,
                        id: this.userRecords[$event.arrayIndex].id,
                        deactivateFromUserTable: true,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.block: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Confirm Authentication Block',
                        messageHTML: `This will “block” the user in Auth0 without modifying deal permissions.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.block,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.unblock: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Unblock Authentication',
                        messageHTML: `This will remove the Blocked status in Auth0, allowing users to authenticate.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.unblock,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
        }
    }

    public canEditUser(status: string): boolean {
        const canEditUser = this.userHelperService.canEditUser(status);
        const perm = this.authService.checkAccess(this.permissionEditUser) === AccessTypes.FULL;
        return canEditUser && perm;
    }

    public canViewUserActivity(status: string): boolean {
        return this.userHelperService.canViewUserActivity(status);
    }

    public canResetMFA(status: string): boolean {
        const canResetMFA = this.userHelperService.canResetMFA(status);
        const perm = this.authService.checkAccess(this.permissionResetMfaUser) === AccessTypes.FULL;
        return canResetMFA && perm;
    }

    public canResendInvitation(status: string): boolean {
        const canResendInvitation = this.userHelperService.canResendInvitation(status);
        const perm = this.authService.checkAccess(this.permissionResendInvUser) === AccessTypes.FULL;
        return canResendInvitation && perm;
    }

    public canSendPwReset(status: string): boolean {
        const canSendPwReset = this.userHelperService.canSendPwReset(status);
        const perm = this.authService.checkAccess(this.permissionSendPwResetUser) === AccessTypes.FULL;
        return canSendPwReset && perm;
    }

    public canBlock(status: string): boolean {
        const canBlock = this.userHelperService.canBlock(status);
        const perm = this.authService.checkAccess(this.permissionBlockUser) === AccessTypes.FULL;
        return canBlock && perm;
    }

    public canUnblock(status: string): boolean {
        const canUnblock = this.userHelperService.canUnblock(status);
        const perm = this.authService.checkAccess(this.permissionUnblockUser) === AccessTypes.FULL;
        return canUnblock && perm;
    }

    public canDeactivate(status: string): boolean {
        const canDeactivate = this.userHelperService.canDeactivate(status);
        const perm = this.authService.checkAccess(this.permissionDeactivateUser) === AccessTypes.FULL;
        return canDeactivate && perm;
    }

    public showAddUserMenu(): boolean {
        return (
            this.authService.checkAccess(this.permissionCreateUser) === AccessTypes.FULL ||
            this.authService.checkAccess(this.permissionAddUser) === AccessTypes.FULL
        );
    }

    public handleEditUserClick() {
        this.showCreateUser = true;
        this.editMode = true;
        this.userToEdit = this.selectedUser;
        this.singleUserEditMode = true;
        this.requestHelperDeals.uid = this.userToEdit.id;
        this.internalAdminService.getDeals(this.requestHelperDeals).subscribe({
            next: (res: any) => {
                this.singleUserEditDealRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
        this.requestHelperDeals.uid = null;
        this.internalAdminService.getDeals(this.requestHelperDeals).subscribe({
            next: (res: any) => {
                this.singleUserAllDealRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    public handleSingleUserEditEvent(e: UserRecord) {
        this.selectedUser.firstName = e.firstName;
        this.selectedUser.lastName = e.lastName;
        this.selectedUser.email = e.email;
        this.selectedUser.phoneNr = e.phoneNr;
        this.requestHelperDeals.uid = e.id;
        this.fetchDeals();
    }

    public handleUserToEditEvent(e: UserRecord) {
        this.requestHelperDeals.uid = e.id;
        this.internalAdminService.getDeals(this.requestHelperDeals).subscribe({
            next: (res: any) => {
                this.allUsersEditDealRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
        this.userToEdit = e;
        this.allUsersEditMode = true;
    }

    public handleEditModeEvent(e: boolean) {
        this.editMode = e;
        this.showCreateUser = true;
    }

    public handleRevokeAccessEvent(d: DealRecord) {
        this.selectedDealId = d.id;
        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Revoke Access',
                action: UserActionEnum.revokeAccess,
                messageHTML: "This will remove the user's assigned role to this deal.",
                id: this.selectedDealId,
            },
        };
        this.openModal(modalConfig);
    }

    public handleRevokeAllAccessClick() {
        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Revoke All Access',
                action: UserActionEnum.revokeAllAccess,
                messageHTML: 'This will remove roles to all deals this user currently has been assigned.',
                id: this.selectedUserId,
            },
        };
        this.openModal(modalConfig);
    }

    public handleUserActivityClick(name?: string) {
        this.router.navigate(['/internal-admin/activity'], {
            queryParams: {
                userName: name ? name : this.selectedUser.firstName + ' ' + this.selectedUser.lastName,
            },
        });
    }

    public handleResetMFAClick() {
        const name = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Reset MFA Method?',
                messageHTML: `Would you like to reset the Multi-Factor Authentication method for <b>${name}</b>?`,
                userName: name,
                dealName: null,
                action: UserActionEnum.resetMfa,
                id: this.selectedUser.id,
            },
        };
        this.openModal(modalConfig);
    }

    public handleResendInvitation() {
        const name = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Resend Invitation',
                messageHTML: `Would you like to resend the Deal Dashboard invitation to <b>${name}</b>?`,
                userName: name,
                dealName: null,
                action: UserActionEnum.resendInv,
                id: this.selectedUser.id,
            },
        };
        this.openModal(modalConfig);
    }

    public handleSendPwResetClick() {
        const name = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Send Password Reset?',
                messageHTML: `Would you like to send a password reset for <b>${name}</b>?`,
                userName: name,
                dealName: null,
                action: UserActionEnum.sendPwReset,
                id: this.selectedUser.id,
                email: this.selectedUser.email,
            },
        };
        this.openModal(modalConfig);
    }

    public handleBlockClick() {
        const name = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Authentication Block',
                messageHTML: `This will “block” the user in Auth0 without modifying deal permissions.`,
                userName: name,
                dealName: null,
                action: UserActionEnum.block,
                id: this.selectedUser.id,
            },
        };
        this.openModal(modalConfig);
    }

    public handleUnblockClick() {
        const name = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Unblock Authentication',
                messageHTML: `This will remove the Blocked status in Auth0, allowing users to authenticate.`,
                userName: name,
                dealName: null,
                action: UserActionEnum.unblock,
                id: this.selectedUser.id,
            },
        };
        this.openModal(modalConfig);
    }

    public handleDeactivateClick() {
        const name = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Deactivate User',
                userName: name,
                dealName: null,
                action: UserActionEnum.deactivate,
                id: this.selectedUser.id,
            },
        };
        this.openModal(modalConfig);
    }

    public createUserClick() {
        this.handleEditModeEvent(false);
        this.showCreateUser = true;
        this.fetchDeals();
        this.fetchShareholders();
    }

    public addExistingUser() {
        this.handleEditModeEvent(false);
        this.showCreateUser = true;
        this.addExistingUserMode = true;
        this.addExistingUserRecords = [...this.userRecords];
        this.addExistingUserRecords.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    public handleAddExistingUserSelectedUserId(uid: number) {
        this.requestHelperDeals.uid = 0;
        this.internalAdminService.getDeals(this.requestHelperDeals).subscribe({
            next: (res: any) => {
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsDeals = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.dealRecords = res.body;
                this.requestHelperDeals.uid = uid;
                this.internalAdminService.getDeals(this.requestHelperDeals).subscribe({
                    next: (res: any) => {
                        const contentRange = res.headers.get('content-range'); // "1-3/3"
                        this.addExistingUserDealRecords = res.body;
                    },
                    error: (error) => this.handleError(error),
                });
            },
            error: (error) => this.handleError(error),
        });
    }

    public handleAddExistingUserButtonClick() {
        this.closeSliderPanel();
        this.addExistingUser();
    }

    public handleNewUserButtonClick() {
        this.closeSliderPanel();
        this.createUserClick();
    }

    public closeSliderPanel() {
        this.showCreateUser = false;
        this.allUsersEditMode = false;
        this.addExistingUserMode = false;
        this.fetchUsers();
    }

    public openInNewTab(url: string) {
        window.open(url, '_blank').focus();
    }

    public getUserNsUrl(nsId: number): string {
        const nsContactUrl = this.configurationService.envConfig.getValue().nsContactUrl;

        return nsContactUrl && nsId ? nsContactUrl.replace(/INSERTIDHERE/, nsId.toString()) : '';
    }

    public handleUserClickedEventDealsUsers(uid: number) {
        // will need to pass in the real uid when it exists on the record
        this.router.navigate(['/internal-admin/users'], {
            queryParams: { userId: this.userRecords[0].id },
        });
    }

    public handleShareholderClickedEventDealsUsers(sid: number) {
        this.router.navigate(['/internal-admin/shareholders'], {
            queryParams: { sid: sid },
        });
    }

    public handlePageEventDealsUsers(e: PageEvent) {
        this.requestHelperDealsUsers.size = e.pageSize;
        this.requestHelperDealsUsers.page = e.pageIndex;
        this.fetchShareholdersDealsUsers();
    }

    public handleSortChangeDealsUsers(s: Sort) {
        if (s.direction) {
            this.requestHelperDealsUsers.sortField = s.active;
            this.requestHelperDealsUsers.sortDir = s.direction;
            // Reset the page back to 0
            this.requestHelperDealsUsers.page = 0;
            this.fetchShareholdersDealsUsers();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperDealsUsers.sortField = null;
            this.requestHelperDealsUsers.sortDir = null;
        }
    }

    public addDealAccessClick() {
        this.dealDashboardDealsRow.push([]);
        const dealAccessForm = new FormGroup({
            deal: new FormControl<String>(null, [Validators.required]),
            role: new FormControl<number>(null, [Validators.required]),
        });
        this.filteredDealDashboardDeals = dealAccessForm.controls.deal.valueChanges.pipe(
            startWith(null),
            map((deal: string | null) =>
                deal ? this.filterDealDashboardDeals(deal) : this.allDealDashboardDeals.slice(),
            ),
        );
        this.dealAccess.push(dealAccessForm);
    }

    public deleteDealAccessRow(index: number) {
        if (this.dealDashboardDealsRow[index].length > 0) {
            for (let i = 0; i < this.dealDashboardDealsRow[index].length; i++) {
                this.allDealDashboardDeals.push(this.dealDashboardDealsRow[index][i]);
            }
        }
        this.dealAccess.removeAt(index);
        this.dealDashboardDealsRow[index].splice(0, this.dealDashboardDealsRow[index].length);
        // const noEmpty = this.dealDashboardDealsRow.filter((elem) => elem.length > 0);
        // console.log(noEmpty);
        this.dealDashboardCurrentRoles.splice(index, 1);
    }

    public dealSelected(event: MatAutocompleteSelectedEvent, index: number): void {
        const dealToAdd = event.option.viewValue;
        if (this.dealDashboardDealsRow[index].find((element) => element == dealToAdd)) {
            this.dealDashboardDealInput.nativeElement.value = '';
            this.dealAccess.controls[index].get('deal').setValue(null);
            this.selectedCalled = true;
        } else {
            const dealIndex = this.allDealDashboardDeals.indexOf(dealToAdd);
            this.dealDashboardDealsRow[index].push(dealToAdd);
            this.allDealDashboardDeals.splice(dealIndex, 1);
            this.dealDashboardDealInput.nativeElement.value = '';
            this.dealAccess.controls[index].get('deal').setValue(null);
            this.selectedCalled = true;
        }
        this.dealAccess.controls[index].get('deal').setErrors({});
        this.dealAccess.controls[index].get('deal').clearValidators();
        this.dealAccess.controls[index].get('deal').updateValueAndValidity();
    }

    public shareholderSelected(event: MatAutocompleteSelectedEvent): void {
        const shareholderToAdd = event.option.viewValue;
        const foundIndex = this.allShareholderRecords.indexOf(shareholderToAdd);
        this.shareholderPortalShareholders.push(shareholderToAdd);
        this.allShareholderRecords.splice(foundIndex, 1);
    }

    public shareholderDealSelected(event: MatAutocompleteSelectedEvent): void {
        const shareholderDealToAdd = event.option.viewValue;
        const shareholderPortalDealIndex = this.allShareholderPortalDeals.indexOf(shareholderDealToAdd);
        this.shareholderPortalDeals.push(shareholderDealToAdd);
        this.allShareholderPortalDeals.splice(shareholderPortalDealIndex, 1);
    }

    public onRoleChange(selectionChange, section: string, index: number) {
        if (section === 'shareholderAccess') {
            this.currentShareholderPortalRole = this.shareholderRoleRecords.find(
                (role) => role.id === selectionChange.value,
            );
            if (this.shareholderPortalCurrentRoles[index]) {
                this.shareholderPortalCurrentRoles[index] = this.currentShareholderPortalRole;
            } else {
                this.shareholderPortalCurrentRoles.push(this.currentShareholderPortalRole);
            }
        } else {
            this.currentDealDashboardRole = this.roleRecords.find((role) => role.id === selectionChange.value);
            if (this.dealDashboardCurrentRoles[index]) {
                this.dealDashboardCurrentRoles[index] = this.currentDealDashboardRole;
            } else {
                this.dealDashboardCurrentRoles.push(this.currentDealDashboardRole);
            }
        }
    }

    public addShareholderAccessClick() {
        this.shareholderPortalAccessRow.push([]);
        const shareholderAcessForm = new FormGroup({
            shareholder: new FormControl<String>(null, [Validators.required]),
            deal: new FormControl<String>(null, [Validators.required]),
            role: new FormControl<number>(null, [Validators.required]),
        });

        this.filteredShareholderRecords = shareholderAcessForm.controls.shareholder.valueChanges.pipe(
            startWith(null),
            map((shareholder: string | null) =>
                shareholder ? this.filterShareholderRecords(shareholder) : this.allShareholderRecords.slice(),
            ),
        );

        this.filteredShareholderPortalDeals = shareholderAcessForm.controls.deal.valueChanges.pipe(
            startWith(null),
            map((deal: string | null) =>
                deal ? this.filterShareholderPortalDeals(deal) : this.allShareholderPortalDeals.slice(),
            ),
        );
        this.shareholderAccess.push(shareholderAcessForm);
    }

    public deleteShareholderAccessRow(index: number) {
        if (this.shareholderPortalAccessRow[index].length > 0) {
            for (let i = 0; i < this.shareholderPortalAccessRow[index].length; i++) {
                this.allShareholderPortalDeals.push(this.shareholderPortalAccessRow[index][i]);
            }
        }
        this.shareholderAccess.removeAt(index);
        this.shareholderPortalAccessRow[index].splice(0, this.shareholderPortalAccessRow[index].length);
        // const noEmpty = this.shareholderPortalAccessRow.filter((elem) => elem.length > 0);
        this.dealDashboardCurrentRoles.splice(index, 1);
        this.shareholderPortalCurrentRoles.splice(index, 1);
        this.shareholderAccess.removeAt(index);
    }

    public addDealChip(event: MatChipInputEvent) {
        event.chipInput!.clear();
    }

    public removeDealChip(dealName: string, i: number): void {
        const index = this.dealDashboardDealsRow[i].indexOf(dealName);
        if (index >= 0) {
            this.dealDashboardDealsRow[i].splice(index, 1);
            this.allDealDashboardDeals.push(dealName);
        }
        this.dealDashboardDealInput.nativeElement.value = '';
        this.dealAccess.value[i].deal = null;
        this.dealAccess.setValue([
            {
                deal: this.dealAccess.value[i].deal,
                role: this.dealAccess.value[i].role,
            },
        ]);
        this.removeDealChipCalled = true;
    }

    public cancelClicked(f: FormGroup) {
        f.reset();
        this.router.navigate(['/internal-admin/users'], {
            queryParams: { clear: true },
            queryParamsHandling: 'merge',
        });
        this.showCreateUser = false;
        for (let i = 0; i < this.dealDashboardDealsRow.length; i++) {
            this.dealDashboardDealsRow[i].splice(0, this.dealDashboardDealsRow[i].length);
        }
        for (let i = 0; i < this.dealDashboardDealsRow.length; i++) {
            this.dealDashboardDealsRow[i].splice(0, this.dealDashboardDealsRow[i].length);
        }
        this.dealDashboardCurrentRoles.splice(0, this.dealDashboardCurrentRoles.length);
        this.dealAccess.clear();
        this.shareholderAccess.clear();
    }

    public checkDealErrorState(f: FormGroup, index: number): boolean {
        return f.controls[index].get('deal').invalid;
    }

    // will need to change the users service in ms-internal-admin to handle
    // two new objects: dealAccess and shareholderAccess, which will contain
    // previousDealId, dealId, and roleId properties,
    // and a shareholderName and id field in the shareholderAccess object.

    public onSubmit(f: FormGroup, action?: string) {
        let shareholderAccess = [];

        if (!f.valid) {
            f.markAllAsTouched();
            return;
        }
        let dealIdsArr: number[] = [];

        if (f.value.dealAccess.length > 0) {
            for (let i = 0; this.dealDashboardDealsRow.length > i; i++) {
                let foundDeal = this.dealRecords.find((deal) => deal.name == this.dealDashboardDealsRow[i][i]);
                dealIdsArr.push(foundDeal.id);
            }
        }

        if (f.value.shareholderAccess.length > 0) {
            for (let i = 0; i < f.value.shareholderAccess.length; i++) {
                let foundShareholderID = this.shareholderRecords.find(
                    (shareholder) => shareholder.shareholder == f.value.shareholderAccess[i].shareholder,
                ).nsId;
                let foundShareholderName = this.shareholderRecords.find(
                    (shareholder) => shareholder.shareholder == f.value.shareholderAccess[i].shareholder,
                ).shareholder;
                let foundRoleId = this.shareholderRoleRecords.find(
                    (role) => role.id == f.value.shareholderAccess[i].role,
                ).id;
                let foundShareholderDealId = this.dealRecords.find(
                    (deal) => deal.name == this.shareholderPortalDeals[i],
                ).id;
                shareholderAccess.push({
                    shareholderId: foundShareholderID,
                    shareholderName: foundShareholderName,
                    shareholderDealId: foundShareholderDealId,
                    shareholderPreviousDealId: null,
                    roleId: foundRoleId,
                });
            }
        }

        const newUser: UserDeal = {
            user: {
                id: 0,
                firstName: f.value.firstName,
                lastName: f.value.lastName,
                email: f.value.email,
                phoneNr: f.value.phoneNumber,
                auth0Id: null,
            },
            previousDealIds: [],
            dealIds: dealIdsArr,
            roleId: f.value.dealAccess[0]?.role ? f.value.dealAccess[0]?.role : 1,
            byId: this.userService.user.sub,
            byName: this.userService.user.name,
        };
        this.createUser(newUser, action);
    }

    private createUser(user: UserDeal, action?: string) {
        if (action === 'save') {
            this.internalAdminService.createUser(user).subscribe({
                next: (res: any) => {
                    this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                        data: `User has been created`,
                        duration: 10000,
                    });
                    this.dealDashboardDealInput.nativeElement.value = '';
                    this.cancelClicked(this.userForm);
                },
                error: (error) => this.handleError(error),
            });
        }
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.internalAdminModal = this.matDialog.open(InternalAdminModalComponent, modalConfig);
        this.internalAdminModal.componentInstance.confirmButtonEvent.subscribe((emmittedValue: ModalCloseConfig) => {
            if (emmittedValue) {
                this.internalAdminModal.close();

                const auth0Config: Auth0APIConfig = {
                    userId: emmittedValue.id,
                    action: modalConfig.data.action,
                    byId: this.userService.user.sub,
                    byName: this.userService.user.name,
                };

                switch (emmittedValue.action) {
                    case UserActionEnum.resetMfa: {
                        this.internalAdminService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(
                                        `${modalConfig.data.userName}'s multi-factor authentication method has been reset.`,
                                        undefined,
                                        {
                                            duration: 6000,
                                        },
                                    );
                                } else {
                                    const msg = `Unable to reset the MFA method for ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.sendPwReset: {
                        this.internalAdminService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    const msg = `Email sent with password reset link.`;
                                    this.snackBar.open(msg, undefined, {
                                        duration: 6000,
                                    });
                                } else {
                                    const msg = `Unable to send password reset for ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.resendInv: {
                        this.internalAdminService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(`Invitation to ${modalConfig.data.userName} resent`, undefined, {
                                        duration: 6000,
                                    });
                                } else {
                                    const msg = `Unable to resend the invitation to ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.deactivate: {
                        this.internalAdminService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(
                                        `${modalConfig.data.userName} has been deactivated.`,
                                        undefined,
                                        {
                                            duration: 6000,
                                        },
                                    );

                                    if (this.selectedUser) {
                                        this.fetchSingleUser(this.selectedUserId);
                                    } else {
                                        this.fetchUsers();
                                    }
                                } else {
                                    const msg = `Unable to deactivate ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.block: {
                        this.internalAdminService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(`User Authentication Blocked`, undefined, {
                                        duration: 6000,
                                    });

                                    if (this.selectedUser) {
                                        this.fetchSingleUser(this.selectedUserId);
                                    } else {
                                        this.fetchUsers();
                                    }
                                } else {
                                    const msg = `Unable to block ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.unblock: {
                        this.internalAdminService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(`User Authentication Unblocked`, undefined, {
                                        duration: 6000,
                                    });

                                    if (this.selectedUser) {
                                        this.fetchSingleUser(this.selectedUserId);
                                    } else {
                                        this.fetchUsers();
                                    }
                                } else {
                                    const msg = `Unable to unblock ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.revokeAccess: {
                        const config: RequestDealUserRole = {
                            dealId: emmittedValue.id,
                            userId: this.selectedUserId,
                            byId: this.userService.user.sub,
                            byName: this.userService.user.name,
                        };
                        this.internalAdminService.deleteUserFromDeal(config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.closeSliderPanel();
                                    const msg = 'User access revoked';
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                    this.fetchDeals();
                                } else {
                                    const msg = 'Error occurred while revoking access';
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.revokeAllAccess: {
                        const config: UserDeal = {
                            user: this.selectedUser,
                            previousDealIds: this.singleUserAllDealRecords.map((dealRecord) => dealRecord.id),
                            dealIds: [],
                            roleId: 0,
                            byId: this.userService.user.sub,
                            byName: this.userService.user.name,
                        };
                        this.internalAdminService.editUser(config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.closeSliderPanel();
                                    const msg = 'All access revoked';
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                    this.fetchDeals();
                                } else {
                                    const msg = 'Error occurred while revoking access';
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                        });

                        break;
                    }
                }
            }
        });
        this.internalAdminModal.componentInstance.cancelButtonEvent.subscribe((emmittedValue) => {
            if (emmittedValue) {
                this.internalAdminModal.close();
            }
        });
    }

    private fetchSingleUser(uid: number) {
        this.selectedUserId = uid;

        // retrieve all values for a single user record, which has joins to other
        // tables not required by the users table. Since pagination, sorting, and
        // searching are not required, a new request helper is created to leave the
        // original untouched.
        const tempRequestHelper = new AdminRequestHelper(GetType.users);
        tempRequestHelper.id = uid;

        this.internalAdminService.getUsers(tempRequestHelper).subscribe({
            next: (res: any) => {
                // handling this in the front end for now, will need to revisit if we'd like
                // to handle this in the service instead and make changes there
                let singleUser = res.body.filter((obj) => {
                    return obj.id == this.selectedUserId;
                });
                this.selectedUser = singleUser[0];
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchShareholders() {
        this.requestHelperDealsUsers.sid = null;
        this.internalAdminService.getShareholders(this.requestHelperDealsUsers).subscribe({
            next: (res: any) => {
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalShareholderRecords = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.allShareholderRecords = res.body.map((shareholder) => shareholder.shareholder);
                this.shareholderRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    /**
     * The "single shareholder" comes from the original list of shareholders,
     * so this search is to retrieve the linked deals and users.
     * @param sid
     * @private
     */
    private fetchShareholdersDealsUsers() {
        this.fetchingDealsUsers = true;
        // this.selectedShareholderId = sid;
        // this.selectedShareholder = this.shareholderRecords.find((shareholder) => shareholder.nsId === sid);
        this.internalAdminService.getShareholdersDealsUsers(this.requestHelperDealsUsers).subscribe({
            next: (res: any) => {
                this.fetchingDealsUsers = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsDealsUsers = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.shareholderDealUserRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchUsers() {
        this.fetching = true;
        this.internalAdminService.getUsers(this.requestHelper).subscribe({
            next: (res: any) => {
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.userRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchDeals() {
        this.internalAdminService.getDeals(this.requestHelperDeals).subscribe({
            next: (res: any) => {
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsDeals = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.dealRecords = res.body;
                this.singleUserAllDealRecords = res.body;
                this.allDealDashboardDeals = res.body.map((deal) => deal.name);
                this.allShareholderPortalDeals = res.body.map((deal) => deal.name);
                if (this.selectedUserId) {
                    const userIds = [this.selectedUserId];
                    this.fetchUserDealRel(userIds);
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchRoles() {
        this.internalAdminService.getRoles(this.requestHelperRoles).subscribe({
            next: (res: any) => {
                this.roleRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchUserDealRel(userIds: number[]) {
        const dealIds = this.dealRecords.map((deal) => deal.id);
        this.internalAdminService.getUserDealRel(userIds, dealIds).subscribe({
            next: (res: any) => {
                this.userDealRelRecords = res.body;
                for (let i = 0; i < dealIds.length; i++) {
                    const foundIndex = this.dealRecords.findIndex((deal) => deal.id === dealIds[i]);
                    const foundDealId = this.dealRecords[foundIndex].id;
                    const foundRoleId = this.userDealRelRecords.find(
                        (userDealRel) => userDealRel.parent_id == dealIds[i],
                    ).role_id;
                    this.dealRecords[foundIndex].role = this.roleRecords.find((role) => role.id == foundRoleId).name;
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        error['stack'] ? console.error(error['stack']) : console.error(error);

        let msg: string;
        if (error.status === 400 && error.error.message.includes('User with provided email address already exists.')) {
            msg = 'User with provided email address already exists.';
            this.userForm.controls.email.setErrors({
                emailAlreadyExists: true,
                invalid: true,
            });
        }
        if (error?.status >= 400 && error?.status < 500) {
            msg = error?.error?.message ? error.error.message : 'Server error';
        } else {
            msg = 'Server error';
        }

        this.snackBar.open(msg, undefined, {
            duration: 10000,
        });
    }
}
