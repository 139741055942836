import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../core/services/configuration.service';
import { catchError, Observable } from 'rxjs';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';
import { Bank } from '../issuers/shared/banks.types';
import { BankAccount } from '../accounts/shared/accounts.types';

@Injectable({
    providedIn: 'root',
})
export class BankService {
    private serverName: string = 'Services Bank Payments API';

    constructor(
        private http: HttpClient,
        private configService: ConfigurationService,
    ) {}

    public getBanks(): Observable<Bank[]> {
        const requestUrl = `${this.configService.envConfig.getValue().bankPaymentsHost}/banks`;
        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getBankAccountsByBankID(bankID: number): Observable<BankAccount[]> {
        const requestURL = `${this.configService.envConfig.getValue().bankPaymentsHost}/banks/${bankID}/accounts`;
        return this.http.get(requestURL).pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
