<p-toast></p-toast>

<div class="tableContainer">
    <p-table
        #dt
        [value]="TableData"
        [rows]="10"
        [globalFilterFields]="['name', 'description', 'wso_id', 'primary_tax_form_type', 'review_status_id']"
        [(selection)]="selectedRows"
        dataKey="id"
        [responsive]='true'
        [autoLayout]="true"
        scrollHeight="40vh"
        [scrollable]="true"
        scrollWidth="100%"
    >
        <ng-template pTemplate="caption">
            <div class="tableTop">
                <div class="tableTop">
                    <h5 class="title">{{ TableTitle }}</h5>
                </div>
                <div>
                    <div class="new-import-search">
                        <div class="p-1">
                            <ng-container>
                                <button
                                    mat-raised-button
                                    *ngFor="let tableAction of TableActions"
                                    [color]="tableAction.backgroundColor"
                                    (click)="tableAction.action()"
                                >
                                    {{ tableAction.text }}
                                </button>
                            </ng-container>
                        </div>
                        <div class="p-1">
                            <span class="p-input-icon-left search">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..."
                                    class="search"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let header of TableHeaders" pSortableColumn="{{ header.value }}">
                    {{ header.label }}
                    <p-sortIcon field="{{header.value}}" ></p-sortIcon>
                    <p-columnFilter
                        *ngIf="header.label === 'Primary Tax Form Type'"
                        field="{{header.value}}" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="mr-auto">
                        <ng-template pTemplate="header">
                            <div class="px-3 pt-3 pb-0">
                                <span class="font-bold">Tax Forms</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="TaxFormTypes" optionLabel="value" optionValue="value" defaultLabel="Select Form" (onChange)="filter($event?.value)">
                                <ng-template let-option pTemplate="item">
                                    <div>
                                        <span class="ml-1">{{option.value}}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                    <p-columnFilter
                        *ngIf="header.label === 'Review Status'"
                        field="{{header.value}}" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="mr-auto">
                        <ng-template pTemplate="header">
                            <div class="px-3 pt-3 pb-0">
                                <span class="font-bold">Statuses</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="ReviewStatuses" optionLabel="label" optionValue="label" defaultLabel="Select Status" (onChange)="filter($event?.value)">
                                <ng-template let-option pTemplate="item">
                                        <div>
                                            <span class="ml-1">{{option.label}}</span>
                                        </div>
                                </ng-template>
                            </p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>Lender Details</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr class="p-selectable-row">
                <td><p-tableCheckbox [value]="data"></p-tableCheckbox></td>
                <td><span class="p-column-title">WSO Id</span>{{data.wso_id}}</td>
                <td><span class="p-column-title">Lender Name</span>{{data.name}}</td>
                <td><span class="p-column-title">Primary Tax Form Type</span>{{data.primary_tax_form_type}}</td>
                <td>
                    <span class="p-column-title">Review Status</span>
                    <ng-container *ngFor="let status of ReviewStatuses">
                        <button class="status-button"
                            *ngIf="status.label == data.review_status_id"
                            mat-raised-button
                            [color]="status.backgroundColor"
                        >{{status.label}}
                        </button>
                    </ng-container>
                </td>
                <td><span class="p-column-title">Notes</span>{{data.notes}}</td>
                <td class="details-actions">
                    <span class="p-column-title">Lender Details</span>
                    <ng-container>
                        <a
                            *ngFor="let tableAction of DetailActions"
                            (click)="tableAction.action(data)"
                        >{{tableAction.text}}</a>
                    </ng-container>
                </td>
                <td class="row-actions">
                    <span class="p-column-title">Action</span>
                    <ng-container>
                        <button
                            *ngFor="let tableAction of RowActions"
                            mat-mini-fab
                            [color]="tableAction.backgroundColor"
                            (click)="tableAction.action(data)"
                        >
                            <mat-icon>{{ tableAction.icon }}</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">
            <div class="tableBottom">
                <div>There are {{ TableData ? TableData.length : 0 }} {{ TableTitle }}.</div>
            </div>
        </ng-template>
    </p-table>
</div>
