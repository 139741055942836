export interface Lender {
    id?: string;
    wso_id: number;
    name: string;
    description?: string;
    primary_tax_form_type?: string;
    review_status_id: string;
    notes?: string;
    is_entity: boolean;
    created?: number;
    updated?: number;
}

export interface LenderFileInfoWithName {
    lender_id: string;
    file_info_id: number;
    is_primary?: boolean;
    name: string;
    is_entity: boolean;
    created?: number;
    updated?: number;
}

export type LenderTableListItem = Omit<Lender, 'description' | 'created' | 'updated'>;
export type AddLender = Omit<Lender, 'id' | 'created' | 'updated'>;
export type EditLender = Omit<Lender, 'created' | 'updated'>;

export interface LenderTableHeaderTemplate {
    label: string;
    value: string;
}

export const LENDER_REVIEW_STATUSES = [
    {
        value: '1',
        label: 'Not Reviewed',
        backgroundColor: 'review_status_not_reviewed',
    },
    {
        value: '2',
        label: 'In Review',
        backgroundColor: 'review_status_in_review',
    },
    {
        value: '3',
        label: 'Issue',
        backgroundColor: 'review_status_issue',
    },
    {
        value: '4',
        label: 'Validated',
        backgroundColor: 'review_status_validated',
    },
    {
        value: '5',
        label: 'Inactive',
        backgroundColor: 'review_status_inactive',
    },
];

export type LenderModalConfigData = {
    title?: string;
    selectedLender?: LenderTableListItem;
    action: 'create' | 'edit' | 'delete';
};

export const TAX_FORM_TYPES = [
    {
        value: 'W-9',
        label: 'W-9',
    },
    {
        value: 'W-8BEN-E',
        label: 'W-8BEN-E',
    },
    {
        value: 'W-8BEN',
        label: 'W-8BEN',
    },
    {
        value: 'W-8IMY',
        label: 'W-8IMY',
    },
    {
        value: 'W-8ECI',
        label: 'W-8ECI',
    },
    {
        value: 'W-8EXP',
        label: 'W-8EXP',
    },
];
