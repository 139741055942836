<mat-card>
    <mat-card-title>{{ issuerModalData.title }}</mat-card-title>
    <div class="card__container">
        <ng-container
            *ngTemplateOutlet="issuerModalData.action === 'delete' ? deleteIssuerSection : createEditIssuerSection"
        ></ng-container>
    </div>
</mat-card>

<ng-template #createEditIssuerSection>
    <mat-card-content>
        <div class="issuer-details">
            <h3>Issuer Details</h3>
            <form class="issuer-form" [formGroup]="issuerForm" (ngSubmit)="saveIssuer()">
                <mat-form-field>
                    <mat-label>WSO Issuer ID</mat-label>
                    <input matInput type="number" formControlName="wsoIssuerID" />
                    <mat-error *ngIf="issuerForm.controls.wsoIssuerID.hasError('required')">
                        WSO Issuer ID is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="issuerForm.controls.wsoIssuerID.hasError('pattern')">
                        WSO Issuer ID must be a <strong>number</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Issuer Name</mat-label>
                    <input matInput type="text" formControlName="issuerName" />
                    <mat-error *ngIf="issuerForm.controls.issuerName.hasError('required')">
                        Issuer Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="issuerForm.controls.issuerName.hasError('maxlength')">
                        Issuer Name must be less than 40 characters
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Issuer Description</mat-label>
                    <textarea matInput type="text" formControlName="issuerDescription"></textarea>
                    <mat-error *ngIf="issuerForm.controls.issuerDescription.hasError('maxlength')">
                        Issuer Description must be less than 200 characters
                    </mat-error>
                </mat-form-field>
                <button type="submit" mat-flat-button color="primary" [disabled]="!issuerForm.valid">Submit</button>
            </form>
        </div>

        <mat-divider></mat-divider>

        <div class="issuer-associated-accounts">
            <h3>Associated Accounts</h3>
            <div class="associated-accounts">
                <ng-container *ngFor="let account of issuerAccounts | async">
                    <div class="associated-account__row">
                        <p>{{ account.number }} / {{ account.currency }}</p>
                        <button mat-stroked-button color="warn" (click)="deleteAccountAssociation(account)">
                            Remove Association
                        </button>
                    </div>
                </ng-container>
            </div>
            <form [formGroup]="accountAssociationForm" (ngSubmit)="saveAccountAssociation()">
                <mat-form-field>
                    <mat-label>Bank Account</mat-label>
                    <mat-select [formControl]="accountAssociationForm.controls.account">
                        <mat-option>
                            <ngx-mat-select-search
                                placeholderLabel="Account Number"
                                [formControl]="bankAccountFilterControl"
                            ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                            *ngFor="let account of filteredBankAccounts | async"
                            [value]="account"
                            [disabled]="account.isDisabled"
                        >
                            {{ account.number }} / {{ account.currency }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button type="submit" [disabled]="!accountAssociationForm.controls.account.value">
                    <mat-icon>add</mat-icon>
                </button>
            </form>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button
            mat-flat-button
            [color]="(areChangesSaved | async) ? 'primary' : 'warn'"
            (click)="closeModal()"
            [matTooltip]="modalTooltip"
            [matTooltipDisabled]="areChangesSaved | async"
            matTooltipPosition="before"
        >
            Close
        </button>
    </mat-card-actions>
</ng-template>

<ng-template #deleteIssuerSection>
    <mat-card-content> 
        <div class="modal-body">
            Are you sure to delete the issuer: <span class="issuer-info">{{issuerModalData.selectedIssuer.name}}</span>
            (Issuer Id: {{issuerModalData.selectedIssuer.wso_issuer_id}})
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="modal-footer">
            <button mat-flat-button (click)="closeModal()"  mat-flat-button color="primary">Cancel</button>
            <button mat-flat-button (click)="deleteIssuer()"  mat-flat-button color="primary">Submit</button>
        </div>
    </mat-card-actions>
</ng-template>
