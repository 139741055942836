import { Injectable } from '@angular/core';
import {
    BankIssuerMap,
    DynamicXmlFieldTypesMap,
    IAggregateLoanAgencyRecord,
    IErrorRecord,
    IFlattenedPayment,
    IGroupedPaymentRecords,
    ILoanAgencyRecord,
    ILoanAgencyResultAggregate,
    ILoanAgencyValidationResult,
    IPaymentRecords,
    LoanAgencyPaymentErrors,
    mandatoryPaymentFields,
    PaymentPrefixMap,
    PaymentTypeMap,
    XmlHardcodedFieldsMap,
} from './loan-agency.types';
import { ICdtTrfTxInf, IPain00100103Json } from './pain_001_001_03.types';
import { UtilsService } from './../../../core/services/utils.service';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConfigurationService } from './../../../core/services/configuration.service';
import { IEnvConfiguration } from './../../../core/configuration.model';

@Injectable({
    providedIn: 'root',
})
export class LoanAgencyService {
    public groupValidRecordsByPaymentType(
        incomingRecord: { lineNumber: number; record: ILoanAgencyRecord },
        validRecordsRef: IGroupedPaymentRecords,
    ): IGroupedPaymentRecords {
        const record = incomingRecord.record;
        const lineNumber = incomingRecord.lineNumber;

        const validRecords = { ...validRecordsRef };
        const recordKey = `${record.Portfolio_ID}-${record.Issuer_ID}`;
        if (!validRecords[recordKey]) {
            validRecords[recordKey] = {};
        }
        try {
            const paymentType: string = this.getPaymentType(record.TranCash_ActionCode_ID);
            if (!validRecords[recordKey][paymentType] || validRecords[recordKey][paymentType].length === 0) {
                validRecords[recordKey][paymentType] = {};
                validRecords[recordKey][paymentType].aggregatedRows = [record];
                validRecords[recordKey][paymentType].aggregatedRowNumbers = [lineNumber];
            } else {
                validRecords[recordKey][paymentType].aggregatedRows.push(record);
                validRecords[recordKey][paymentType].aggregatedRowNumbers.push(lineNumber);
            }
            return validRecords;
        } catch (e) {
            console.error(e);
            return validRecordsRef;
        }
    }

    public getPaymentType(tranCashActionCodeId: string): string {
        if (PaymentTypeMap.interest.includes(tranCashActionCodeId)) {
            return 'interest';
        }
        if (PaymentTypeMap.paydown.includes(tranCashActionCodeId)) {
            return 'paydown';
        }
        if (PaymentTypeMap.fee.includes(tranCashActionCodeId)) {
            return 'fee';
        }
        if (PaymentTypeMap.exclude.includes(tranCashActionCodeId)) {
            return 'exclude';
        }
        throw new Error('tranCashActionCodeId not found in PaymentTypesMap!');
    }

    /**
     * Accepts the grouped records that have already been sorted to this point and adds the TransCash_Amounts and flattens the records.
     * Typically, the first record is used as the base for other records. However, the presence of Process Receivable records,
     * which contain no Payment Instructions, we can't just rely on always using the first record.
     * @param validRecords: IGroupedPaymentRecords the records that are to be flattened to a single record with summed TransCash_Amount
     */
    public flattenRecordsForTransformation(validRecords: IGroupedPaymentRecords): IPaymentRecords {
        return Object.keys(validRecords).reduce(
            (accumulator: IPaymentRecords, validRecordKey) => {
                const currRecord: {
                    interest?: IAggregateLoanAgencyRecord;
                    paydown?: IAggregateLoanAgencyRecord;
                    fee?: IAggregateLoanAgencyRecord;
                    exclude?: IAggregateLoanAgencyRecord;
                } = validRecords[validRecordKey];
                Object.keys(currRecord).forEach((paymentType) => {
                    if (currRecord[paymentType] && currRecord[paymentType].aggregatedRows.length > 0) {
                        const aggregateRecord: {
                            aggregatedPaymentData: ILoanAgencyRecord;
                            aggregatedRowNumbers: number[];
                        } = {
                            aggregatedPaymentData: undefined,
                            aggregatedRowNumbers: [],
                        };
                        let runningSum: number = 0;
                        for (let i = 0; i < currRecord[paymentType].aggregatedRows.length; i++) {
                            // Here we are creating a base record... The record will take all truthy values that appear
                            // in the record. Any falsy values that are encountered will be overwritten by following
                            // records that have a truthy value.
                            aggregateRecord.aggregatedPaymentData = {
                                ...aggregateRecord.aggregatedPaymentData,
                                ...currRecord[paymentType].aggregatedRows[i],
                            };

                            // Here we are taking the transcash amount and creating a running sum from the value of each
                            // record in the aggregate. This will be applied directly to the TranCash_Amount field of the
                            // final aggregate at the end.
                            runningSum += +parseFloat(
                                currRecord[paymentType].aggregatedRows[i].TranCash_Amount as string,
                            ).toFixed(4);
                        }
                        aggregateRecord.aggregatedPaymentData.TranCash_Amount = runningSum.toFixed(2);
                        aggregateRecord.aggregatedRowNumbers = currRecord[paymentType].aggregatedRowNumbers;
                        accumulator[paymentType].push(aggregateRecord);
                    }
                });
                return accumulator;
            },
            { interest: [], paydown: [], fee: [] },
        );
    }

    /**
     * Checks the record for fields that are mandatory. The fields must exist and be populated.
     * returns an array of validations - successes AND failures if the data is missing
     * @param record: the object representation of a record from the WSO csv
     */
    public validateMandatoryDataExists(record: ILoanAgencyRecord): ILoanAgencyValidationResult[] {
        const validationResults: ILoanAgencyValidationResult[] = [];
        mandatoryPaymentFields.forEach((mandatoryField: string) => {
            validationResults.push(this.validateMissingField(record, mandatoryField));
        });
        return validationResults;
    }

    /**
     * accepts single loan agency record, performs pre-flattening validations, and returns a result aggregate object.
     * @param bankIssuers: the list of issuers for the bank selected by the user
     * @param record: the object representation of a record from the WSO csv
     */
    public validateFileRow(
        bankIssuers: string[],
        selectedDate: Date,
        record: ILoanAgencyRecord,
    ): ILoanAgencyResultAggregate {
        let resultAggregate: ILoanAgencyResultAggregate;

        // Prepare validation failure array
        const aggregateValidationResults: ILoanAgencyValidationResult[] = [];

        // Perform validations by calling their functions
        aggregateValidationResults.push(...this.removeRecordNotAssociatedWithSelectedBank(bankIssuers, record));
        aggregateValidationResults.push(...this.validateMandatoryDataExists(record));
        aggregateValidationResults.push(...this.removeRecordOutOfDateRange(record));
        aggregateValidationResults.push(...this.removeRecordDateMismatch(selectedDate, record));

        // Map validation results to an aggregate object.
        resultAggregate = aggregateValidationResults.reduce(this.mapValidationResultsToMessages, {
            status: 'success',
            errors: [],
            warnings: [],
        });

        // Return the errorAggregate object or undefined if no errors were found
        return resultAggregate;
    }

    public validateFlattenedPayment(records: IPaymentRecords): IErrorRecord[] {
        // Prepare validation failure array
        const invalidRecords: IErrorRecord[] = [];
        Object.keys(records).forEach((paymentType) => {
            records[paymentType].forEach((record: IFlattenedPayment) => {
                const paymentData = record.aggregatedPaymentData;

                const aggregateValidationResults: ILoanAgencyValidationResult[] = [];
                // Perform validations by calling their functions
                aggregateValidationResults.push(...this.validateWireInstructions(paymentData));

                const resultsAggregate = aggregateValidationResults.reduce(this.mapValidationResultsToMessages, {
                    status: 'success',
                    errors: [],
                    warnings: [],
                });
                if (resultsAggregate.status === 'error') {
                    invalidRecords.push({
                        lineNumber: record.aggregatedRowNumbers,
                        errors: resultsAggregate.errors,
                        record: paymentData,
                    });
                }
            });
        });
        return invalidRecords;
    }

    public cleanFlattenedPayment(records: IPaymentRecords): IPaymentRecords {
        Object.keys(records).forEach((paymentType) => {
            records[paymentType].forEach((record: IFlattenedPayment) => {
                const paymentData = record.aggregatedPaymentData;
                Object.keys(paymentData).forEach((key) => {
                    let currVal = paymentData[key];
                    currVal = this.utilsService.removeDisallowedCharacters(currVal);

                    // TODO: any other column-level cleaning (e.g. ABA padding) can be performed here
                    if (key.includes('_ABA') && paymentData[key]) {
                        currVal = paymentData[key].padStart(9, '0');
                    }
                    paymentData[key] = currVal;
                });
            });
        });
        return records;
    }

    /**
     * This is a reduce function that takes all the aggregated validation results and stores them according to their status
     * if pass: nothing happens
     * if error: the result is mapped to a string and added to the errors array of the return object
     * if warning: the result is mapped to a string and added to the warnings array of the return object
     * @param accumulated: ILoanAgencyResultAggregate the aggregate result object
     * @param value: ILoanAgencyValidationResult a validation result pulled from the array being iterated over
     */
    public mapValidationResultsToMessages(accumulated: ILoanAgencyResultAggregate, value: ILoanAgencyValidationResult) {
        if (value !== undefined) {
            if (value.status === 'error') {
                const errorMessage: string = value.column
                    ? `Column: ${value.column}\nError: ${value.message}`
                    : `Error: ${value.message}`;
                accumulated.errors.push(errorMessage);
            } else if (value.status === 'warning') {
                const warningMessage: string = value.column
                    ? `Column: ${value.column}\nWarning: ${value.message}`
                    : `Warning: ${value.message}`;
                accumulated.warnings.push(warningMessage);
            }
        }
        /**
         * The presence of errors dictates that the return status be error.
         * A warning status may return only if there are warnings and no errors.
         * If there have been only warnings, and an error comes through, the status will be set to error.
         */
        if (accumulated?.errors?.length > 0 && accumulated.status !== 'error') {
            accumulated.status = 'error';
        } else if (accumulated?.warnings?.length > 0 && accumulated.status === 'success') {
            accumulated.status = 'warning';
        }
        return accumulated;
    }

    /**
     * Checks the record's issuer id against the the list of issuer for the bank to determine if the record
     * should be included or not. If the issuer id does not match the selected bank, a warning is returned.
     * @param bankIssuers:  the list of issuer for the bank selector drop down
     * @param record: ILoanAgencyRecord the object representation of a record from the WSO csv
     */
    public removeRecordNotAssociatedWithSelectedBank(
        bankIssuers: string[],
        record: ILoanAgencyRecord,
    ): ILoanAgencyValidationResult[] {
        if (bankIssuers.includes(record.Issuer_ID)) {
            return [];
        } else {
            return [
                { status: 'warning', column: 'Issuer_ID', message: LoanAgencyPaymentErrors.RECORD_SKIPPED_ISSUER_ID },
            ];
        }
    }

    /** This method is the entryway for mapping the the final XMl format.
     * The output is an array of minimized objects containing JUST the fields needed for transformation to XML.
     * This is the object that will be sent to Boomi for mapping to XML.
     * @param records IPaymentRecords The object containing the records created by the previous flattening process
     */
    public mapToXmlFields(records: IPaymentRecords, executionDate: Date): IPain00100103Json {
        let xmlPaymentFile = {} as IPain00100103Json;

        // set up some date formats
        const currentDateWithTimestamp = this.setCurrentDateWithTimestamp();
        const fileId =
            'SRSALoanAgntPmt-' +
            this.utilsService.formatDateAsStringPattern('MMddyyyy_HHmmss', currentDateWithTimestamp);

        // GrpHdr fields
        xmlPaymentFile = { ...XmlHardcodedFieldsMap.GRPHDR, ...xmlPaymentFile };
        xmlPaymentFile.GrpHdr_MsgId = fileId;
        xmlPaymentFile.GrpHdr_CreDtTm = this.utilsService.formatDateAsStringPattern(
            'yyyy-MM-ddTHH:mm:ss',
            currentDateWithTimestamp,
        );

        // PmtInfo fields
        xmlPaymentFile.PmtInf_PmtInfId = fileId;
        xmlPaymentFile.PmtInf_ReqdExctnDt = this.utilsService.formatDateAsStringPattern('yyyy-MM-dd', executionDate);
        xmlPaymentFile = { ...XmlHardcodedFieldsMap.PMTINFO, ...xmlPaymentFile };
        xmlPaymentFile = { ...XmlHardcodedFieldsMap.DBTR, ...xmlPaymentFile };

        xmlPaymentFile.PmtInfo_CdtTrfTxInf_Array = this.createCdtTrfTxInfArray(records);
        xmlPaymentFile.GrpHdr_NbOfTxs = xmlPaymentFile.PmtInfo_CdtTrfTxInf_Array.length.toString();
        return xmlPaymentFile;
    }

    /** this method contains logic for mapping the data needed for individual transactions within the payment file.
     * This includes numbering the payment individually and selecting the correct wire instructions.
     * @param records IPaymentRecords The flattened object of payments created from the CSV input
     */
    public createCdtTrfTxInfArray(records: IPaymentRecords): ICdtTrfTxInf[] {
        let minimizedRecords = [];
        let wireIndex = 1;
        for (let paymentType in records) {
            const wirePaymentTypePrefix = PaymentPrefixMap[paymentType];

            records[paymentType].forEach((record) => {
                const recordData = record.aggregatedPaymentData;
                let minimizedRecord = {};

                const targetPaymentTypePrefix = this.findWireInstructionTypeToUse(recordData, wirePaymentTypePrefix);

                // mapping the Wire Instructions
                minimizedRecord = this.mapPaymentInstructions(recordData, targetPaymentTypePrefix);

                // mapping the other fields... TODO: error handling???
                // creditor address info
                /**
                 * Here, the order matters! we want the mappedPaymentInstructions to overwrite the hard coded values
                 * specifically in the case of the CdtrAgt_FinInstnId_PstlAdr_Ctry value in the case of a BIC being used
                 * instead of an ABA
                 */
                minimizedRecord = { ...XmlHardcodedFieldsMap['CDTRADDR'], ...minimizedRecord };
                // the payment amount
                minimizedRecord['Amt_InstdAmt'] = recordData.TranCash_Amount;
                // piece together the end-to-end ID
                minimizedRecord['PmtId_EndToEndId'] = this.mapEndToEndId(recordData, wirePaymentTypePrefix);
                // the Intruction ID (an ongoing count of records in the file)
                minimizedRecord['PmtId_InstrId'] = `WIRE_${wireIndex++}`;

                minimizedRecords.push(minimizedRecord);
            });
        }
        return minimizedRecords;
    }

    /** 'ALL'-type wire instructions take precedent above any payment type-specific instructions (e.g. FEE, INT, PRN)
     * returns string 'ALL' if there are wire instructions in the ALL fields, or the payment-type specific field if not
     * @param sourceRecord: ILoanAgencyRecord The record/line from the CSV that we're using to pull data from
     * @param paymentPrefix: string The type of payment these isntructions are intended for (e.g. ALL, INT, FEE, PRN)
     */
    public findWireInstructionTypeToUse(sourceRecord: ILoanAgencyRecord, wirePaymentType: string): string {
        // TODO: depending on nature of validations, these IFs may be changed to include 'AI'
        if (this.checkIfABAorBIC(sourceRecord, 'ALL', 'BC') !== '') {
            // THEN we have ALL wire instructions to use
            return 'ALL';
        } else if (this.checkIfABAorBIC(sourceRecord, wirePaymentType, 'BC') !== '') {
            //THEN we have payment-specific instructions to use
            return wirePaymentType;
        } else {
            return '';
        }
    }

    /** business requirements: first 11 chars of Issuer_Name, appended with '- PRN'|'- FEE'|'- INT'
     * @param sourceRecord: ILoanAgencyRecord The record/line from the CSV that we're using to pull data from
     * @param paymentPrefix: string The type of payment these isntructions are intended for (e.g. ALL, INT, FEE, PRN)
     */
    public mapEndToEndId(sourceRecord: ILoanAgencyRecord, paymentPrefix: string): string {
        const abbrevIssuerName = sourceRecord.Issuer_Name.substring(0, 11);
        return abbrevIssuerName + '- ' + paymentPrefix;
    }

    /** this method determines which payment field types to use for the intermediary, FFC, and creditor
     * agent bank information
     * @param sourceRecord: ILoanAgencyRecord The record/line from the CSV that we're using to pull data from
     * @param paymentPrefix: string The type of payment these isntructions are intended for (e.g. ALL, INT, FEE, PRN)
     */
    public mapPaymentInstructions(sourceRecord: ILoanAgencyRecord, paymentPrefix: string): any {
        let mapPaymentInstructionObject = {} as ICdtTrfTxInf;
        let creditorAcctFieldGroup = '';

        if (this.checkIfABAorBIC(sourceRecord, paymentPrefix, 'BC') !== '') {
            // HANDLE INTERMEDIARY FIELD MAPPINGS
            const intermediaryBankNumberType = this.checkIfABAorBIC(sourceRecord, paymentPrefix, 'II');
            if (intermediaryBankNumberType !== '') {
                const intermediaryFieldMappings = { ...DynamicXmlFieldTypesMap.INTRMY };
                if (intermediaryBankNumberType === 'ABA') {
                    intermediaryFieldMappings['IntrmyAgt1_FinInstnId_ClrSysMmbId_MmbId'] = 'ABA';
                    // AIO-267 and new requirement discoveries dictated that a Postal Address Country Code should be hard coded to US
                    // if the intermediary agent is using an ABA instead of a BIC
                    mapPaymentInstructionObject.IntrmyAgt1_FinInstnId_PstlAdr_Ctry = 'US';
                } else {
                    intermediaryFieldMappings['IntrmyAgt1_FinInstnId_BIC'] = 'BIC';
                }
                mapPaymentInstructionObject = {
                    ...this.mapToDynamicFields(sourceRecord, paymentPrefix, 'II', intermediaryFieldMappings),
                    ...mapPaymentInstructionObject,
                };
            }

            // HANDLE POSSIBLE FFC MAPPINGS AND DETERMINE CREDITOR FIELD GROUP
            // do we have AI-level instructions?
            if (this.checkIfABAorBIC(sourceRecord, paymentPrefix, 'AI') !== '') {
                if (
                    this.checkIfFieldGroupEquivalent(sourceRecord, paymentPrefix, 'AI', paymentPrefix, 'BC') === false
                ) {
                    // the field groups are not the same
                    // we need the extra step of mapping the BC fields to FFC
                    mapPaymentInstructionObject = {
                        ...this.mapToFFCFields(sourceRecord, paymentPrefix, 'BC'),
                        ...mapPaymentInstructionObject,
                    };
                }
                // if BC and AI are the same, we can use either to map to Creditor Acct
                // OR if they are different, we give precedence to AI and map BC to FFC
                // so this line works for both scenarios
                creditorAcctFieldGroup = 'AI';
            } else {
                // we have no AI-level instructions, just use the BC for Creditor Acct
                creditorAcctFieldGroup = 'BC';
            }

            // HANDLE CREDITOR FIELD MAPPINGS
            const creditorBankNumberType = this.checkIfABAorBIC(sourceRecord, paymentPrefix, creditorAcctFieldGroup);
            const creditorFieldMappings = { ...DynamicXmlFieldTypesMap['CDTR'] };
            if (creditorBankNumberType === 'ABA') {
                creditorFieldMappings['CdtrAgt_FinInstnId_ClrSysMmbId_MmbId'] = 'ABA';
            } else {
                creditorFieldMappings['CdtrAgt_FinInstnId_BIC'] = 'BIC';
            }
            if (
                sourceRecord[`${paymentPrefix}_${creditorAcctFieldGroup}_IBAN`] !== '' &&
                sourceRecord[`${paymentPrefix}_${creditorAcctFieldGroup}_IBAN`] !== undefined
            ) {
                creditorFieldMappings['CdtrAcct_Id_IBAN'] = 'IBAN';
            } else if (
                sourceRecord[`${paymentPrefix}_${creditorAcctFieldGroup}_AcctNumb`] !== '' &&
                sourceRecord[`${paymentPrefix}_${creditorAcctFieldGroup}_AcctNumb`] !== undefined
            ) {
                creditorFieldMappings['CdtrAcct_Id_Other_Id'] = 'AcctNumb';
            }
            mapPaymentInstructionObject = {
                ...this.mapToDynamicFields(sourceRecord, paymentPrefix, creditorAcctFieldGroup, creditorFieldMappings),
                ...mapPaymentInstructionObject,
            };

            return mapPaymentInstructionObject;
        } else {
            return {}; // TODO: error handling?
        }
    }

    /**
     * for mapping the repeating fields in the ILoanAgencyRecord object (wire information) that change just a bit based on prefixes
     * @param sourceRecord: ILoanAgencyRecord The record/line from the CSV that we're using to pull data from
     * @param paymentPrefix: string The type of payment these isntructions are intended for (e.g. ALL, INT, FEE, PRN)
     * @param fieldGroup: string The field group of the payment instructions (e.g. II, AI, BC)
     * @param fieldMap: object Top-level keys contain groupings of these fields for organization; each contains an object with keys named
     * after the destination XML field and the values are the suffix that will be tacked onto the paymentPrefix_fieldGroup_ string
     */
    public mapToDynamicFields(
        sourceRecord: ILoanAgencyRecord,
        paymentPrefix: string,
        fieldGroup: string,
        fieldMap: any,
    ): any {
        const paymentInstructionObject = {};
        for (const xmlField of Object.keys(fieldMap)) {
            const targetField = paymentPrefix + '_' + fieldGroup + '_' + fieldMap[xmlField];
            if (sourceRecord[targetField] === undefined || sourceRecord[targetField] === '') {
                continue;
            }
            paymentInstructionObject[xmlField] = sourceRecord[targetField];
            if (xmlField.includes('BIC')) {
                const countryCodeField = this.extrapolatePstlAdrCtryFieldFromBicField(xmlField);
                paymentInstructionObject[countryCodeField] = this.extrapolateCountryCodeFromBic(
                    sourceRecord[targetField],
                );
            }
        }
        return paymentInstructionObject;
    }

    /**
     * FFC fields are unstructured text fields that need to be built and possibly split based on character lengt
     * @param sourceRecord: ILoanAgencyRecord The record/line from the CSV that we're using to pull data from
     * @param paymentPrefix: string The type of payment these isntructions are intended for (e.g. ALL, INT, FEE, PRN)
     * @param fieldGroup: string The field group of the payment instructions (e.g. II, AI, BC)
     */
    public mapToFFCFields(sourceRecord: ILoanAgencyRecord, paymentPrefix: string, fieldGroup: string): any {
        let paymentInstructionObject = {};
        let ffcFields: string[] = [];

        // check for AcctNumb value
        const ffcAcctNumField = paymentPrefix + '_' + fieldGroup + '_' + 'AcctNumb';
        if (sourceRecord[ffcAcctNumField] !== '' && sourceRecord[ffcAcctNumField] !== undefined) {
            ffcFields.push('FFC Acct. NO. ' + sourceRecord[ffcAcctNumField]);
        }
        // check for AcctName value
        const ffcAcctNameField = paymentPrefix + '_' + fieldGroup + '_' + 'AcctName';
        if (sourceRecord[ffcAcctNameField] !== '' && sourceRecord[ffcAcctNameField] !== undefined) {
            ffcFields.push('FFC Acct Name ' + sourceRecord[ffcAcctNameField]);
        }

        const ffcText = ffcFields.join(' ');

        // TODO: This functionality should be broken out into its own utility method.
        // these values are hard-coded because this is a bank-standard file that is unlikely to change
        const textChunks = ffcText.match(/.{1,140}/g); // break it up into chunks of 140 characters
        const textChunksLength = textChunks.length;
        const loopLength = textChunksLength > 4 ? 4 : textChunksLength; // we only have FOUR unstructured text fields
        for (let i = 0; i < loopLength; i++) {
            // TODO: look into how to use dot notation to assign these values
            // do we want to create intermediary types for every step of the way?
            // or break the types into smaller chunks?
            paymentInstructionObject[`RmtInf_Unstrd${i}`] = textChunks[i];
        }
        return paymentInstructionObject;
    }

    /**
     * often the payment information in a field group does not have every field filled in
     * these are the bare minimum fields needed in order to safely assume that there is data in a particular field group
     * even if some fields are empty (only one or the other needs to have information)
     * returns a boolean based on whether the ABA or BIC number for a particular payment type/field group exists
     * @param sourceRecord: ILoanAgencyRecord The record/line from the CSV that we're using to pull data from
     * @param paymentPrefix: string The type of payment these isntructions are intended for (e.g. ALL, INT, FEE, PRN)
     * @param fieldGroup: string The field group of the payment instructions (e.g. II, AI, BC)
     */
    public checkIfABAorBIC(sourceRecord: ILoanAgencyRecord, paymentPrefix: string, fieldGroup: string): string {
        const abaField = `${paymentPrefix}_${fieldGroup}_ABA`;
        const bicField = `${paymentPrefix}_${fieldGroup}_BIC`;

        if (sourceRecord[abaField] !== '' && sourceRecord[abaField] !== undefined) {
            return 'ABA';
        }
        if (sourceRecord[bicField] !== '' && sourceRecord[bicField] !== undefined) {
            return 'BIC';
        }
        // we have NEITHER
        return '';
    }

    /**  sometimes the payment information is duplicated or partly duplicated across two field groups
     * these are the bare minimum fields needed in order to safely assume that two field groups have duplicate data
     * returns a boolean based on whether two fields (a) both have values and (b) those values match
     * @param sourceRecord: ILoanAgencyRecord The record/line from the CSV that we're using to pull data from
     * @param paymentPrefix1: string The type of payment these isntructions are intended for (e.g. ALL, INT, FEE, PRN)
     * @param paymentPrefix2: string The type of payment these isntructions are intended for (e.g. ALL, INT, FEE, PRN)
     * @param fieldGroup1: string The field group of the payment instructions (e.g. II, AI, BC)
     * @param fieldGroup2: string The field group of the payment instructions (e.g. II, AI, BC)
     */
    public checkIfFieldGroupEquivalent(
        sourceRecord: ILoanAgencyRecord,
        paymentPrefix1: string,
        fieldGroup1: string,
        paymentPrefix2: string,
        fieldGroup2: string,
    ): boolean {
        const fieldsToCheckForEquivalence = ['ABA', 'BIC', 'AcctName', 'AcctNumb'];
        const fieldsToCheckForEquivalenceLength = fieldsToCheckForEquivalence.length;

        const fieldPrefix1 = paymentPrefix1 + '_' + fieldGroup1 + '_';
        const fieldPrefix2 = paymentPrefix2 + '_' + fieldGroup2 + '_';

        for (let i = 0; i < fieldsToCheckForEquivalenceLength; i++) {
            const fieldName1 = fieldPrefix1 + fieldsToCheckForEquivalence[i];
            const fieldName2 = fieldPrefix2 + fieldsToCheckForEquivalence[i];

            if (
                sourceRecord[fieldName1] !== sourceRecord[fieldName2] &&
                sourceRecord[fieldName1] != false &&
                sourceRecord[fieldName2] != false
            ) {
                return false;
            }
        }
        return true;
    }

    public removeRecordOutOfDateRange(record: ILoanAgencyRecord): ILoanAgencyValidationResult[] {
        const currentDate = this.setCurrentDate();
        const cutOffDate: Date = this.getFutureDateGivenBusinessDays(currentDate, 5);
        const tranCashReceivedDate: Date = new Date(record.TranCash_ReceiveDate);
        if (currentDate <= tranCashReceivedDate && tranCashReceivedDate <= cutOffDate) {
            return [];
        } else {
            return [
                {
                    column: 'TranCash_ReceiveDate',
                    status: 'warning',
                    message: LoanAgencyPaymentErrors.RECEIVED_DATE_OUT_OF_RANGE,
                },
            ];
        }
    }

    public removeRecordDateMismatch(selectedDate: Date, record: ILoanAgencyRecord): ILoanAgencyValidationResult[] {
        const tranCashReceivedDate: Date = new Date(record.TranCash_ReceiveDate);
        if (tranCashReceivedDate.getTime() == selectedDate.getTime()) {
            return [];
        } else {
            return [
                {
                    column: 'TranCash_ReceiveDate',
                    status: 'warning',
                    message: LoanAgencyPaymentErrors.SELECTED_DATE_MISMATCH,
                },
            ];
        }
    }

    public setCurrentDate(): Date {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return currentDate;
    }

    public setCurrentDateWithTimestamp(): Date {
        return new Date();
    }

    /**
     * This function receives a date and returns a date that is x business days away
     * NOTE: This function does not currently (1/25/2021) handle holidays
     * @param date: Date the date you want to start counting business days from
     * @param businessDays: number the number of business days you want to count out from date
     */
    public getFutureDateGivenBusinessDays(date: Date, businessDays: number): Date {
        const startDay = date.getDay();
        const businessDaysOffset = this.convertBusinessDaysToActualDays(startDay, businessDays);
        const cutOffDate = new Date(date);
        cutOffDate.setHours(23, 59, 59, 999);
        cutOffDate.setFullYear(
            cutOffDate.getFullYear(),
            cutOffDate.getMonth(),
            cutOffDate.getDate() + businessDaysOffset,
        );
        return cutOffDate;
    }

    /**
     * Used to determine the number of actual days that exist between the startDay and the businessDays passed in
     * @param startDay: number the day of week that we are starting to count days from
     * @param businessDays: number the number of business days we need to count to
     */
    public convertBusinessDaysToActualDays(startDay: number, businessDays: number): number {
        if (businessDays < 1) {
            return 0;
        }
        const weeks = Math.floor(businessDays / 5);
        const days = businessDays % 5;
        // Handle Saturday and Sunday
        if (!(startDay > 0 && startDay < 6)) {
            // If days is 0, that means it's a multiple of 5 and thus the day needs to be friday, not a week from weekend day
            if (days === 0) {
                const weekEndModifier = startDay === 0 ? 5 : 6;
                return (weeks === 1 ? 0 : weeks * 7) + weekEndModifier;
            } else {
                return weeks * 7 + (startDay === 0 ? days : days + 1);
            }
        } else {
            // convert to base 5
            const base5Number = (startDay + days).toString(5);
            // get modulo 10
            const dayOfWeek = +base5Number % 10;
            // If base5Number is less than or equal to 10, we are in the same week and don't have to account for weekend days
            // If base5Number is greater than 10, we must account for spilling into the following week
            if (+base5Number <= 10) {
                return weeks * 7 + days;
            } else {
                return weeks * 7 + (7 - startDay) + dayOfWeek;
            }
        }
    }

    public validateWireInstructions(aggregateRecord: ILoanAgencyRecord): ILoanAgencyValidationResult[] {
        const validationErrors: ILoanAgencyValidationResult[] = [];

        const paymentType = PaymentPrefixMap[this.getPaymentType(aggregateRecord.TranCash_ActionCode_ID)];

        const paymentInstructionType = this.findWireInstructionTypeToUse(aggregateRecord, paymentType);

        if (paymentInstructionType === '') {
            const errorMessage = this.getMissingPaymentDetailsError(paymentType);
            validationErrors.push({ status: 'error', message: errorMessage });
            return validationErrors;
        }

        // default is mapping BC-fields to creditor
        let creditorFieldGroup: string = 'BC';
        if (
            this.checkIfABAorBIC(aggregateRecord, paymentInstructionType, 'AI') !== '' &&
            !this.checkIfFieldGroupEquivalent(
                aggregateRecord,
                paymentInstructionType,
                'AI',
                paymentInstructionType,
                'BC',
            )
        ) {
            // we have AI-level instructions and they are different from the BC fields
            // so we will be using the FFC fields
            validationErrors.push(...this.validateFfcFields(aggregateRecord, paymentInstructionType));

            // AI-fields get mapped to creditor instead
            creditorFieldGroup = 'AI';
        }

        validationErrors.push(
            ...this.validateCreditorFields(aggregateRecord, paymentInstructionType, creditorFieldGroup),
        );

        // validate intermediary fields (_II_) if we have any
        if (this.checkIfABAorBIC(aggregateRecord, paymentInstructionType, 'II') !== '') {
            validationErrors.push(...this.validateIntermediaryFields(aggregateRecord, paymentInstructionType));
        }

        return validationErrors;
    }

    public validateCreditorFields(
        aggregateRecord: ILoanAgencyRecord,
        paymentInstructionType: string,
        creditorFieldGroup: string,
    ): ILoanAgencyValidationResult[] {
        const validationResults: ILoanAgencyValidationResult[] = [];
        validationResults.push(
            this.validateLoanAgencyBICorABA(aggregateRecord, paymentInstructionType, creditorFieldGroup),
        );
        validationResults.push(
            this.validateMissingField(aggregateRecord, `${paymentInstructionType}_${creditorFieldGroup}_AcctName`),
        );
        const acctNumb: string = aggregateRecord[`${paymentInstructionType}_${creditorFieldGroup}_AcctNumb`];
        const iban: string = aggregateRecord[`${paymentInstructionType}_${creditorFieldGroup}_IBAN`];
        if ((acctNumb === '' || acctNumb === undefined) && (iban === '' || iban === undefined)) {
            validationResults.push({
                status: 'error',
                message: this.getMissingCreditorDetailsError(paymentInstructionType, creditorFieldGroup),
            });
        }
        return validationResults;
    }

    public validateFfcFields(
        aggregateRecord: ILoanAgencyRecord,
        paymentInstructionType: string,
    ): ILoanAgencyValidationResult[] {
        const validationResults: ILoanAgencyValidationResult[] = [];

        const acctNumb: string = aggregateRecord[`${paymentInstructionType}_BC_AcctNumb`];
        const acctName: string = aggregateRecord[`${paymentInstructionType}_BC_AcctName`];

        // we have an error if there is no value in EITHER of these fields
        if ((acctNumb === '' || acctNumb === undefined) && (acctName === '' || acctName === undefined)) {
            validationResults.push({
                status: 'error',
                message: this.getMissingFfcDetailsError(paymentInstructionType),
            });
        } else {
            validationResults.push({ status: 'success' });
        }

        return validationResults;
    }

    public validateIntermediaryFields(
        aggregateRecord: ILoanAgencyRecord,
        paymentInstructionType: string,
    ): ILoanAgencyValidationResult[] {
        const validationResults: ILoanAgencyValidationResult[] = [];

        // validate either ABA or BIC if there is no ABA
        validationResults.push(this.validateLoanAgencyBICorABA(aggregateRecord, paymentInstructionType, 'II'));

        return validationResults;
    }

    public validateMissingField(
        aggregateRecord: ILoanAgencyRecord,
        fieldToValidate: string,
    ): ILoanAgencyValidationResult {
        if (aggregateRecord[fieldToValidate] === '' || aggregateRecord[fieldToValidate] === undefined) {
            return { status: 'error', column: fieldToValidate, message: LoanAgencyPaymentErrors.MISSING_CRITICAL_DATA };
        }
        return { status: 'success' };
    }

    public validateLoanAgencyBICorABA(
        aggregateRecord: ILoanAgencyRecord,
        paymentInstructionType: string,
        paymentFieldGroup: string,
    ): ILoanAgencyValidationResult {
        // validate either ABA or BIC if there is no ABA
        const bankNumberType = this.checkIfABAorBIC(aggregateRecord, paymentInstructionType, paymentFieldGroup);
        let validFlag = false;
        const validatedField = `${paymentInstructionType}_${paymentFieldGroup}_${bankNumberType}`;
        switch (bankNumberType) {
            case 'ABA': {
                validFlag = this.utilsService.checkValidABA(aggregateRecord[validatedField]);
                break;
            }
            case 'BIC': {
                validFlag = this.utilsService.checkValidBIC(aggregateRecord[validatedField]);
                break;
            }
            // after validations, no other options should be possible
        }
        if (validFlag === false) {
            return {
                status: 'error',
                column: validatedField,
                message: LoanAgencyPaymentErrors[`INVALID_${bankNumberType}`],
            };
        }
        return { status: 'success' };
    }

    public getMissingPaymentDetailsError(paymentType: string) {
        switch (paymentType) {
            case 'INT':
                return LoanAgencyPaymentErrors.MISSING_PAYMENT_INT_DETAILS;
            case 'FEE':
                return LoanAgencyPaymentErrors.MISSING_PAYMENT_FEE_DETAILS;
            case 'PRN':
                return LoanAgencyPaymentErrors.MISSING_PAYMENT_PRN_DETAILS;
            default:
                return LoanAgencyPaymentErrors.MISSING_PAYMENT_TYPE;
        }
    }

    public getMissingFfcDetailsError(paymentType: string) {
        switch (paymentType) {
            case 'INT':
                return LoanAgencyPaymentErrors.MISSING_FFC_INT_DETAILS;
            case 'FEE':
                return LoanAgencyPaymentErrors.MISSING_FFC_FEE_DETAILS;
            case 'PRN':
                return LoanAgencyPaymentErrors.MISSING_FFC_PRN_DETAILS;
            case 'ALL':
                return LoanAgencyPaymentErrors.MISSING_FFC_ALL_DETAILS;
            default:
                return LoanAgencyPaymentErrors.MISSING_PAYMENT_TYPE;
        }
    }

    public getMissingCreditorDetailsError(paymentType: string, creditorGroup: string): string {
        switch (paymentType) {
            case 'INT':
                if (creditorGroup === 'BC') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_INT_BC_DETAILS;
                } else if (creditorGroup === 'II') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_INT_II_DETAILS;
                } else if (creditorGroup === 'AI') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_INT_AI_DETAILS;
                } else {
                    return LoanAgencyPaymentErrors.INVALID_CREDITOR_GROUP;
                }
            case 'FEE':
                if (creditorGroup === 'BC') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_FEE_BC_DETAILS;
                } else if (creditorGroup === 'II') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_FEE_II_DETAILS;
                } else if (creditorGroup === 'AI') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_FEE_AI_DETAILS;
                } else {
                    return LoanAgencyPaymentErrors.INVALID_CREDITOR_GROUP;
                }
            case 'PRN':
                if (creditorGroup === 'BC') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_PRN_BC_DETAILS;
                } else if (creditorGroup === 'II') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_PRN_II_DETAILS;
                } else if (creditorGroup === 'AI') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_PRN_AI_DETAILS;
                } else {
                    return LoanAgencyPaymentErrors.INVALID_CREDITOR_GROUP;
                }
            case 'ALL':
                if (creditorGroup === 'BC') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_ALL_BC_DETAILS;
                } else if (creditorGroup === 'II') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_ALL_II_DETAILS;
                } else if (creditorGroup === 'AI') {
                    return LoanAgencyPaymentErrors.MISSING_CREDITOR_ALL_AI_DETAILS;
                } else {
                    return LoanAgencyPaymentErrors.INVALID_CREDITOR_GROUP;
                }
            default:
                return LoanAgencyPaymentErrors.MISSING_PAYMENT_TYPE;
        }
    }

    public postToApiBoomiService(paymentFile: IPain00100103Json): Observable<any> {
        return this.http.post('/api/v1/lance', paymentFile, { headers: { 'Content-Type': 'application/json' } });
    }

    public extrapolatePstlAdrCtryFieldFromBicField(relatedBicField: string) {
        const splitTargetField = relatedBicField.split('_');
        splitTargetField.pop();
        splitTargetField.push('PstlAdr', 'Ctry');
        return splitTargetField.join('_');
    }

    public extrapolateCountryCodeFromBic(incomingBic: string) {
        return incomingBic.substring(4, 6);
    }

    public postToBankPaymentsApiManual(formData: FormData): Observable<any> {
        const requestUrl = [this.envConfig.bankPaymentsHost, 'download-archive'].join('/');
        return this.http.post<Blob>(requestUrl, formData, { observe: 'response', responseType: 'blob' as 'json' }).pipe(
            catchError((error) => {
                //console.log(error)
                let errorMessage = '';
                if (error.status === 0) {
                    errorMessage = 'Service Bank Payment API is unavailable.';
                    return throwError(() => {
                        return errorMessage;
                    });
                } else {
                    return throwError(error);
                }
            }),
        );
    }

    public postToBankPaymentsApiDirect(paymentFile: File, bank: string): Observable<any> {
        var endpointUrl = `send-wires?bank=${bank}`;
        const requestUrl = [this.envConfig.bankPaymentsHost, endpointUrl].join('/');
        return this.http.post(requestUrl, paymentFile, { headers: { Accept: '*/*' } });
    }

    private envConfig: IEnvConfiguration;
    constructor(
        private utilsService: UtilsService,
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {
        configurationService.envConfig
            .subscribe((envConfig) => {
                this.envConfig = envConfig;
            })
            .unsubscribe();
    }
}
