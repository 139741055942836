import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { handleMissingServerError } from '../../../core/utils/http-response-handlers';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { AddLender, EditLender, Lender, LenderFileInfoWithName } from './lenders.types';

@Injectable({
    providedIn: 'root',
})
export class LenderService {
    private serverName: string = 'Services Bank Payments API';
    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    public getLenders(): Observable<Lender[]> {
        const requestUrl = [this.configurationService.envConfig.getValue().bankPaymentsHost, 'lenders'].join('/');
        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getLenderFileInfoByLenderId(lenderId: number): Observable<LenderFileInfoWithName[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bankPaymentsHost,
            'lenders',
            lenderId,
            'files',
        ].join('/');
        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public addLender(lender: AddLender, file?: File): Observable<Lender> {
        const requestUrl = [this.configurationService.envConfig.getValue().bankPaymentsHost, 'lenders'].join('/');
        const formData = new FormData();
        if (file !== null) {
            formData.append('fileToUpload', file, file.name);
        }
        formData.append('lender', JSON.stringify(lender));
        formData.append('is_primary_tax_form_file', 'true');
        return this.http.post(requestUrl, formData).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public editLender(lender: EditLender, file?: File): Observable<Lender> {
        const requestUrl = [this.configurationService.envConfig.getValue().bankPaymentsHost, 'lenders', lender.id].join(
            '/',
        );
        const formData = new FormData();
        if (file !== null) {
            formData.append('fileToUpload', file, file.name);
        }
        formData.append('lender', JSON.stringify(lender));
        formData.append('is_primary_tax_form_file', 'true');
        return this.http.put(requestUrl, formData).pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
