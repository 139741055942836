import { Component, ViewChild, Input, TemplateRef, ElementRef, OnChanges } from '@angular/core';
import { TableDataTemplate, TableHeaderTemplate } from '../../../lance/issuers/shared/issuers.types';
import { Table } from 'primeng/table';
import {
    LENDER_REVIEW_STATUSES,
    LenderTableListItem,
    TAX_FORM_TYPES,
} from '../../../lance/lender-management/shared/lenders.types';

export type RowAction = {
    backgroundColor: 'primary' | 'accent' | 'warn';
    icon: string;
    action: Function;
};

export type TableAction = {
    backgroundColor: 'primary' | 'accent' | 'warn';
    text: string;
    action: Function;
};

export type DetailAction = {
    text: string;
    action: Function;
};

@Component({
    selector: 'app-lender-table',
    templateUrl: './lender-table.component.html',
    styleUrls: ['./lender-table.component.scss'],
})
export class LenderTableComponent implements OnChanges {
    @Input() TableTitle: string;
    @Input() TableData: LenderTableListItem[];
    @Input() TableHeaders: TableHeaderTemplate[];
    @Input() BankId: string;

    @Input() RowActions: RowAction[];
    @Input() TableActions: TableAction[];
    @Input() DetailActions: DetailAction[];
    @Input() result: string;

    @ViewChild('tableContainer') tableContainer: TemplateRef<ElementRef>;
    @ViewChild('dt') tableDetail: Table;

    public selectedRows: TableDataTemplate[] = [];
    public ReviewStatuses = LENDER_REVIEW_STATUSES;
    public TaxFormTypes = TAX_FORM_TYPES;

    constructor() {}

    ngOnChanges(): void {
        if (this.tableDetail) {
            this.tableDetail.reset();
        }
    }
}
