import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountModalConfigData, BankAccount, EditAccount } from '../shared/accounts.types';
import { AccountsService } from '../shared/accounts.service';
import { selectField } from '../shared/accounts.types';
import { currencies, states } from '../../shared/shared-types';
import { AddAccont } from '../shared/accounts.types';

@Component({
    selector: 'app-account-modal',
    templateUrl: './account-modal.component.html',
    styleUrls: ['./account-modal.component.scss'],
})
export class AccountModalComponent implements OnInit, OnDestroy {
    public areChangesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public bankAccountControl: FormControl = new FormControl('');

    private addressCheck: ValidatorFn = (form: FormGroup) => {
        const accountAddress1 = form.get('accountAddress1');
        const accountCity = form.get('accountCity');
        const accountState = form.get('accountState');
        const accountZipCode = form.get('accountZipCode');

        if (
            accountAddress1.value !== null &&
            accountCity.value !== null &&
            accountState.value !== null &&
            accountZipCode.value !== null
        ) {
            if (
                accountAddress1.value !== '' &&
                accountCity.value !== '' &&
                accountState.value['value'] !== '' &&
                accountZipCode.value !== ''
            ) {
                return null;
            } else if (
                accountAddress1.value === '' &&
                accountCity.value === '' &&
                accountState.value['value'] === '' &&
                accountZipCode.value === ''
            ) {
                return null;
            } else {
                const error = {
                    addressError: 'Please enter all the required address fields, Address1, city, state, zip code',
                };
                return error;
            }
        } else if (
            accountAddress1.value === null &&
            accountCity.value === null &&
            accountState.value === null &&
            accountZipCode.value === null
        ) {
            return null;
        } else {
            const error = {
                addressError: 'Please enter all the required address fields, Address1, city, state, zip code',
            };
            return error;
        }
    };

    public accountForm = new FormGroup(
        {
            accountNumber: new FormControl<string>(undefined, [
                Validators.required,
                Validators.pattern(/^[a-zA-Z0-9]/),
            ]),
            accountCurrency: new FormControl<selectField>(undefined, [Validators.required]),
            accountName: new FormControl<string>('', Validators.maxLength(200)),
            accountAddress1: new FormControl<string | undefined>(undefined, Validators.maxLength(200)),
            accountAddress2: new FormControl<string | undefined>(undefined, Validators.maxLength(200)),
            accountCity: new FormControl<string | undefined>(undefined, Validators.maxLength(100)),
            accountState: new FormControl<selectField | undefined>(undefined),
            accountZipCode: new FormControl<string | undefined>(undefined, Validators.maxLength(5)),
        },
        { validators: [this.addressCheck] },
    );
    public filteredCurrencies: BehaviorSubject<selectField[]> = new BehaviorSubject<selectField[]>([]);
    public currenciesFilterControl: FormControl = new FormControl('');
    public statesFilterControl: FormControl = new FormControl('');
    public filteredStates: BehaviorSubject<selectField[]> = new BehaviorSubject<selectField[]>([]);

    public modalTooltip: string = 'Changes have not been saved!';

    private componentSubscriptions: Subscription[] = [];
    private lastSavedAccountForm;
    private updateData: boolean = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<AccountModalComponent>,
        private snackBar: MatSnackBar,
        private accountsService: AccountsService,
        @Inject(MAT_DIALOG_DATA)
        public accountModalData: AccountModalConfigData,
    ) {
        if (this.accountModalData.selectedAccount) {
            this.accountForm.controls.accountNumber.setValue(this.accountModalData.selectedAccount.number);
            this.accountForm.controls.accountName.setValue(this.accountModalData.selectedAccount.name);
            this.accountForm.controls.accountCurrency.setValue(
                currencies.filter((element) => element.value === this.accountModalData.selectedAccount.currency)[0],
            );
            this.accountForm.controls.accountCurrency.disable();
            this.accountForm.controls.accountAddress1.setValue(this.accountModalData.selectedAccount.address1);
            this.accountForm.controls.accountAddress2.setValue(this.accountModalData.selectedAccount.address2);
            this.accountForm.controls.accountCity.setValue(this.accountModalData.selectedAccount.city);
            this.accountForm.controls.accountState.setValue(
                states.filter((element) => element.value === this.accountModalData.selectedAccount.state)[0],
            );
            this.accountForm.controls.accountZipCode.setValue(this.accountModalData.selectedAccount.zip);
        }
    }

    ngOnInit(): void {
        this.componentSubscriptions.push(
            this.accountForm.valueChanges.subscribe((values) => {
                this.areChangesSaved.next(JSON.stringify(values) === JSON.stringify(this.lastSavedAccountForm));
            }),
        );
        this.componentSubscriptions.push(
            this.currenciesFilterControl.valueChanges.subscribe((value) => {
                this.filterCurrencies(value);
            }),
            this.statesFilterControl.valueChanges.subscribe((value) => {
                this.filterStates(value);
            }),
        );
        this.filteredCurrencies.next(currencies);
        this.filteredStates.next(states);
    }

    ngOnDestroy() {
        for (let sub of this.componentSubscriptions) {
            sub.unsubscribe();
        }
    }

    private filterCurrencies(filterValue: string) {
        if (!filterValue) {
            this.filteredCurrencies.next(currencies.slice());
            return;
        } else {
            filterValue = filterValue.toLowerCase();
        }
        this.filteredCurrencies.next(
            currencies.filter((currency) => currency.label.toLowerCase().indexOf(filterValue) > -1),
        );
    }

    private filterStates(filterValue: string) {
        if (!filterValue) {
            this.filteredStates.next(states.slice());
            return;
        } else {
            filterValue = filterValue.toLowerCase();
        }
        this.filteredStates.next(states.filter((state) => state.label.toLowerCase().indexOf(filterValue) > -1));
    }

    public closeModal() {
        this.dialogRef.close({
            updateData: this.updateData,
            areChangesSaved: this.areChangesSaved.getValue(),
        });
    }

    public saveAccount() {
        if (this.areChangesSaved.getValue()) {
            this.snackBar.open(`There are no changes to save`, undefined, { duration: 3000 });
            return;
        }
        if (this.accountModalData.action === 'create') {
            let accountToSubmit: AddAccont;
            accountToSubmit = {
                bank_id: this.accountModalData.selectedBank,
                name: this.accountForm.value.accountName ? this.accountForm.value.accountName : '',
                number: this.accountForm.value.accountNumber.toString(),
                currency: this.accountForm?.value.accountCurrency['value'],
                address1: this.accountForm?.value.accountAddress1 ? this.accountForm.value.accountAddress1 : '',
                address2: this.accountForm.value.accountAddress2 ? this.accountForm.value.accountAddress2 : '',
                city: this.accountForm.value.accountCity ? this.accountForm.value.accountCity : '',
                state: this.accountForm.value.accountState ? this.accountForm.value.accountState['value'] : '',
                zip: this.accountForm.value.accountZipCode ? this.accountForm.value.accountZipCode : '',
            };
            this.accountsService.addAccount(accountToSubmit).subscribe({
                next: (res) => {
                    this.areChangesSaved.next(true);
                    this.updateData = true;
                    this.closeModal();
                },
                error: (error: HttpErrorResponse) => {
                    console.error(error);
                    let errorMessage;
                    if (error.error.details) {
                        errorMessage = error.error.message;
                        error.error.details.map((message) => {
                            errorMessage += ' | ' + message;
                        });
                    } else {
                        errorMessage = `${error.error.message} | ${error.message}`;
                    }
                    this.snackBar.open(errorMessage);
                },
            });
        }
        if (this.accountModalData.action === 'edit') {
            let accountToSubmit: EditAccount = {
                id: this.accountModalData.selectedAccount.id,
                bank_id: this.accountModalData.selectedBank,
                name: this.accountForm.value.accountName ? this.accountForm.value.accountName : '',
                number: this.accountForm.value.accountNumber.toString(),
                currency: this.accountModalData.selectedAccount.currency,
                address1: this.accountForm?.value.accountAddress1 ? this.accountForm.value.accountAddress1 : '',
                address2: this.accountForm.value.accountAddress2 ? this.accountForm.value.accountAddress2 : '',
                city: this.accountForm.value.accountCity ? this.accountForm.value.accountCity : '',
                state: this.accountForm.value.accountState ? this.accountForm.value.accountState['value'] : '',
                zip: this.accountForm.value.accountZipCode ? this.accountForm.value.accountZipCode : '',
            };
            this.accountsService.editAccount(accountToSubmit).subscribe({
                next: (res) => {
                    this.areChangesSaved.next(true);
                    this.updateData = true;
                    this.closeModal();
                },
                error: (error: HttpErrorResponse) => {
                    console.error(error);
                    let errorMessage;
                    if (error.error.details) {
                        errorMessage = error.error.message;
                        error.error.details.map((message) => {
                            errorMessage += ' | ' + message;
                        });
                    } else {
                        errorMessage = `${error.error.message} | ${error.message}`;
                    }
                    this.snackBar.open(errorMessage);
                },
            });
        }
    }

    public deleteAccount() {
        this.accountsService.deleteAccount(this.accountModalData.selectedAccount.id).subscribe({
            next: () => {
                this.areChangesSaved.next(true);
                this.updateData = true;
                this.closeModal();
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
                this.snackBar.open(`${error.error.message} | ${error.message}`);
            },
        });
    }
}
