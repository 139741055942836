<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="shareholders-container" id="shareholders-container">
    <ng-container
        *ngTemplateOutlet="selectedShareholderId ? singleShareholder : mutipleShareholders"
    ></ng-container>
</div>

<ng-template #mutipleShareholders>
    <div class="header-bar" id="header-bar">
        <div class="header-text-div">
            <span class="header-text" id="header-text">Shareholders</span>
        </div>
    </div>
    <div class="main">
        <div class="search-bar" id="search-bar">
            <app-intadm-search
                (newSearchEvent)="performSearch($event)"
                data-testid="app-accounts-search"
                placeholder="Filter Shareholders"></app-intadm-search>
        </div>
        <div class="table-container">
            <app-shareholder-table
                (pageEvent)="handlePageEvent($event)"
                (selected)="handleShareholderSelected($event)"
                (sortEvent)="handleSortChange($event)"
                [fetching]="fetching"
                [pageSize]="pageSize"
                [page]="page"
                [searchText]="searchValue"
                [shareholderRecords]="shareholderRecords"
                [totalRecords]="totalRecords"
                class="shareholders-table"
                data-testid="app-shareholders-table"
            ></app-shareholder-table>
        </div>
    </div>
</ng-template>

<ng-template #singleShareholder>
    <div class="wrapper-div">
        <div class="page-title-actions">
            <div class="title-actions">
                <span class="title">{{selectedShareholder?.shareholder}}</span>
                <div class="actions">
                    <div class="action-icon action-icon-border"
                         (click)="shareholderActivityClick()"
                         data-testid="shareholder-activity-button"
                    >
                        <span class="action-label">Shareholder Activity</span>
                    </div>
                    <div class="action-icon action-icon-primary"
                         (click)="shareholderPortalClick()"
                         data-testid="shareholder-dashboard-button"
                    >
                        <span class="action-label">Shareholder Portal</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="frame-div">
            <div class="shareholder-details-div">
                <div class="shareholder-details-scroll">
                    <div class="info-div">
                        <div class="info-label">SHAREHOLDER INFO</div>
                        <div class="info-data">
                            <span class="info-data-label">Shareholder Email</span>
                            <span class="info-data-value">{{selectedShareholder?.email}}</span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">NetSuite ID</span>
                            <span class="as-link"
                                  (click)="openInNewTab($event, getShareholderNsUrl(selectedShareholder?.nsId))"
                                  [matTooltip]="getShareholderNsUrl(selectedShareholder?.nsId)"
                                  matTooltipPosition="below"
                                  matTooltipShowDelay="300"
                                  matTooltipHideDelay="200"
                            >{{selectedShareholder?.nsId}}
                            </span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Address</span>
                            <span class="info-data-value">{{selectedShareholder?.address1}}</span>
                            <span class="info-data-value">{{selectedShareholder?.address2}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="deals-users-section-div">
                <div class="deals-users-frame-div">
                    <div class="deals-users-search-bar">
                        <app-intadm-search
                            data-testid="app-accounts-search-users"
                            placeholder="Filter by deal, user, or permission..."
                            (newSearchEvent)="performSearch($event)">
                        </app-intadm-search>
                    </div>
                </div>
                <div class="table-container">
                    <app-shareholder-deal-user-table
                        class="deals-users-table"
                        data-testid="app-deal-user-table"
                        [displayedColumns]="displayedColumns"
                        [records]="shareholderDealUserRecords"
                        [searchText]="searchValueDealsUsers"
                        [page]="pageDealsUsers"
                        [pageSize]="pageSizeDealsUsers"
                        [totalRecords]="totalRecordsDealsUsers"
                        [fetching]="fetchingDealsUsers"
                        [showRevokeAccess]="showRevokeAccess"
                        (pageEvent)="handlePageEventDealsUsers($event)"
                        (sortEvent)="handleSortChangeDealsUsers($event)"
                        (revokeAccessEvent)="handleRevokeAccessEvent($event)"
                    ></app-shareholder-deal-user-table>
<!--                        [showCreateUser]="showCreateUser"-->
<!--                        (userToEditEvent)="handleUserToEditEvent($event)"-->
<!--                        (editModeEvent)="handleEditModeEvent($event)"-->
<!--                        (selected)="handleUserSelected($event)"-->
<!--                        (userActionNav)="handleUserAction($event)"-->
                </div>
            </div>
        </div>
    </div>
</ng-template>
