<div class="armory__sidenav" *ngIf="opened">
    <mat-nav-list>
        <ng-container *ngFor="let allowedApp of allowedApps$ | async | keyvalue">
            <a
                mat-list-item
                *appFlags="allowedApp.value.flag"
                routerLinkActive="active-list-item"
                [routerLink]="[allowedApp.value.route]"
                (click)="appClicked(allowedApp.key)"
                [queryParams]="allowedApp.value.hasSubmenu ? {clear: true} : null"
            >
                <mat-icon>{{ allowedApp.value.icon }}</mat-icon>
                <span class="label_name menu-label-name"
                      [class.menu-item-active]="routerUrl.indexOf(allowedApp.value.route) >= 0">
                    {{ allowedApp.value.label }}
                </span>
                <mat-icon *ngIf="allowedApp.value.hasSubmenu && allowedApp.key === currentApp && submenuExpanded"
                          (click)="toggleIcon($event)">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="allowedApp.value.hasSubmenu && ((allowedApp.key === currentApp && !submenuExpanded) || (allowedApp.key !== currentApp))"
                          (click)="toggleIcon($event)">keyboard_arrow_right</mat-icon>
            </a>
            <ng-container *ngIf="allowedApp.value.hasSubmenu && allowedApp.key === currentApp && submenuExpanded && submenuItems$ | async as submenuItems">
                <a
                    mat-list-item
                    [class.active-list-item-submenu]="routerUrl.indexOf('/' + childPath.path) > 0"
                    *ngFor="let childPath of submenuItems.childPaths"
                    routerLink="{{submenuItems.parentPath + '/' + childPath.path}}"
                    [queryParams]="{clear: true}"
                >
                    <span class="submenu-item list-item"
                          [class.menu-item-active]="routerUrl.indexOf('/' + childPath.path) > 0"
                          [class.submenu-item-inactive]="routerUrl.indexOf('/' + childPath.path) < 0">
                        {{childPath.displayName}}
                    </span>
                </a>
            </ng-container>
        </ng-container>
    </mat-nav-list>
</div>
