import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddLender, EditLender, LenderModalConfigData, TAX_FORM_TYPES } from '../shared/lenders.types';
import { LenderService } from '../shared/lender.service';

@Component({
    selector: 'app-lender-modal',
    templateUrl: './lender-modal.component.html',
    styleUrls: ['./lender-modal.component.scss'],
})
export class LenderModalComponent implements OnInit, OnDestroy {
    public areChangesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public lenderForm = new FormGroup({
        wsoLenderID: new FormControl<number | undefined>(undefined, [Validators.required, Validators.pattern(/^\d*$/)]),
        lenderName: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
        taxForm: new FormControl<string>(''),
        taxFileUpload: new FormControl<File>(null),
        isEntity: new FormControl<boolean>(true),
    });
    public modalTooltip: string = 'Changes have not been saved!';

    private componentSubscriptions: Subscription[] = [];
    private lastSavedlenderForm;
    private updateData: boolean = false;

    public taxFormlist = TAX_FORM_TYPES;
    public buttonTextToDisplay: string = 'Upload PDF File';
    public primaryTaxFormName: string;

    constructor(
        @Optional() public dialogRef: MatDialogRef<LenderModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public lenderModalData: LenderModalConfigData,
        private snackBar: MatSnackBar,
        private lenderService: LenderService,
    ) {
        if (this.lenderModalData.action === 'edit' && this.lenderModalData.selectedLender) {
            this.lenderForm.controls.wsoLenderID.setValue(this.lenderModalData.selectedLender.wso_id);
            this.lenderForm.controls.wsoLenderID.disable();
            this.lenderForm.controls.lenderName.setValue(this.lenderModalData.selectedLender.name);
            this.lenderForm.controls.taxForm.setValue(this.lenderModalData.selectedLender.primary_tax_form_type);
            this.lenderForm.controls.isEntity.setValue(this.lenderModalData.selectedLender.is_entity);
            this.getPrimaryTaxFileForLender(this.lenderModalData.selectedLender.id);
        }
    }

    ngOnInit(): void {
        this.componentSubscriptions.push(
            this.lenderForm.valueChanges.subscribe((values) => {
                this.areChangesSaved.next(JSON.stringify(values) === JSON.stringify(this.lastSavedlenderForm));
            }),
        );
    }

    ngOnDestroy() {
        for (let sub of this.componentSubscriptions) {
            sub.unsubscribe();
        }
    }

    public closeModal() {
        this.dialogRef.close({ updateData: this.updateData, areChangesSaved: this.areChangesSaved.getValue() });
    }

    public saveLender() {
        let lender: AddLender & { id?: string } = {
            primary_tax_form_type: this.lenderForm.controls.taxForm.value,
            name: this.lenderForm.value.lenderName,
            wso_id: this.lenderForm.controls.wsoLenderID.value,
            review_status_id: '1',
            is_entity: this.lenderForm.controls.isEntity.value,
        };
        if (this.areChangesSaved.getValue()) {
            this.snackBar.open(`There are no changes to save`, undefined, { duration: 1500 });
            return;
        }

        let file = this.lenderForm.controls.taxFileUpload.value;
        if (!this.lenderModalData.selectedLender) {
            this.lenderService.addLender(lender, file).subscribe({
                next: (res) => {
                    this.updateData = true;
                    this.snackBar.open(`Lender successfully added`, undefined, { duration: 1500 });
                    this.areChangesSaved.next(true);
                    this.closeModal();
                },
                error: (error: HttpErrorResponse) => {
                    console.error(error);
                    this.snackBar.open(`${error.error.message} | ${error.error.details}`);
                },
            });
        } else {
            lender.id = this.lenderModalData.selectedLender.id;
            this.lenderService.editLender(lender as EditLender, file).subscribe({
                next: (res) => {
                    this.updateData = true;
                    this.snackBar.open(`Lender successfully updated`, undefined, { duration: 1500 });
                    this.lenderForm.reset();
                    this.areChangesSaved.next(true);
                    this.closeModal();
                },
                error: (error: HttpErrorResponse) => {
                    console.error(error);
                    this.snackBar.open(`${error.error.message} | ${error.error.details}`);
                },
            });
        }
    }

    getPrimaryTaxFileForLender(lenderId) {
        this.lenderService.getLenderFileInfoByLenderId(lenderId).subscribe({
            next: (res) => {
                res.forEach((file) => {
                    if (file.is_primary) {
                        this.primaryTaxFormName = file.name;
                    }
                });
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
            },
        });
    }
}
