import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, first } from 'rxjs/operators';
import { catchError, Observable, throwError } from 'rxjs';
import { IEnvConfiguration } from '../../../core/configuration.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { UtilsService } from '../../../core/services/utils.service';
import { addPortfolio, editPortfolio } from './portfolios.types';

@Injectable({
    providedIn: 'root',
})
export class PortfoliosService {
    public getPortfolios(): Observable<any> {
        const requestUrl = [this.envConfig.bankPaymentsHost, 'portfolios'].join('/');
        return this.http.get(requestUrl).pipe(
            catchError((error) => {
                let errorMessage = '';
                if (error.status === 0) {
                    errorMessage = 'Service Bank Payment API is unavailable.';
                    return throwError(() => {
                        return errorMessage;
                    });
                } else {
                    return throwError(error);
                }
            }),
        );
    }

    public addPortfolios(portfolio: addPortfolio): Observable<any> {
        const requestUrl = [this.envConfig.bankPaymentsHost, 'portfolios'].join('/');
        return this.http.post(requestUrl, portfolio, { headers: { 'Content-Type': 'application/json' } }).pipe(
            catchError((error) => {
                let errorMessage = '';
                if (error.status === 0) {
                    errorMessage = 'Service Bank Payment API is unavailable.';
                    return throwError(() => {
                        return errorMessage;
                    });
                } else {
                    return throwError(error);
                }
            }),
        );
    }

    public editPortfolio(portfolio: editPortfolio): Observable<any> {
        const requestUrl = [this.envConfig.bankPaymentsHost, 'portfolios', portfolio.id].join('/');
        return this.http.put(requestUrl, portfolio).pipe(
            catchError((error) => {
                let errorMessage = '';
                if (error.status === 0) {
                    errorMessage = 'Service Bank Payment API is unavailable.';
                    return throwError(() => {
                        return errorMessage;
                    });
                } else {
                    return throwError(error);
                }
            }),
        );
    }

    public deletePortfolio(portfolioId: number): Observable<any> {
        const requestUrl = [this.envConfig.bankPaymentsHost, 'portfolios', portfolioId].join('/');
        return this.http.delete(requestUrl).pipe(
            catchError((error) => {
                let errorMessage = '';
                if (error.status === 0) {
                    errorMessage = 'Service Bank Payment API is unavailable.';
                    return throwError(() => {
                        return errorMessage;
                    });
                } else {
                    return throwError(error);
                }
            }),
        );
    }

    private envConfig: IEnvConfiguration;
    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {
        configurationService.envConfig
            .subscribe((envConfig) => {
                this.envConfig = envConfig;
            })
            .unsubscribe();
    }
}
