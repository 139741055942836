import { Component } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { NavigationHelperService } from '../../core/services/navigation-helper.service';

@Component({
    selector: 'app-armory-header',
    templateUrl: './armory-header.component.html',
    styleUrls: ['./armory-header.component.scss'],
})
export class ArmoryHeaderComponent {
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private navigationHelperService: NavigationHelperService,
    ) {}

    public async logout() {
        await this.authService.logout();
    }

    public get isDoneLoading$() {
        return this.authService.isAuthDoneLoading$;
    }

    public get isAuthenticated$() {
        return this.userService.isAuthenticated$;
    }

    public login() {
        this.authService.login();
    }

    public toggleSidebar() {
        this.navigationHelperService.toggleSidebar();
    }
}
