<div class="home">
  <ng-container *ngFor="let allowedApp of allowedApps$ | async | keyvalue">
    <a
        *appFlags="allowedApp.value.flag"
        [routerLink]="allowedApp.value.route"
        class="home__app-card-link"
        (click)="appClicked(allowedApp.key)"
    >
      <mat-card appearance="outlined" class="home__app-card">
        <mat-card-subtitle class="app-card__title">
          {{allowedApp.value.label | uppercase}}
        </mat-card-subtitle>
        <mat-card-content class="app-card__content">
          <mat-icon>{{allowedApp.value.icon}}</mat-icon>
        </mat-card-content>
      </mat-card>
    </a>
  </ng-container>
</div>
