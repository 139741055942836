import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth-guard.service';
import { LanceComponent } from './lance.component';
import { AllowedRoles } from '../core/guards/allowed-roles.guard';
import { FeatureToggleGuard } from '../core/guards/feature-toggle.guard';
import { LoanAgencyComponent } from './loan-agency/loan-agency.component';
import { OneLoginRoles } from '../core/services/auth.service';
import { IssuersComponent } from './issuers/issuers.component';
import { PortfoliosComponent } from './portfolios/portfolios.component';
import { AccountsComponent } from './accounts/accounts.component';
import { LenderManagementComponent } from './lender-management/lender-management.component';

const routes: Routes = [
    {
        path: 'lance',
        component: LanceComponent,
        canActivate: [AuthGuard, AllowedRoles, FeatureToggleGuard],
        data: {
            AllowedRolesData: {
                oneOfRoles: [
                    OneLoginRoles.loanAgency,
                    OneLoginRoles.loanAgencyAdmin,
                    OneLoginRoles.loanAgencyGeneral,
                    OneLoginRoles.armoryAuditor,
                ],
            },
            requiredFeatureFlag: 'lance',
            featureFlagRedirect: '/',
        },
        children: [
            {
                path: '',
                redirectTo: '/lance/loan-agency-payments',
                pathMatch: 'full',
            },
            {
                path: 'loan-agency-payments',
                component: LoanAgencyComponent,
                canActivate: [AuthGuard, AllowedRoles, FeatureToggleGuard],
                data: {
                    AllowedRolesData: {
                        oneOfRoles: [
                            OneLoginRoles.loanAgency,
                            OneLoginRoles.loanAgencyAdmin,
                            OneLoginRoles.loanAgencyGeneral,
                            OneLoginRoles.armoryAuditor,
                        ],
                    },
                    requiredFeatureFlag: 'loanAgencyPayments',
                    featureFlagRedirect: '/',
                },
            },
            {
                path: 'issuers',
                component: IssuersComponent,
                canActivate: [AuthGuard, AllowedRoles, FeatureToggleGuard],
                data: {
                    AllowedRolesData: {
                        oneOfRoles: [
                            OneLoginRoles.loanAgencyAdmin,
                            OneLoginRoles.loanAgencyGeneral,
                            OneLoginRoles.armoryAuditor,
                        ],
                    },
                    requiredFeatureFlag: 'issuers',
                    featureFlagRedirect: '/',
                },
            },
            {
                path: 'portfolios',
                component: PortfoliosComponent,
                canActivate: [AuthGuard, AllowedRoles, FeatureToggleGuard],
                data: {
                    AllowedRolesData: {
                        oneOfRoles: [
                            OneLoginRoles.loanAgencyAdmin,
                            OneLoginRoles.loanAgencyGeneral,
                            OneLoginRoles.armoryAuditor,
                        ],
                    },
                    requiredFeatureFlag: 'portfolios',
                    featureFlagRedirect: '/',
                },
            },
            {
                path: 'accounts',
                component: AccountsComponent,
                canActivate: [AuthGuard, AllowedRoles, FeatureToggleGuard],
                data: {
                    AllowedRolesData: {
                        oneOfRoles: [
                            OneLoginRoles.loanAgencyAdmin,
                            OneLoginRoles.loanAgencyGeneral,
                            OneLoginRoles.armoryAuditor,
                        ],
                    },
                    requiredFeatureFlag: 'accounts',
                    featureFlagRedirect: '/',
                },
            },
            {
                path: 'lender-management',
                component: LenderManagementComponent,
                canActivate: [AuthGuard, AllowedRoles, FeatureToggleGuard],
                data: {
                    AllowedRolesData: {
                        oneOfRoles: [
                            OneLoginRoles.loanAgencyAdmin,
                            OneLoginRoles.loanAgencyGeneral,
                            OneLoginRoles.armoryAuditor,
                        ],
                    },
                    requiredFeatureFlag: 'lenderManagement',
                    featureFlagRedirect: '/',
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LanceRoutingModule {}
