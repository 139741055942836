import { Component, OnInit, Inject, OnDestroy, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { addPortfolio, editPortfolio, Portfolio, PortfolioModalConfigData } from '../shared/portfolios.types';
import { PortfoliosService } from '../shared/portfolios.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-portfolio-modal',
    templateUrl: './portfolio-modal.component.html',
    styleUrls: ['./portfolio-modal.component.scss'],
})
export class PortfolioModalComponent implements OnInit, OnDestroy {
    public title: string = '';
    public portfolioForm = new FormGroup({
        portfolioName: new FormControl<string>('', [Validators.required, Validators.maxLength(40)]),
        portfolioDescription: new FormControl<string>('', Validators.maxLength(200)),
        wsoId: new FormControl<number | undefined>(undefined, [Validators.required, Validators.pattern(/^\d*$/)]),
    });
    private componentSubscriptions: Subscription[] = [];
    public areChangesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private updateData: boolean = false;
    private lastSavedPortfolioForm;
    public modalTooltip: string = 'Changes have not been saved!';

    constructor(
        @Optional() public dialogRef: MatDialogRef<PortfolioModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public portfolioModalData: PortfolioModalConfigData,
        private portfoliosService: PortfoliosService,
        private _formBuilder: UntypedFormBuilder,
        private snackBar: MatSnackBar,
    ) {
        if (this.portfolioModalData.action === 'edit' && this.portfolioModalData.selectedPortfolio) {
            this.portfolioForm.controls.wsoId.setValue(this.portfolioModalData.selectedPortfolio.wso_portfolio_id);
            this.portfolioForm.controls.wsoId.disable();
            this.portfolioForm.controls.portfolioName.setValue(this.portfolioModalData.selectedPortfolio.name);
            this.portfolioForm.controls.portfolioDescription.setValue(
                this.portfolioModalData.selectedPortfolio.description,
            );
            this.lastSavedPortfolioForm = this.portfolioForm.value;
        }
    }

    ngOnInit(): void {
        this.componentSubscriptions.push(
            this.portfolioForm.valueChanges.subscribe((values) => {
                this.areChangesSaved.next(JSON.stringify(values) === JSON.stringify(this.lastSavedPortfolioForm));
            }),
        );
    }

    public setPortfolioForm(portfolio: Portfolio) {
        this.portfolioForm.controls.wsoId.setValue(portfolio.wso_portfolio_id);
        this.portfolioForm.controls.wsoId.disable();
        this.portfolioForm.controls.portfolioName.setValue(portfolio.name);
        this.portfolioForm.controls.portfolioDescription.setValue(portfolio.description);
        const { id, name, description, wso_portfolio_id } = portfolio;
        this.portfolioModalData.selectedPortfolio = {
            id,
            name,
            description,
            wso_portfolio_id,
        };
        this.lastSavedPortfolioForm = this.portfolioForm.value;
        this.areChangesSaved.next(true);
        this.updateData = true;
    }

    public savePortfolio() {
        let portfolio: addPortfolio & { id?: number } = {
            wso_portfolio_id: this.portfolioForm.controls.wsoId.value,
            name: this.portfolioForm.value.portfolioName,
            description: this.portfolioForm.value.portfolioDescription,
        };
        if (this.areChangesSaved.getValue()) {
            this.snackBar.open(`There are no changes to save`, undefined, { duration: 1500 });
            return;
        }

        if (!this.portfolioModalData.selectedPortfolio) {
            this.portfoliosService.addPortfolios(portfolio as addPortfolio).subscribe({
                next: (res) => {
                    this.setPortfolioForm(res);
                    this.snackBar.open(`Portfolio successfully created`, undefined, { duration: 1500 });
                    this.close();
                },
                error: (error: HttpErrorResponse) => {
                    console.error(error);
                    this.snackBar.open(`${error.error.message} | ${error.error.details}`);
                },
            });
        } else {
            portfolio.id = this.portfolioModalData.selectedPortfolio.id;
            this.portfoliosService.editPortfolio(portfolio as editPortfolio).subscribe({
                next: (res) => {
                    this.setPortfolioForm(res);
                    this.snackBar.open(`Portfolio successfully updated`, undefined, { duration: 1500 });
                    this.close();
                },
                error: (error: HttpErrorResponse) => {
                    console.error(error);
                    this.snackBar.open(`${error.error.message} | ${error.message}`);
                },
            });
        }
    }

    deleteRecord() {
        this.portfoliosService.deletePortfolio(Number(this.portfolioModalData.selectedPortfolio.id)).subscribe({
            next: () => {
                this.areChangesSaved.next(true);
                this.updateData = true;
                this.snackBar.open(`Portfolio deleted successfully`, undefined, { duration: 1500 });
                setTimeout(() => {
                    this.close();
                }, 1500);
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
                this.snackBar.open(`${error.error.message} | ${error.message}`);
            },
        });
    }

    close() {
        this.dialogRef.close({ updateData: this.updateData, areChangesSaved: this.areChangesSaved.getValue() });
    }

    ngOnDestroy() {
        for (let sub of this.componentSubscriptions) {
            sub.unsubscribe();
        }
    }
}
