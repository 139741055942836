<div class="main-div" id="main-div">
    <table (matSortChange)="handleSortChange($event)" [dataSource]="dataSource" class="table-div" mat-table matSort>
        <ng-container matColumnDef="shareholder">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="shareholder"> Shareholder</th>
            <td *matCellDef="let element"
                [ignoreCase]="true"
                [searchedWords]="searchText"
                [text]="element.shareholder"
                appHighlight
                classToApply="selected"
                mat-cell
            >{{ element.shareholder }}
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr (click)="handleShareholderSelected(row)" *matRowDef="let row; columns: displayedColumns" class="intadm-table-row"
            mat-row></tr>

        <!-- Row shown when there is no matching data. -->
        <tr *matNoDataRow class="mat-row">
            <td class="mat-cell no-data" colspan="10"><span *ngIf="!fetching">No data</span></td>
        </tr>
    </table>
</div>
<div class="paginator-div">
    <mat-paginator (page)="handlePageEvent($event)"
                   [length]="totalRecords"
                   [pageSizeOptions]="[10, 25, 50, 100]"
                   [pageSize]="pageSize"
                   aria-label="Select page"
                   disabled=false
                   hidePageSize=false
                   showFirstLastButtons=true>
    </mat-paginator>
</div>
