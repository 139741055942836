import { GroupHeaderAuthorizationCode, PaymentMethodCode, ChargeBearerTypeCode } from './pain_001_001_03.types';

export const Banks = {
    CITIZENS: {
        id: 'cfg',
        name: 'Citizens',
    },
    HSBC: {
        id: 'hsbc',
        name: 'HSBC',
    },
};

export interface BankField {
    id: string;
    name: string;
}

export interface ILoanAgencyRecord {
    Asset_Name: string;
    Issuer_Name: string;
    Portfolio_Name: string;
    Portfolio_ID: string;
    Issuer_ID: string;
    Facility_ID: string;
    Position_Name: string;
    Portfolio_Institution_ID: string;
    TranCash_Amount: string | number;
    TranCash_Name: string;
    TranCash_Source_ID: string;
    TranCash_Source_Type: string;
    TranCash_ReceiveDate: string;
    TranCash_ID: string;
    TranCash_ActionCode_ID: string;
    TranCash_CurrencyType_Identifier: string;
    TranCash_CurrencyType_ID: string;
    TranCash_ActionCodeBase_ID: string;
    Position_ID: string;
    INT_BC_ABA?: string;
    INT_BC_BIC?: string;
    INT_BC_AcctName?: string;
    INT_BC_AcctNumb?: string;
    INT_BC_IBAN?: string;
    INT_II_ABA?: string;
    INT_II_BIC?: string;
    INT_II_AcctName?: string;
    INT_II_AcctNumb?: string;
    INT_II_IBAN?: string;
    INT_AI_ABA?: string;
    INT_AI_BIC?: string;
    INT_AI_AcctName?: string;
    INT_AI_AcctNumb?: string;
    INT_AI_IBAN?: string;
    PRN_BC_ABA?: string;
    PRN_BC_BIC?: string;
    PRN_BC_AcctName?: string;
    PRN_BC_AcctNumb?: string;
    PRN_BC_IBAN?: string;
    PRN_II_ABA?: string;
    PRN_II_BIC?: string;
    PRN_II_AcctName?: string;
    PRN_II_AcctNumb?: string;
    PRN_II_IBAN?: string;
    PRN_AI_ABA?: string;
    PRN_AI_BIC?: string;
    PRN_AI_AcctName?: string;
    PRN_AI_AcctNumb?: string;
    PRN_AI_IBAN?: string;
    FEE_BC_ABA?: string;
    FEE_BC_BIC?: string;
    FEE_BC_AcctName?: string;
    FEE_BC_AcctNumb?: string;
    FEE_BC_IBAN?: string;
    FEE_II_ABA?: string;
    FEE_II_BIC?: string;
    FEE_II_AcctName?: string;
    FEE_II_AcctNumb?: string;
    FEE_II_IBAN?: string;
    FEE_AI_ABA?: string;
    FEE_AI_BIC?: string;
    FEE_AI_AcctName?: string;
    FEE_AI_AcctNumb?: string;
    FEE_AI_IBAN?: string;
    ALL_BC_ABA?: string;
    ALL_BC_BIC?: string;
    ALL_BC_AcctName?: string;
    ALL_BC_AcctNumb?: string;
    ALL_BC_IBAN?: string;
    ALL_II_ABA?: string;
    ALL_II_BIC?: string;
    ALL_II_AcctName?: string;
    ALL_II_AcctNumb?: string;
    ALL_II_IBAN?: string;
    ALL_AI_ABA?: string;
    ALL_AI_BIC?: string;
    ALL_AI_AcctName?: string;
    ALL_AI_AcctNumb?: string;
    ALL_AI_IBAN?: string;
}

export interface MismatchedColumnHeaderError {
    message: string;
    invalidRowNumber: number;
    type: string;
}

export interface CitizenRecord {
    Column: string;
    Message: string;
    ValidationName: String;
}

export interface UnexpectedError {
    message: string;
    MismatchedColumnHeaderError?: MismatchedColumnHeaderError[];
}

export interface ExpectedError {
    Status: string;
    Message: string;
    Errors: ICitizenRecord[];
}

export interface IErrorRecord {
    lineNumber: number[];
    errors: string[];
    record: ILoanAgencyRecord;
}

export interface IWarningRecord {
    lineNumber: number[];
    warnings: string[];
    record: ILoanAgencyRecord;
}
export interface ICitizenRecord {
    lineNumber: number;
    record: CitizenRecord[];
}

export interface ILoanAgencyValidationResult {
    column?: string;
    status: 'warning' | 'error' | 'success';
    message?: string;
}

export interface ILoanAgencyResultAggregate {
    status: 'warning' | 'error' | 'success';
    errors?: string[];
    warnings?: string[];
}

export interface IAggregateLoanAgencyRecord {
    aggregatedRows: ILoanAgencyRecord[];
    aggregatedRowNumbers: number[];
}

export interface IGroupedPaymentRecords {
    [key: string]: {
        interest?: IAggregateLoanAgencyRecord;
        paydown?: IAggregateLoanAgencyRecord;
        fee?: IAggregateLoanAgencyRecord;
        exclude?: IAggregateLoanAgencyRecord;
    };
}

export interface IFlattenedPayment {
    aggregatedPaymentData: ILoanAgencyRecord;
    aggregatedRowNumbers: number[];
}

export interface IPaymentRecords {
    interest: { aggregatedPaymentData: ILoanAgencyRecord; aggregatedRowNumbers?: number[] }[];
    paydown: { aggregatedPaymentData: ILoanAgencyRecord; aggregatedRowNumbers?: number[] }[];
    fee: { aggregatedPaymentData: ILoanAgencyRecord; aggregatedRowNumbers?: number[] }[];
}

export const DynamicXmlFieldTypesMap = {
    CDTR: {
        // 'CdtrAgt_FinInstnId_BIC': 'BIC',
        // 'CdtrAgt_FinInstnId_ClrSysMmbId_MmbId': 'ABA',
        Cdtr_Nm: 'AcctName',
        // 'CdtrAcct_Id_Other_Id': 'AcctNumb',
        // 'CdtrAcct_Id_IBAN': 'IBAN'
    },
    INTRMY: {
        IntrmyAgt1Acct_Id_IBAN: 'IBAN',
        // 'IntrmyAgt1_FinInstnId_BIC': 'BIC',
        // 'IntrmyAgt1_FinInstnId_ClrSysMmbId_MmbId': 'ABA',
        IntrmyAgt1Acct_Id_Other_Id: 'AcctNumb',
    },
};

export const XmlHardcodedFieldsMap = {
    CDTRADDR: {
        CdtrAgt_FinInstnId_PstlAdr_Ctry: 'US',
        Cdtr_PstlAdr_StrtNm: '3 EMBARCADERO CENTER',
        Cdtr_PstlAdr_BldgNb: '5TH FLOOR',
        Cdtr_PstlAdr_PstCd: '94111',
        Cdtr_PstlAdr_TwnNm: 'SAN FRANCISCO',
        Cdtr_PstlAdr_CtrySubDvsn: 'CA',
        Cdtr_PstlAdr_Ctry: 'US',
    },
    GRPHDR: {
        GrpHdr_Authstn_Cd: GroupHeaderAuthorizationCode.ILEV,
    },
    PMTINFO: {
        PmtInf_PmtMtd: PaymentMethodCode.TRF,
        PmtInf_PmtTpInf_SvcLvl_Cd: 'URGP',
        ChrgBr: ChargeBearerTypeCode.DEBT,
    },
    DBTR: {
        Dbtr_Nm: 'WHITE OAK GLOBAL ADVISORS LLC',
        Dbtr_PstlAdr_StrtNm: '3 EMBARCADERO CENTER',
        Dbtr_PstlAdr_BldgNb: '5TH FLOOR',
        Dbtr_PstlAdr_PstCd: '94111',
        Dbtr_PstlAdr_TwnNm: 'SAN FRANCISCO',
        Dbtr_PstlAdr_CtrySubDvsn: 'CA',
        Dbtr_PstlAdr_Ctry: 'US',
        // TODO: If DbtrAgt_FinInstnId_BIC is ever not hard-coded, we must extrapolate DbtrAgt_FinInstnId_PstlAdr_Ctry
        // From the new value (the 5th and 6th characters of a BIC correspond to the country code)
        DbtrAgt_FinInstnId_BIC: 'MRMDUS33',
        DbtrAgt_FinInstnId_PstlAdr_Ctry: 'US',
    },
};

export enum LoanAgencyPaymentErrors {
    // file-stopping errors
    MISSING_CRITICAL_DATA = 'This data is required and cannot be empty.',
    BANK_ISSUER_MAP_MISSING_BANK = 'The bank selected cannot be found in our bank issuer map. Please contact Support.',
    // errors which cause rows to be skipped, but do not stop the whole file
    RECORD_SKIPPED_ISSUER_ID = 'This record will be skipped as the issuer does not match the selected bank.',
    RECEIVED_DATE_OUT_OF_RANGE = 'This record will be skipped as the TranCash_ReceivedDate must be between today and 5 business days from today.',
    SELECTED_DATE_MISMATCH = 'This record will be skipped as the TranCash_ReceivedDate does not match the date selected.',
    MISSING_PAYMENT_INT_DETAILS = 'There is no beneficiary ABA or BIC number provided for either ALL- or INT-type instructions.',
    MISSING_PAYMENT_FEE_DETAILS = 'There is no beneficiary ABA or BIC number provided for either ALL- or FEE-type instructions.',
    MISSING_PAYMENT_PRN_DETAILS = 'There is no beneficiary ABA or BIC number provided for either ALL- or PRN-type instructions.',
    MISSING_PAYMENT_TYPE = 'Unable to determine payment type. Please provide a valid TranCash_ActionCode_ID and corresponding beneficiary payment details.',
    INVALID_ABA = 'An ABA number must be 9 numeric characters.',
    INVALID_BIC = 'A BIC number must be 8 or 11 alphanumeric characters.',
    MISSING_FFC_INT_DETAILS = 'There must be a value in INT_BC_AcctNumb and/or INT_BC_AcctName provided.',
    MISSING_FFC_ALL_DETAILS = 'There must be a value in ALL_BC_AcctNumb and/or ALL_BC_AcctName provided.',
    MISSING_FFC_FEE_DETAILS = 'There must be a value in FEE_BC_AcctNumb and/or FEE_BC_AcctName provided.',
    MISSING_FFC_PRN_DETAILS = 'There must be a value in PRN_BC_AcctNumb and/or PRN_BC_AcctName provided.',
    MISSING_CREDITOR_INT_BC_DETAILS = 'There must be a value in INT_BC_AcctName and either INT_BC_AcctNumb or INT_BC_IBAN provided.',
    MISSING_CREDITOR_INT_II_DETAILS = 'There must be a value in INT_II_AcctName and either INT_II_AcctNumb or INT_II_IBAN provided.',
    MISSING_CREDITOR_INT_AI_DETAILS = 'There must be a value in INT_AI_AcctName and either INT_AI_AcctNumb or INT_AI_IBAN provided.',
    MISSING_CREDITOR_ALL_BC_DETAILS = 'There must be a value in ALL_BC_AcctName and either ALL_BC_AcctNumb or ALL_BC_IBAN provided.',
    MISSING_CREDITOR_ALL_II_DETAILS = 'There must be a value in ALL_II_AcctName and either ALL_II_AcctNumb or ALL_II_IBAN provided.',
    MISSING_CREDITOR_ALL_AI_DETAILS = 'There must be a value in ALL_AI_AcctName and either ALL_AI_AcctNumb or ALL_AI_IBAN provided.',
    MISSING_CREDITOR_FEE_BC_DETAILS = 'There must be a value in FEE_BC_AcctName and either FEE_BC_AcctNumb or FEE_BC_IBAN provided.',
    MISSING_CREDITOR_FEE_II_DETAILS = 'There must be a value in FEE_II_AcctName and either FEE_II_AcctNumb or FEE_II_IBAN provided.',
    MISSING_CREDITOR_FEE_AI_DETAILS = 'There must be a value in FEE_AI_AcctName and either FEE_AI_AcctNumb or FEE_AI_IBAN provided.',
    MISSING_CREDITOR_PRN_BC_DETAILS = 'There must be a value in PRN_BC_AcctName and either PRN_BC_AcctNumb or PRN_BC_IBAN provided.',
    MISSING_CREDITOR_PRN_II_DETAILS = 'There must be a value in PRN_II_AcctName and either PRN_II_AcctNumb or PRN_II_IBAN provided.',
    MISSING_CREDITOR_PRN_AI_DETAILS = 'There must be a value in PRN_AI_AcctName and either PRN_AI_AcctNumb or PRN_AI_IBAN provided.',
    INVALID_CREDITOR_GROUP = 'Creditor group must be "BC", "II", or "AI".',
}

export const mandatoryPaymentFields = [
    'Asset_Name',
    'Issuer_Name',
    'Portfolio_Name',
    'Portfolio_ID',
    'Issuer_ID',
    'Facility_ID',
    'Position_Name',
    'Portfolio_Institution_ID',
    'TranCash_Amount',
    'TranCash_Name',
    'TranCash_Source_ID',
    'TranCash_Source_Type',
    'TranCash_ReceiveDate',
    'TranCash_ID',
    'TranCash_ActionCode_ID',
    'TranCash_CurrencyType_Identifier',
    'TranCash_CurrencyType_ID',
    'TranCash_ActionCodeBase_ID',
    'Position_ID',
];

export const BankIssuerMap = {
    hsbc: [
        '10', // Panasas, Inc.
        '11', // Xicato, Inc.
        '14', // Opera Solutions USA, LLC
        '15', // Hardinge Inc.
        '17', // Farren Group LLC
        '18', // Speedwell Holdings, LLC
        '26', // LDC Stone, Inc.
        '27', // 49 Middle Drive, LLC
        '28', // New Angus, LLC
        '32', // Atlantic Carbon Group, Inc.
        '34', // WOHC RE SLB - White Oak Healthcare Real Estate Sale-Leaseback, LLC
        '35', // Navarro Capital Partners, LLC
        '38', // L'Arte Della Cucina, LLC
        '39', // Acqua Liana Capital Partners, LLC
        '40', // Alabama Shipyard, LLC
        '43', // WO - C2FO SPV, LLC
        '45', // Orion Drilling, LLC
        '50', // New Day Aluminum LLC
        '51', // ElectrifAi, LLC
        '54', // Rig Runner Holdings
        '57', // Scan Oak, LLC
        '60', // WOCA - White Oak Commercial Aviation LP
        '62', // Tilden Marcellus, LLC
        '65', // CSDVRS, LLC aka ZVRS
        '66', // Mid-State Machine and Fabricating Corporation
        '68', // Ashton-Potter (USA) Ltd.
        '69', // WOCA II - White Oak Commercial Aviation II, LP
        '70', // The PendaForm Company
        '75', // WO ABL Domestic - White Oak ABL Domestic SPV LLC Partnership - PCS
        '79', // WO Europe - White Oak Europe, Ltd.
        '80', // White Oak Europe Acquisition Ltd.
        '81', // WOHCF - White Oak Healthcare Finance, LLC
        '84', // WOBC FNCC - White Oak Business Capital FNCC
        '85', // WOCF - White Oak Commercial Finance Holdco, LLC
        '86', // WOTF I - White Oak Trade Finance I
        '87', // WOHC RE - White Oak Healthcare Real Estate Debt, LP
        '88', // WOTF II - White Oak Trade Finance II
        '89', // Acqua Liana - Alabama Shipyard, LLC
        '90', // Acqua Liana - Navarro Capital Partners, LLC
        '92', // WO ABL Hunt - White Oak ABL, LLC - Hunt
        '96', // WOHC Direct - White Oak Healthcare Finance Direct, LLC
        '98', // Titania Drilling, LLC
        '99', // WO Pace Funding, LLC
        '100', // CCGEN Holdings WO, LLC (Cascade Genesis)
        '107', // Endurance Environmental Solutions, LLC
        '109', // International Keg Rental, LLC
        '136', // PCS Wireless - WO ABL Domestic SPV, LLC Participation
        '142', // Revolt Media and TV LLC
        '147', // Venture Metals, LLC
        '148', // Hardinge ABL
        '155', // Hunt & Sons, Inc. - CDPQ WO ABL LLC Participation
        '160', // Direct Source Seafood, LLC
        '167', // Reflex Performance Resources Inc.
        '170', // EnVogue International LLC
        '171', // 40 Center Street JC LLC
        '182', // Tilden Marcellus DIP
        '189', // WOHC ALT - White Oak H-Alternative, LLC
        '213', // WO ABL Lender Finance Special Series
        '217', // 49 Middle Drive, LLC TL
        '223', // BUZZFEED MEDIA ENTERPRISES, INC.
        '224', // S.T.L. Marshlands LLC
        '231', // Madaluxe Group, LLC
    ],
    vectra: [
        '94', // Acqua Liana - Alliance NewCo
        '105', // FinFit Funding I, LLC
        '108', // Bain Capital, LP
        '110', // California Pizza Kitchen, Inc.
        '117', // GTIS Partners
        '123', // Zignal Labs, Inc
        '127', // In-Shape Solutions, LLC
        '134', // Protect America, Inc.
        '137', // Suffolk Regional Off-Track Betting Corporation
        '161', // Warburg Pincus LLC
        '194', // Ashford Hospitality Holdings LLC
        '195', // DesertXpress Enterprises, LLC
        '196', // LPF JV Corporation
        '201', // Imperium3 New York, Inc.
        '208', // Connected International Inc.
    ],
    terminated: [
        '97', // TERMINATED  - Trico Group, LLC
        '91', // TERMINATED - Alliance NewCo
        '132', // TERMINATED - Automotores Gildemeister SPA
        '37', // TERMINATED - Banker's Toolbox, Inc.
        '56', // TERMINATED - Community Surgical Supply of Toms River, Inc.
        '63', // TERMINATED - Danimer Scientific Holdings, LLC
        '64', // TERMINATED - Eagle Energy, Inc.
        '36', // TERMINATED - Epic Companies, LLC - DIP
        '44', // TERMINATED - Epic Estate
        '52', // TERMINATED - Flatland Holdings, LLC
        '71', // TERMINATED - Global Keg Rental LLC
        '33', // TERMINATED - GR Energy Services Operating GP, LLC
        '7', // TERMINATED - JDC Drilling Company, LLC
        '8', // TERMINATED - K.R. Drenth Trucking, Inc.
        '53', // TERMINATED - Machine Zone, Inc.
        '46', // TERMINATED - MCG Energy Solutions, LLC
        '130', // TERMINATED - My Alarm Center, LLC
        '67', // TERMINATED - Nelson Pipeline Constructors LLC
        '55', // TERMINATED - OAS Restructuring (BVI) Limited Depositary
        '59', // TERMINATED - Retail Services WIS Corporation
        '25', // TERMINATED - Revolt Media and TV, LLC
        '74', // TERMINATED - Solvium Equipmemt Finance GmbH
        '103', // TERMINATED - Thunderhead Midco (One) Limited
        '104', // TERMINATED - TigerRisk Holdings, LLC
        '58', // TERMINATED - Trax Data Refinery, Inc.
        '19', // TERMINATED - UPG Company, LLC
        '42', // TERMINATED - White Oak ABL LLC (DE) Partnership - E&A
        '41', // Bainbridge Uinta, LLC
        '47', // Rockdale Marcellus, LLC
    ],
};

export const PaymentPrefixMap = {
    interest: 'INT',
    paydown: 'PRN',
    fee: 'FEE',
};

export const PaymentTypeMap: { [key: string]: string[] } = {
    interest: [
        '961005', // Interest
        '961021', // Interest   Accrual Adjustment
        '961025', // Interest   Reallocation
        '961003', // LIBOR Interest
        '961004', // Prime Interest
        '961019', // Purchased   Accrued Interest
        '961532', // Received   Accrued Interest
        '961531', // Sold Accrued   Interest
    ],
    paydown: [
        '961015', // Paydown
        '900119', // Paydown   Discount
        '900120', // Paydown   Premium
        '961014', // Prime Paydown
        '900250', // Principal In
        '900251', // Principal Out
        '961023', // Refundable   Paydown
        '961550', // Trade Paydown   (Buy)
        '961551', // Trade Paydown   (Sell)
        '961027', // Unscheduled   LIBOR Paydown
        '961030', // Unscheduled   Paydown
        '961028', // Unscheduled   Prime Paydown
        '961011', // Principal (Change Allocation (Sell))
        '961013', // LIBOR Paydown
    ],
    fee: [
        '961008', // Accrued LOC   Fee
        '961006', // Accrued   Unfunded Fee
        '961034', // Amendment Fee
        '961020', // Assignment Fee
        '961037', // Breakage Fee
        '961007', // Commitment Fee
        '900012', // Consent Fee
        '961514', // Exchange Fee
        '961035', // Facility   Extension Fee
        '961022', // Fee Accrual   Adjustment
        '961036', // Funding Fee
        '961208', // Interest -   Accrued Fee
        '962003', // Management Fee
        '961039', // Markit   Subtrade Fee
        '961010', // Misc Fee
        '961204', // Purchased   Accrued Fee
        '961206', // Received   Accrued Fee
        '884490', // Restructure   Fee
        '961513', // SEC Fee
        '961205', // Sold Accrued   Fee
        '961033', // Ticking Fee
        '961207', // Transfer   Purchase Accrued Fee
        '900011', // Unfunded Fee
        '961040', // Upfront Fee
        '961002', // Upfront Fee   (Buy)
        '961012', // Upfront Fee   (Sale)
        '961038', // Waiver Fee
    ],
    exclude: [
        '961536', // Accrual PIK   Capitalized Interest
        '961535', // Capitalize   Fees
        '961537', // Capitalize   Into Another Position
        '961533', // Capitalized   Interest
        '961211', // Interest -   Accrued PIK
        '961534', // PIK
        '961201', // Purchased   Accrued PIK
        '961203', // Received   Accrued PIK
        '961202', // Sold Accrued   PIK
        '961031', // Trade   Capitalized Interest (Buy)
        '961032', // Trade   Capitalized Interest (Sell)
        '961209', // Transfer   Purchase Accrued PIK
    ],
};
