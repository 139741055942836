import { Component, OnInit } from '@angular/core';
import { PortfoliosService } from './shared/portfolios.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
    TableHeaderTemplate,
    PortfolioModalConfigData,
    PortfolioTableListItem,
} from '../portfolios/shared/portfolios.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { RowAction, TableAction } from '../../components/table-template/table-template.component';
import { PortfolioModalComponent } from './portfolio-modal/portfolio-modal.component';

@Component({
    selector: 'app-portfolios',
    templateUrl: './portfolios.component.html',
    styleUrls: ['./portfolios.component.scss'],
})
export class PortfoliosComponent implements OnInit {
    public portfolios: PortfolioTableListItem[] = [];
    public portfoliosActions: RowAction[] = [
        {
            backgroundColor: 'primary',
            action: this.editRow.bind(this),
            icon: 'edit',
        },
        {
            backgroundColor: 'warn',
            action: this.deleteRow.bind(this),
            icon: 'delete',
        },
    ];
    public portfolioModal: MatDialogRef<PortfolioModalComponent>;
    public portfolioTitle = 'Portfolios';
    public tableActions: TableAction[] = [
        {
            backgroundColor: 'primary',
            action: this.addPortfolio.bind(this),
            text: 'New',
        },
    ];
    TableHeaders: TableHeaderTemplate[] = [
        {
            label: 'ID',
            value: 'wso_portfolio_id',
        },
        {
            label: 'Name',
            value: 'name',
        },
        {
            label: 'Description',
            value: 'description',
        },
    ];

    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '300px',
        minHeight: '250px',
    };

    public result: boolean = false;
    public resultMessage: string = '';

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private portfoliosService: PortfoliosService,
    ) {}

    ngOnInit(): void {
        this.getPortfolios();
    }

    getPortfolios() {
        this.portfolios = [];
        this.result = false;
        this.resultMessage = '';

        this.portfoliosService.getPortfolios().subscribe({
            next: (res) => {
                res.forEach((portfolio) => {
                    let tempPortfolio: PortfolioTableListItem = {
                        id: portfolio.id,
                        name: portfolio.name,
                        wso_portfolio_id: portfolio.wso_portfolio_id,
                        description: portfolio.description,
                    };
                    this.portfolios.push(tempPortfolio);
                });
            },
            error: (error: HttpErrorResponse) => {
                console.log('error', error);
            },
        });
    }

    public deleteRow(data: PortfolioTableListItem) {
        const deleteRowConfig: MatDialogConfig<PortfolioModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: `Delete Portfolio: ${data.wso_portfolio_id}`,
                selectedPortfolio: data,
                action: 'delete',
            },
        };
        this.openModal(deleteRowConfig);
    }

    public addPortfolio() {
        const addRowConfig: MatDialogConfig<PortfolioModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Create Portfolio',
                selectedPortfolio: null,
                action: 'create',
            },
        };
        this.openModal(addRowConfig);
    }

    public editRow(data: PortfolioTableListItem) {
        const editRowConfig: MatDialogConfig<PortfolioModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: `Edit Portfolio: ${data.wso_portfolio_id}`,
                selectedPortfolio: data,
                action: 'edit',
            },
        };
        this.openModal(editRowConfig);
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.portfolioModal = this.matDialog.open(PortfolioModalComponent, modalConfig);
        this.portfolioModal.afterClosed().subscribe((res: { updateData: boolean; areChangesSaved: boolean }) => {
            if (!res.areChangesSaved) {
                this.snackBar.open('Last changes were not saved and have been discarded', undefined, {
                    duration: 1500,
                });
            }
            if (res.updateData) {
                setTimeout(() => {
                    this.snackBar.open('Fetching portfolio list after most recent edits', undefined, {
                        duration: 1500,
                    });
                }, 1500);
                this.getPortfolios();
            }
        });
    }
}
