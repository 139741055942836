import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { handleMissingServerError } from '../../../core/utils/http-response-handlers';
import { BankAccount, AddAccont, EditAccount } from './accounts.types';

@Injectable({
    providedIn: 'root',
})
export class AccountsService {
    private serverName: string = 'Services Bank Payments API';

    // we may want to get bank account in this service

    public addAccount(account: AddAccont): Observable<BankAccount> {
        const requestUrl = [this.configurationService.envConfig.getValue().bankPaymentsHost, 'accounts'].join('/');
        return this.http
            .post(requestUrl, account, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public deleteAccount(accountID: number): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bankPaymentsHost,
            'accounts',
            accountID,
        ].join('/');
        return this.http.delete(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public editAccount(account: EditAccount): Observable<BankAccount> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bankPaymentsHost,
            'accounts',
            account.id,
        ].join('/');
        return this.http
            .put(requestUrl, account, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}
}
