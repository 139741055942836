<header class="admin__header">
    <mat-toolbar color="primary">
        <h1 class="admin-icon-header">
            <img [src]="'assets/favicon.ico'" alt="SRSA logo" style="height: 24px; width: 24px" />
            <a class="home_link" href="/"> Admin </a>
        </h1>
        <span id="export-span"
              class="material-symbols-outlined app-menu"
              (click)="toggleSidebar()"
              *ngIf="(isAuthenticated$ | async)"
        >menu</span>
        <button
            mat-flat-button
            color="primary"
            (click)="logout()"
            class="button"
            *ngIf="(isDoneLoading$ | async) && (isAuthenticated$ | async)"
        >
            logout
        </button>
        <button
            mat-flat-button
            color="primary"
            (click)="login()"
            class="button"
            *ngIf="(isDoneLoading$ | async) && !(isAuthenticated$ | async)"
        >
            login
        </button>
    </mat-toolbar>
</header>
