import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, mergeMap, skip, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IssuersService } from '../shared/issuers.service';
import { AddIssuer, EditIssuer, Issuer, IssuerAccountByIssuerID, IssuerModalConfigData } from '../shared/issuers.types';
import { HttpErrorResponse } from '@angular/common/http';
import { IssuerAccountsService } from '../../shared/issuer-accounts.service';
import { BankService } from '../../shared/bank.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BankAccount } from '../../accounts/shared/accounts.types';

type AccountListOption = BankAccount & { isDisabled: boolean };

@Component({
    selector: 'app-issuer-modal',
    templateUrl: './issuer-modal.component.html',
    styleUrls: ['./issuer-modal.component.scss'],
})
export class IssuerModalComponent implements OnInit, OnDestroy {
    public accountAssociationForm = new FormGroup({
        account: new FormControl<BankAccount | undefined>(undefined),
    });
    public areChangesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public bankAccountControl: FormControl = new FormControl('');
    public bankAccountFilterControl: FormControl = new FormControl('');
    public filteredBankAccounts: BehaviorSubject<AccountListOption[]> = new BehaviorSubject<AccountListOption[]>([]);
    public issuerForm = new FormGroup({
        wsoIssuerID: new FormControl<number | undefined>(undefined, [Validators.required, Validators.pattern(/^\d*$/)]),
        issuerName: new FormControl<string>('', [Validators.required, Validators.maxLength(40)]),
        issuerDescription: new FormControl<string>('', Validators.maxLength(200)),
    });
    public issuerAccounts: BehaviorSubject<IssuerAccountByIssuerID[]> = new BehaviorSubject([]);
    public modalTooltip: string = 'Changes have not been saved!';

    private availableBankAccounts: BankAccount[];
    private accountList: AccountListOption[] = [];
    private componentSubscriptions: Subscription[] = [];
    private lastSavedIssuerForm;
    private updateData: boolean = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<IssuerModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public issuerModalData: IssuerModalConfigData,
        private snackBar: MatSnackBar,
        private issuerService: IssuersService,
        private issuerAccountsService: IssuerAccountsService,
        private bankService: BankService,
    ) {
        this.accountAssociationForm.disable();
        if (this.issuerModalData.action === 'edit' && this.issuerModalData.selectedIssuer) {
            this.issuerForm.controls.wsoIssuerID.setValue(this.issuerModalData.selectedIssuer.wso_issuer_id);
            this.issuerForm.controls.wsoIssuerID.disable();
            this.issuerForm.controls.issuerName.setValue(this.issuerModalData.selectedIssuer.name);
            this.issuerForm.controls.issuerDescription.setValue(this.issuerModalData.selectedIssuer.description);
            this.lastSavedIssuerForm = this.issuerForm.value;
            this.accountAssociationForm.enable();

            this.bankService
                .getBankAccountsByBankID(this.issuerModalData.selectedBank)
                .pipe(
                    mergeMap((bankAccounts) => {
                        this.availableBankAccounts = bankAccounts;
                        return this.issuerAccountsService.getIssuerAccountsByIssuerID(
                            issuerModalData.selectedIssuer.id,
                        );
                    }),
                )
                .subscribe((issuerAccountsResponse) => {
                    this.issuerAccounts.next(issuerAccountsResponse);
                });
        } else {
            this.bankService.getBankAccountsByBankID(this.issuerModalData.selectedBank).subscribe({
                next: (bankAccounts) => {
                    this.availableBankAccounts = bankAccounts;
                },
                error: (err) => {
                    console.error(err);
                },
            });
        }
    }

    ngOnInit(): void {
        this.componentSubscriptions.push(
            this.issuerForm.valueChanges.subscribe((values) => {
                this.areChangesSaved.next(JSON.stringify(values) === JSON.stringify(this.lastSavedIssuerForm));
            }),
        );

        this.componentSubscriptions.push(
            this.bankAccountFilterControl.valueChanges.subscribe((value) => {
                this.filterBankAccounts(value);
            }),
        );

        this.componentSubscriptions.push(
            this.issuerAccounts.pipe(skip(1)).subscribe(() => {
                this.setAccountListOptions();
            }),
        );
    }

    ngOnDestroy() {
        for (let sub of this.componentSubscriptions) {
            sub.unsubscribe();
        }
    }

    public closeModal() {
        this.dialogRef.close({ updateData: this.updateData, areChangesSaved: this.areChangesSaved.getValue() });
    }

    public deleteAccountAssociation(account: IssuerAccountByIssuerID) {
        this.issuerAccountsService
            .deleteIssuerAccountAssociation(account.association_id)
            .pipe(
                mergeMap(() => {
                    this.snackBar.open(
                        `Association with account number ${account.number} successfully removed`,
                        undefined,
                        { duration: 3000 },
                    );
                    return this.issuerAccountsService.getIssuerAccountsByIssuerID(
                        this.issuerModalData.selectedIssuer.id,
                    );
                }),
            )
            .subscribe((res) => {
                this.issuerAccounts.next(res);
                this.updateData = true;
            });
    }

    public saveAccountAssociation() {
        this.issuerAccountsService
            .saveIssuerAccountAssociation(
                this.issuerModalData.selectedIssuer.id,
                this.accountAssociationForm.value.account.id,
            )
            .pipe(
                mergeMap(() => {
                    this.snackBar.open(
                        `Issuer successfully associated with account number ${this.accountAssociationForm.value.account.number}`,
                        undefined,
                        { duration: 3000 },
                    );
                    this.accountAssociationForm.reset();
                    return this.issuerAccountsService.getIssuerAccountsByIssuerID(
                        this.issuerModalData.selectedIssuer.id,
                    );
                }),
            )
            .subscribe((res) => {
                this.issuerAccounts.next(res);
                this.updateData = true;
            });
    }

    public saveIssuer() {
        let issuer: AddIssuer & { id?: number } = {
            bank_id: this.issuerModalData.selectedBank,
            description: this.issuerForm.value.issuerDescription,
            name: this.issuerForm.value.issuerName,
            wso_issuer_id: this.issuerForm.controls.wsoIssuerID.value,
        };
        if (this.areChangesSaved.getValue()) {
            this.snackBar.open(`There are no changes to save`, undefined, { duration: 3000 });
            return;
        }

        if (!this.issuerModalData.selectedIssuer) {
            this.issuerService.addIssuer(issuer).subscribe({
                next: (res) => {
                    this.setIssuerForm(res);
                    this.accountAssociationForm.enable();
                    this.issuerAccounts.next([]);
                    this.snackBar.open(`Issuer successfully created`, undefined, { duration: 3000 });
                    this.issuerModalData.title = `Edit Issuer: ${res.wso_issuer_id}`;
                },
                error: (error: HttpErrorResponse) => {
                    console.error(error);
                    this.snackBar.open(`${error.error.message} | ${error.error.details}`);
                },
            });
        } else {
            issuer.id = this.issuerModalData.selectedIssuer.id;
            this.issuerService.editIssuer(issuer as EditIssuer).subscribe({
                next: (res) => {
                    this.setIssuerForm(res);
                    this.snackBar.open(`Issuer successfully updated`, undefined, { duration: 3000 });
                },
                error: (error: HttpErrorResponse) => {
                    console.error(error);
                    this.snackBar.open(`${error.error.message} | ${error.message}`);
                },
            });
        }
    }

    private filterBankAccounts(filterValue: string) {
        if (!filterValue) {
            this.filteredBankAccounts.next(this.accountList.slice());
            return;
        } else {
            filterValue = filterValue.toLowerCase();
        }
        this.filteredBankAccounts.next(
            this.accountList.filter((bankAccount) => bankAccount.number.toLowerCase().indexOf(filterValue) > -1),
        );
    }

    /**
     * Iterates through all the available accounts for the issuer, marking them as abled or disabled depending on whether
     * the account is already associated to this issuer OR if the account is using a currency already associated with the issuer
     * @private
     */
    private setAccountListOptions() {
        const accountList: AccountListOption[] = [];
        const currentAssociatedAccounts: string[] = [];
        const currentAssociatedCurrencies: string[] = [];
        for (let issuerAccount of this.issuerAccounts.getValue()) {
            currentAssociatedAccounts.push(issuerAccount.number);
            currentAssociatedCurrencies.push(issuerAccount.currency);
        }
        for (let bankAccount of this.availableBankAccounts) {
            const accountListOption: AccountListOption = { ...bankAccount, isDisabled: false };
            if (
                currentAssociatedAccounts.includes(bankAccount.number) ||
                currentAssociatedCurrencies.includes(bankAccount.currency)
            ) {
                accountListOption.isDisabled = true;
            }
            accountList.push(accountListOption);
        }
        this.accountList = accountList;
        this.filteredBankAccounts.next(accountList);
    }

    private setIssuerForm(issuer: Issuer) {
        this.issuerForm.controls.wsoIssuerID.setValue(issuer.wso_issuer_id);
        this.issuerForm.controls.wsoIssuerID.disable();
        this.issuerForm.controls.issuerName.setValue(issuer.name);
        this.issuerForm.controls.issuerDescription.setValue(issuer.description);
        const { id, name, description, wso_issuer_id } = issuer;
        this.issuerModalData.selectedIssuer = {
            id,
            name,
            description,
            wso_issuer_id,
        };
        this.lastSavedIssuerForm = this.issuerForm.value;
        this.areChangesSaved.next(true);
        this.updateData = true;
    }

    public deleteIssuer() {
        this.issuerService.deleteIssuer(this.issuerModalData.selectedIssuer.id).subscribe({
            next: () => {
                this.areChangesSaved.next(true);
                this.updateData = true;
                this.snackBar.open(`Issuer deleted successfully`, undefined, { duration: 1500 });
                setTimeout(() => {
                    this.closeModal();
                }, 1500);
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
                this.snackBar.open(`${error.error.message} | ${error.message}`);
            },
        });
    }
}
