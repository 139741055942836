import { Component, OnDestroy, OnInit } from '@angular/core';
import { IssuerAndAccountsListItem, IssuerModalConfigData } from './shared/issuers.types';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IssuersService } from './shared/issuers.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TableHeaderTemplate } from './shared/issuers.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RowAction, TableAction } from '../../components/table-template/table-template.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IssuerModalComponent } from './issuer-modal/issuer-modal.component';
import { BankService } from '../shared/bank.service';
import { Bank } from './shared/banks.types';

@Component({
    selector: 'app-issuers',
    templateUrl: './issuers.component.html',
    styleUrls: ['./issuers.component.scss'],
})
export class IssuersComponent implements OnInit, OnDestroy {
    public banks: Bank[] = [];
    public bankControl = new FormControl<Bank | undefined>(undefined);
    public bankFilterCtrl = new FormControl<string>('');
    public filteredBanks: BehaviorSubject<Bank[]> = new BehaviorSubject<Bank[]>([]);
    public issuersAccounts: IssuerAndAccountsListItem[] = [];
    public issuerActions: RowAction[] = [
        {
            backgroundColor: 'primary',
            action: this.editRow.bind(this),
            icon: 'edit',
        },
        {
            backgroundColor: 'warn',
            action: this.deleteRow.bind(this),
            icon: 'delete',
        },
    ];
    public issuerModal: MatDialogRef<IssuerModalComponent>;
    public issuerTitle = 'Issuers';
    public showTable: boolean;
    public tableActions: TableAction[] = [
        {
            backgroundColor: 'primary',
            action: this.addIssuer.bind(this),
            text: 'New',
        },
    ];
    public TableHeaders: TableHeaderTemplate[] = [
        {
            label: 'ID',
            value: 'wso_issuer_id',
        },
        {
            label: 'Name',
            value: 'name',
        },
        {
            label: 'Description',
            value: 'description',
        },
        {
            label: 'Account No./Currency',
            value: 'accountsAndCurrencies',
        },
    ];

    private componentSubscriptions: Subscription[] = [];
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '300px',
        minHeight: '250px',
    };

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private issuersService: IssuersService,
        private bankService: BankService,
    ) {
        this.getBanks();
    }

    ngOnInit(): void {
        this.showTable = false;

        this.componentSubscriptions.push(
            this.bankFilterCtrl.valueChanges.subscribe((filterValue: string) => {
                this.filterBanks(filterValue);
            }),
        );

        this.componentSubscriptions.push(
            this.bankControl.valueChanges.subscribe((bank: Bank) => {
                this.showTable = false;
                this.updateIssuerList(bank.id);
            }),
        );
    }

    ngOnDestroy() {
        for (let subscription of this.componentSubscriptions) {
            subscription.unsubscribe();
        }
    }

    private updateIssuerList(bankID: number) {
        this.issuersAccounts = [];
        this.issuersService.getIssuersAccountsByBank(bankID).subscribe({
            next: (res) => {
                res.forEach((issuerAccount) => {
                    let accounts_currencies = '';
                    if (issuerAccount.accounts && issuerAccount.currencies) {
                        let accounts = issuerAccount.accounts.split(',');
                        let currencies = issuerAccount.currencies.split(',');
                        if (accounts.length > 1 && currencies.length > 1) {
                            let accounts_and_currencies = accounts.map((e, i) => e + '/' + currencies[i]);
                            accounts_currencies = accounts_and_currencies.join(', ');
                        } else {
                            accounts_currencies = issuerAccount.accounts + '/' + issuerAccount.currencies;
                        }
                    }
                    let tempIssuerAccount: IssuerAndAccountsListItem = {
                        id: issuerAccount.id,
                        name: issuerAccount.name,
                        wso_issuer_id: issuerAccount.wso_issuer_id,
                        description: issuerAccount.description,
                        accountsAndCurrencies: accounts_currencies,
                    };
                    this.issuersAccounts.push(tempIssuerAccount);
                    this.showTable = true;
                });
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
            },
        });
    }

    public deleteRow(data: IssuerAndAccountsListItem) {
        const deleteRowConfig: MatDialogConfig<IssuerModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: `Delete Issuer: ${data.wso_issuer_id}`,
                selectedBank: this.bankControl.value.id,
                action: 'delete',
                selectedIssuer: data,
            },
        };
        this.openModal(deleteRowConfig);
    }

    public addIssuer() {
        const addRowConfig: MatDialogConfig<IssuerModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Create Issuer',
                selectedBank: this.bankControl.value.id,
                action: 'create',
            },
        };
        this.openModal(addRowConfig);
    }

    public editRow(data: IssuerAndAccountsListItem) {
        const editRowConfig: MatDialogConfig<IssuerModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: `Edit Issuer: ${data.wso_issuer_id}`,
                selectedIssuer: data,
                selectedBank: this.bankControl.value.id,
                action: 'edit',
            },
        };
        this.openModal(editRowConfig);
    }

    private filterBanks(filterValue: string) {
        if (!this.banks) {
            return;
        }

        if (!filterValue) {
            this.filteredBanks.next(this.banks.slice());
            return;
        } else {
            filterValue = filterValue.toLowerCase();
        }
        // filter the banks
        this.filteredBanks.next(this.banks.filter((bank) => bank.display_name.toLowerCase().indexOf(filterValue) > -1));
    }

    private getBanks() {
        this.bankService.getBanks().subscribe({
            next: (res) => {
                res.forEach((bank) => {
                    this.banks.push(bank);
                });
                this.filteredBanks.next(this.banks);
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
            },
        });
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.issuerModal = this.matDialog.open(IssuerModalComponent, modalConfig);
        this.issuerModal.afterClosed().subscribe((res: { updateData: boolean; areChangesSaved: boolean }) => {
            let showingChangesSavedSnackBar: boolean = false;
            if (!res.areChangesSaved) {
                this.snackBar.open('Last changes were not saved and have been discarded', undefined, {
                    duration: 1500,
                });
                showingChangesSavedSnackBar = true;
            }
            if (res.updateData) {
                if (showingChangesSavedSnackBar) {
                    setTimeout(() => {
                        this.snackBar.open('Fetching issuer list after most recent edits', undefined, {
                            duration: 3000,
                        });
                        this.updateIssuerList(this.bankControl.value.id);
                    }, 1500);
                } else {
                    this.snackBar.open('Fetching issuer list after most recent edits', undefined, {
                        duration: 3000,
                    });
                    this.updateIssuerList(this.bankControl.value.id);
                }
            }
        });
    }
}
