import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { requiredFileType } from '../../core/components/file-upload/requiredFileType';
import { LoanAgencyService } from './shared/loan-agency.service';
import { UtilsService } from '../../core/services/utils.service';
import { CsvParseFailures, IParsedCsvObject } from '../../core/utils.types';
import { bankTransferTypes } from '../shared/shared-types';
import {
    IErrorRecord,
    IGroupedPaymentRecords,
    ILoanAgencyRecord,
    IWarningRecord,
    LoanAgencyPaymentErrors,
    UnexpectedError,
    ExpectedError,
    MismatchedColumnHeaderError,
    ICitizenRecord,
    BankField,
    IPaymentRecords,
} from './shared/loan-agency.types';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IPain00100103Json } from './shared/pain_001_001_03.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { IssuersService } from '../issuers/shared/issuers.service';
import { Issuer } from '../issuers/shared/issuers.types';
import { AccessFeatures, AccessTypes, AuthService } from '../../core/services/auth.service';
import { BankService } from '../shared/bank.service';
import { FlagsService } from '../../core/services/flags.service';

@Component({
    selector: 'app-loan-agency',
    templateUrl: './loan-agency.component.html',
    styleUrls: ['./loan-agency.component.scss'],
})
export class LoanAgencyComponent implements OnDestroy, OnInit {
    @ViewChild('warningDialog') warningDialog: TemplateRef<ElementRef>;
    @ViewChild('errorDialog') errorDialog: TemplateRef<ElementRef>;
    @ViewChild('citizensModal') citizensModal: TemplateRef<ElementRef>;
    @ViewChild('formDirective') formDirective: FormGroupDirective;

    public weekendDatesFilter = (d: Date): boolean => {
        if (d) {
            const day = d.getDay();
            /* Prevent Saturday and Sunday for select. */
            return day !== 0 && day !== 6;
        }
        return false;
    };
    public currentDate: Date;
    public cutOffDate: Date;
    public banks: BankField[] = [];
    public progressStatus: number = 0;
    public hasReviewedWarnings: boolean = true;
    public paymentRecords: IPaymentRecords;
    public painFormattedPaymentFile: IPain00100103Json;
    public allowBankTransferTypeSelection = 0;

    public loanAgencyPaymentForm = new UntypedFormGroup({
        targetBank: new UntypedFormControl(null, Validators.required),
        transferType: new UntypedFormControl({ value: 'Direct' }),
        targetDate: new UntypedFormControl(null, Validators.required),
        paymentCsv: new UntypedFormControl(null, [Validators.required, requiredFileType('csv')]),
    });
    public submitting: boolean = false;
    public transferTypelist = bankTransferTypes;

    private warningDialogRef: MatDialogRef<ElementRef>;
    private errorDialogRef: MatDialogRef<ElementRef>;
    private citizensModalRef: MatDialogRef<ElementRef>;
    private formChangesSubscription: Subscription;

    private bankIssuers: string[] = [];

    public initializeForm() {
        this.hasReviewedWarnings = true;
        const bankValue = this.banks.indexOf(this.loanAgencyPaymentForm.get('targetBank').value);
        this.formDirective.resetForm({
            targetBank: this.banks[bankValue],
        });
    }

    /**
     * Reads the incoming File Blob into string and parses the csv into objects from the string
     * NOTE: We are using FileReader here as the newer Blob.text() method is unsupported on some browsers (Safari)
     * @param csvBlob: Blob the file the user has uploaded
     */
    public getCsvDataAsObject<T>(csvBlob: Blob): Promise<IParsedCsvObject<T>> {
        console.log('prepping file');
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(this.utilsService.parseCsvToObject<T>(reader.result as string));
            reader.onerror = reject;
            reader.readAsText(csvBlob); // or a different mode
        });
    }

    public async loanAgencyPaymentFormValueChanges(data: { targetBank: string; targetDate: Date; paymentCsv: File }) {
        if (data.paymentCsv && data.targetBank && data.targetBank === 'hsbc' && data.targetDate) {
            // If file change or service change occurs, we want to start from fresh slate
            // this.initializeForm();

            const invalidRecords: IErrorRecord[] = [];
            const warningRecords: IWarningRecord[] = [];
            const citizenErrors: ICitizenRecord[] = [];
            const duplicateRecords: { line: number; matchedLine: number }[] = [];
            let validRecords: IGroupedPaymentRecords = {};

            // Parse CSV into an object containing valid and invalid records
            const parsedCsv = await this.getCsvDataAsObject<ILoanAgencyRecord>(data.paymentCsv);

            // separate out invalidRecords that failed during csv to object parsing.
            if (parsedCsv.invalidRows && parsedCsv.invalidRows.length > 0) {
                parsedCsv.invalidRows.forEach((record) => {
                    if (record.record === 'nullHeader') {
                        invalidRecords.push({
                            lineNumber: [record.lineNumber],
                            errors: [CsvParseFailures.MALFORMED_HEADER],
                            record: null,
                        });
                    } else if (record.record === null) {
                        invalidRecords.push({
                            lineNumber: [record.lineNumber],
                            errors: [CsvParseFailures.MALFORMED_ROW],
                            record: null,
                        });
                    } else {
                        invalidRecords.push({
                            lineNumber: [record.lineNumber],
                            errors: [CsvParseFailures.INVALID_RECORD_ROW],
                            record: record.record,
                        });
                    }
                });
            }

            // separate out duplicateRecords that were found during csv to object parsing.
            if (parsedCsv.duplicateRows && parsedCsv.duplicateRows.length > 0) {
                duplicateRecords.push(...parsedCsv.duplicateRows);
            }

            // TODO: look at refactoring how we handle warnings so we can easier determine which warnings invalidate a record and which
            // are still valid records
            /**
             * excludedWarnings will be excluded from the validRecords array and will not be included in the final aggregation
             */
            const excludedWarnings: string[] = [
                `Column: Issuer_ID\nWarning: ${LoanAgencyPaymentErrors.RECORD_SKIPPED_ISSUER_ID}`,
                `Column: TranCash_ReceiveDate\nWarning: ${LoanAgencyPaymentErrors.RECEIVED_DATE_OUT_OF_RANGE}`,
                `Column: TranCash_ReceiveDate\nWarning: ${LoanAgencyPaymentErrors.SELECTED_DATE_MISMATCH}`,
            ];
            const masterRecordPortfolioId = '40'; // change data in this place
            parsedCsv.validRows.forEach((row) => {
                try {
                    const paymentType = this.loanAgencyService.getPaymentType(row.record.TranCash_ActionCode_ID);
                    if (paymentType !== 'exclude') {
                        if (row.record.Portfolio_ID === masterRecordPortfolioId) {
                            return;
                        }
                        const validationResult = this.loanAgencyService.validateFileRow(
                            this.bankIssuers,
                            data.targetDate,
                            row.record,
                        );
                        switch (validationResult.status) {
                            case 'error': {
                                invalidRecords.push({
                                    lineNumber: [row.lineNumber],
                                    errors: validationResult.errors,
                                    record: row.record,
                                });
                                break;
                            }
                            case 'warning': {
                                // Here, we are assuming that the user can only accept all records with warnings as valid
                                // or be forced to cancel the import, thus all warning records are valid records
                                warningRecords.push({
                                    lineNumber: [row.lineNumber],
                                    warnings: validationResult.warnings,
                                    record: row.record,
                                });
                                const isExcluded: boolean =
                                    [
                                        ...this.utilsService
                                            .getArrayIntersection(validationResult.warnings, excludedWarnings)
                                            .values(),
                                    ].length > 0;
                                if (!isExcluded) {
                                    validRecords = this.loanAgencyService.groupValidRecordsByPaymentType(
                                        row,
                                        validRecords,
                                    );
                                }
                                break;
                            }
                            case 'success': {
                                validRecords = this.loanAgencyService.groupValidRecordsByPaymentType(row, validRecords);
                                break;
                            }
                            default: {
                                console.error(
                                    new Error(
                                        `Record didn't return with expected status. Unexpected status: ${validationResult.status}`,
                                    ),
                                );
                            }
                        }
                    }
                } catch (e) {
                    console.error(e);
                    invalidRecords.push({
                        lineNumber: [row.lineNumber],
                        errors: ['tranCashActionCodeId not found in PaymentTypesMap!'],
                        record: row.record,
                    });
                }
            });

            this.paymentRecords = { ...this.loanAgencyService.flattenRecordsForTransformation(validRecords) };
            this.paymentRecords = { ...this.loanAgencyService.cleanFlattenedPayment(this.paymentRecords) };
            invalidRecords.push(...this.loanAgencyService.validateFlattenedPayment(this.paymentRecords));

            if (invalidRecords.length > 0) {
                const dialogData: {
                    invalidRecords: IErrorRecord[];
                } = { invalidRecords: [] };
                dialogData.invalidRecords.push(...invalidRecords);
                this.openErrorDialog(dialogData);
            }

            if (warningRecords.length > 0 || duplicateRecords.length > 0) {
                this.hasReviewedWarnings = false;
                const dialogData: {
                    warningRecords?: IWarningRecord[];
                    duplicateRecords?: { line: number; matchedLine: number }[];
                    validRecordsLeftToProcess: boolean;
                } = {
                    validRecordsLeftToProcess: Object.keys(validRecords).length > 0,
                };

                if (warningRecords.length > 0) {
                    dialogData.warningRecords = warningRecords;
                }
                if (duplicateRecords.length > 0) {
                    dialogData.duplicateRecords = duplicateRecords;
                }
                this.openImportWarningsDialog(dialogData);
            }

            this.painFormattedPaymentFile = {
                ...this.loanAgencyService.mapToXmlFields(this.paymentRecords, data.targetDate),
            };
        } else if (data.paymentCsv && data.targetBank && (data.targetBank === 'cfg' || data.targetBank === 'vectra')) {
            // Here we set an arbitrary date. Citizens doesn't need a date submission, but the date is a mandatory field on the
            // FE form, so we set the date here to make the form valid
            if (!data.targetDate) {
                this.loanAgencyPaymentForm.get('targetDate').setValue(new Date());
            }
        }
    }

    public onClickCancelUpload() {
        console.log('ding! CANCEL');
        if (this.warningDialogRef !== undefined) {
            this.warningDialogRef.close();
        }
        if (this.errorDialogRef !== undefined) {
            this.errorDialogRef.close();
        }
        if (this.citizensModalRef !== undefined) {
            this.citizensModalRef.close();
        }
        this.initializeForm();
    }

    public onClickAcceptWarnings() {
        console.log('ding! Proceed');
        this.hasReviewedWarnings = true;
        this.warningDialogRef.close();
    }

    public openImportWarningsDialog(dialogData: {
        warningRecords?: IWarningRecord[];
        duplicateRecords?: any[];
        validRecordsLeftToProcess: boolean;
    }) {
        this.warningDialogRef = this.dialog.open(this.warningDialog, {
            minWidth: '300px',
            minHeight: '300px',
            maxWidth: '100%',
            maxHeight: '100%',
            data: dialogData,
            panelClass: 'warnings-Dialog',
        });
    }

    public openCitizensModal(dialogData: { invalidRecords: ExpectedError }) {
        this.citizensModalRef = this.dialog.open(this.citizensModal, {
            minWidth: '300px',
            minHeight: '300px',
            maxWidth: '100%',
            maxHeight: '100%',
            data: dialogData,
            panelClass: 'citizens-Modal',
        });
    }

    public openErrorDialog(dialogData: { invalidRecords: IErrorRecord[] }) {
        this.errorDialogRef = this.dialog.open(this.errorDialog, {
            minWidth: '300px',
            minHeight: '300px',
            maxWidth: '100%',
            maxHeight: '100%',
            data: dialogData,
            panelClass: 'error-Dialog',
        });
    }

    public isExpectedError(obj: any): obj is ExpectedError {
        return (
            typeof obj.Status === 'string' &&
            typeof obj.Message === 'string' &&
            typeof obj.Errors.isNotString &&
            obj.Errors.length > 0
        );
    }

    public isMismatchedColumnError(obj: any): obj is MismatchedColumnHeaderError {
        return (
            typeof obj.message === 'string' && typeof obj.invalidRowNumber === 'number' && typeof obj.type === 'string'
        );
    }

    public submit() {
        // Current logic is HSBC goes direct to Boomi service
        // Citizens and Vectra go to the BankPayments api
        this.submitting = true;
        if (this.loanAgencyPaymentForm.valid && this.hasReviewedWarnings) {
            switch (this.loanAgencyPaymentForm.get('targetBank').value) {
                case 'hsbc':
                    this.sendWireToBoomi();
                    break;
                case 'cfg':
                    let transferType =
                        this.allowBankTransferTypeSelection && this.loanAgencyPaymentForm.get('transferType')
                            ? this.loanAgencyPaymentForm.get('transferType')?.value
                            : 'Manual';
                    if (transferType.toLowerCase() == 'direct') {
                        this.sendPaymentTransferDirect();
                    } else {
                        this.sendPaymentTransferManual();
                    }
                    break;
                case 'vectra':
                    this.sendPaymentTransferManual();
                    break;
            }
            this.initializeForm();
        }
    }

    public sendPaymentTransferManual() {
        const formData: FormData = new FormData();
        formData.set('file', this.loanAgencyPaymentForm.get('paymentCsv').value);
        formData.set('source', 'wso');
        formData.set('selectedBank', this.loanAgencyPaymentForm.get('targetBank').value.toLowerCase());
        this.loanAgencyService
            .postToBankPaymentsApiManual(formData)
            .subscribe({
                next: (res: HttpResponse<Blob>) => {
                    const fileName = res.headers.get('content-disposition').split(';')[1].split('=')[1];
                    const blob = new Blob([res.body], { type: 'application/zip' });
                    const a: HTMLAnchorElement = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);
                    a.id = 'temporary-anchor';
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                },
                error: (error: HttpErrorResponse) => {
                    // We are here because there was an error
                    console.log('error', error);
                    if (typeof error === 'string') {
                        this.openSnackBarWithDismiss(error);
                    } else {
                        if (error.error) {
                            error.error.text().then((errorMsg) => {
                                let validationError: ExpectedError = JSON.parse(errorMsg);
                                if (this.isExpectedError(validationError)) {
                                    const invalidRecords: ExpectedError = validationError;
                                    let errorData: {
                                        invalidRecords: ExpectedError;
                                    } = { invalidRecords: invalidRecords };
                                    errorData.invalidRecords = invalidRecords;
                                    this.openCitizensModal(errorData);
                                } else {
                                    let customMessage = 'Unexpected Error. Please contact Internal Ops team.';
                                    try {
                                        // First, lets parse as generic UnexpectedError
                                        let unexpectedError: UnexpectedError = JSON.parse(errorMsg);
                                        customMessage = unexpectedError.message;
                                        console.log('Parsed as UnexpectedError : ', unexpectedError);

                                        // try parsing message property as MismatchedColumnHeaderError
                                        let mismatchedColumnErrors: MismatchedColumnHeaderError[] = JSON.parse(
                                            unexpectedError.message,
                                        );
                                        console.log(
                                            'Parsed as MismatchedColumnHeaderError[] : ',
                                            mismatchedColumnErrors,
                                        );

                                        let rowNumbers: number[] = [];
                                        mismatchedColumnErrors.forEach((element) => {
                                            rowNumbers.push(element.invalidRowNumber);
                                        });
                                        customMessage =
                                            'Invalid file. Rows were malformed. \nMalformed rows : ' +
                                            rowNumbers.join(', ');
                                    } catch (error) {
                                        // Could not parse as MismatchedColumnHeaderError[], so leave as general UnexpectedError.
                                        console.log('ERROR:', error.message);
                                    }
                                    this.openSnackBarWithDismiss(customMessage);
                                }
                            });
                        }
                    }
                },
            })
            .add(() => {
                this.submitting = false;
                this.initializeForm();
            });
    }

    public sendPaymentTransferDirect() {
        const file = this.loanAgencyPaymentForm.get('paymentCsv').value;
        const selectedBank = this.loanAgencyPaymentForm.get('targetBank').value.toLowerCase();

        this.loanAgencyService
            .postToBankPaymentsApiDirect(file, selectedBank)
            .subscribe({
                next: (res: HttpResponse<Blob>) => {},
                error: (err) => {
                    const errorMessage = err.error?.message ?? err.message;
                    this.openSnackBarWithDismiss(`${errorMessage}`);
                    console.log('Error', err);
                },
            })
            .add(() => {
                this.submitting = false;
                this.initializeForm();
            });
    }

    public sendWireToBoomi() {
        this.loanAgencyService
            .postToApiBoomiService(this.painFormattedPaymentFile)
            .subscribe({
                next: (res) => {
                    this.openSnackBar(`${res.status}: ${res.message}`);
                    console.log('HTTP response', res);
                },
                error: (err) => {
                    const errorStatus = err.error?.status ?? err.status;
                    const errorMessage = err.error?.message ?? err.message;
                    this.openSnackBar(`${errorStatus}: ${errorMessage}`);
                    console.log('HTTP Error', err);
                },
            })
            .add(() => {
                this.submitting = false;
                this.initializeForm();
            });
    }
    public openSnackBarWithDismiss(message: string) {
        this.snackBar.open(message, 'dismiss', {
            panelClass: 'custom-snack-bar',
        });
    }

    public openSnackBar(message: string) {
        this.snackBar.open(message, null, { duration: 5000 });
    }

    public hasPaymentsRights(): boolean {
        const accessLevel = this.authService.checkAccess(AccessFeatures.LANCE_PAYMENTS);
        return accessLevel === AccessTypes.FULL;
    }

    getBanks() {
        this.bankService.getBanks().subscribe({
            next: (res) => {
                res.forEach((bank) => {
                    let tempBank: BankField = {
                        id: bank.name,
                        name: bank.display_name,
                    };
                    this.banks.push(tempBank);
                });
            },
            error: (error: HttpErrorResponse) => {
                console.log('error', error);
            },
        });
    }

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private loanAgencyService: LoanAgencyService,
        private utilsService: UtilsService,
        private issuersService: IssuersService,
        private bankService: BankService,
        private authService: AuthService,
        private flagsService: FlagsService,
    ) {}

    ngOnInit(): void {
        this.currentDate = this.loanAgencyService.setCurrentDate();
        this.cutOffDate = this.loanAgencyService.getFutureDateGivenBusinessDays(this.currentDate, 5);
        this.formChangesSubscription = this.loanAgencyPaymentForm.valueChanges.subscribe(
            this.loanAgencyPaymentFormValueChanges.bind(this),
        );
        this.getBanks();
        this.allowBankTransferTypeSelection = this.flagsService.fetchFlagStatus('allowBankTransferTypeSelection');
    }

    ngOnDestroy() {
        this.formChangesSubscription.unsubscribe();
    }

    onBankChange() {
        switch (this.loanAgencyPaymentForm.get('targetBank').value) {
            case 'hsbc':
                this.issuersService.getIssuersByBank(this.loanAgencyPaymentForm.get('targetBank').value).subscribe({
                    next: (res: Issuer[]) => {
                        res.forEach((issuer) => {
                            this.bankIssuers.push(issuer.wso_issuer_id.toString());
                        });
                    },
                    error: (error: HttpErrorResponse) => {
                        console.log('error', error);
                    },
                });
                break;
            case 'cfg':
                this.loanAgencyPaymentForm.get('transferType').setValue('Direct');
                break;
        }
    }
}
