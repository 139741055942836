<p-toast></p-toast>

<div class="tableContainer">
    <p-table
        #dt
        [value]="TableData"
        [rows]="10"
        [globalFilterFields]="['name', 'description', 'wso_id']"
        [(selection)]="selectedRows"
        dataKey="id"
        [responsive]='true'
        [autoLayout]="true"
        scrollHeight="40vh"
        [scrollable]="true"
        scrollWidth="100%"
    >
        <ng-template pTemplate="caption">
            <div class="tableTop">
                <div class="tableTop">
                    <h5 class="title">{{ TableTitle }}</h5>
                </div>
                <div>
                    <div class="new-import-search">
                        <div class="p-1">
                            <ng-container>
                                <button
                                    mat-raised-button
                                    *ngFor="let tableAction of TableActions"
                                    [color]="tableAction.backgroundColor"
                                    (click)="tableAction.action()"
                                >
                                    {{ tableAction.text }}
                                </button>
                            </ng-container>
                        </div>
                        <div class="p-1">
                            <span class="p-input-icon-left search">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..."
                                    class="search"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let header of TableHeaders" pSortableColumn="{{ header.value }}">
                    {{ header.label }} <p-sortIcon field="{{ header.value }}"></p-sortIcon>
                </th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td>
                    <p-tableCheckbox [value]="data"></p-tableCheckbox>
                </td>
                <td *ngFor="let header of TableHeaders">{{ data[header.value] }}</td>
                <td class="row-actions">
                    <ng-container>
                        <button
                            *ngFor="let tableAction of RowActions"
                            mat-mini-fab
                            [color]="tableAction.backgroundColor"
                            (click)="tableAction.action(data)"
                        >
                            <mat-icon>{{ tableAction.icon }}</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="tableBottom">
                <div>There are {{ TableData ? TableData.length : 0 }} {{ TableTitle }}.</div>
                <div>
                    <button
                        *appAccessCheckSection="TableTitle"
                        pButton
                        pRipple
                        icon="pi pi-trash"
                        class="p-button-danger"
                        (click)="deleteSelectedRows()"
                        [disabled]="!selectedRows || !selectedRows.length"
                    ></button>
                </div>
            </div>
        </ng-template>
    </p-table>
</div>
