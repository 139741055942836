<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div id="users-container" class="users-container">
    <ng-container
        *ngTemplateOutlet="selectedUser ? singleUser : multipleUsers"
    ></ng-container>
</div>

<ng-template #singleUser>
    <!-- <div id="slideoutSingleUser" class="slideout" [class.show-create-user]="showCreateUser">
        <app-user-slider-panel
            data-testid="create-user-slideout"
            [isOpen]="showCreateUser"
            [dealRecords]="singleUserAllDealRecords"
            (closeSliderEvent)="closeSliderPanel()"
            [userToEdit]="userToEdit"
            [editMode]="editMode"
            [singleUserEditMode]="singleUserEditMode"
            [singleUserEditDealRecords]="singleUserEditDealRecords"
            (singleUserEditEvent)="handleSingleUserEditEvent($event)">
        </app-user-slider-panel>
    </div> -->
    <div class="wrapper-div">
        <div class="page-title-actions">
            <div class="title-actions">
                 <span class="title">{{selectedUser?.firstName}} {{selectedUser?.lastName}}</span>
                <div class="actions">
                    <div class="button-div" data-testid="user-actions" [matMenuTriggerFor]="userMenu">
                        <div class="button-elem">
                            <span class="button-label">User Actions</span>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                    <mat-menu #userMenu="matMenu" xPosition="before" yPosition="below">
                        <div *ngIf="canEditUser(selectedUser.status)"
                             mat-menu-item
                             class="menu-option"
                             data-testid="single-user-edit-user-option"
                             (click)="handleEditUserClick()">Edit User</div>
                        <div *ngIf="canViewUserActivity(selectedUser.status)"
                             mat-menu-item
                             class="menu-option"
                             (click)="handleUserActivityClick()">User Activity</div>
                        <div *ngIf="canResetMFA(selectedUser.status)"
                             mat-menu-item
                             class="menu-option"
                             (click)="handleResetMFAClick()">Reset MFA Method</div>
                        <div *ngIf="canResendInvitation(selectedUser.status)"
                             mat-menu-item
                             class="menu-option"
                             (click)="handleResendInvitation()">Resend Invitation</div>
                        <div *ngIf="canSendPwReset(selectedUser.status)"
                             mat-menu-item
                             class="menu-option"
                             (click)="handleSendPwResetClick()">Send Password Reset</div>
                        <div *ngIf="showRevokeAccess && dealRecords?.length > 0"
                             mat-menu-item
                             class="menu-option menu-option-red"
                             (click)="handleRevokeAllAccessClick()">Revoke All Access</div>
                        <div *ngIf="canBlock(selectedUser.status)"
                             mat-menu-item
                             class="menu-option menu-option-red"
                             (click)="handleBlockClick()">Block Authentication</div>
                        <div *ngIf="canUnblock(selectedUser.status)"
                             mat-menu-item
                             class="menu-option menu-option-red"
                             (click)="handleUnblockClick()">Unblock Authentication</div>
                        <div *ngIf="canDeactivate(selectedUser.status)"
                             mat-menu-item
                             class="menu-option menu-option-red"
                             (click)="handleDeactivateClick()">Deactivate User</div>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="user-frame-div">
            <div class="user-details-div">
                    <div class="info-div">
                        <div class="info-label">
                            {{ 'User Info' | uppercase }}
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Email</span>
                            <span class="info-data-value">{{selectedUser?.email}}</span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Netsuite ID</span>
<!--                            <a class="info-data-value" [href]="getUserNsUrl(selectedUser?.nsId)" (click)="openInNewTab(selectedUser?.nsId)">{{selectedUser?.nsId > 0 ? selectedUser?.nsId : ''}}</a>-->
                            <span class="as-link"
                                  (click)="openInNewTab(getUserNsUrl(selectedUser?.nsId))"
                                  [matTooltip]="getUserNsUrl(selectedUser?.nsId)"
                                  matTooltipPosition="below"
                                  matTooltipShowDelay="300"
                                  matTooltipHideDelay="200"
                            >{{selectedUser?.nsId > 0 ? selectedUser?.nsId : ''}}</span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Products</span>
                            <span class="product-data-value" *ngFor="let product of selectedUser?.products">{{product.name}}</span>
                        </div>
                        <div class="auth0-info-data">
                            <span class="info-data-label" >Auth0 Status</span>
                            <span class="auth0-chip"
                                [class.auth0-invited]="selectedUser?.status?.toLowerCase() === 'invited'"
                                [class.auth0-active]="selectedUser?.status?.toLowerCase() === 'active'"
                                [class.auth0-blocked]="selectedUser?.status?.toLowerCase() === 'blocked'"
                                [class.auth0-deactivated]="selectedUser?.status?.toLowerCase() === 'deactivated'"
                            >
                            <span class="auth0-label">{{selectedUser?.status}}</span>
                        </span>
                        </div>
                    </div>
            </div>
            <div class="deals-section-div">
                <mat-tab-group mat-align-tabs="start" animationDuration="0ms" mat-stretch-tabs="false"
                               [disableRipple]="true" [(selectedIndex)]="selectedIndex" fitInkBarToContent="true" class="tool-bar">
                    <mat-tab label="Deal Dashboard">
                        <ng-container
                            *ngTemplateOutlet="dealDashboardDealTableTemplate"
                        ></ng-container>
                    </mat-tab>
                    <mat-tab label="Shareholder Portal">
                        <ng-container
                            *ngTemplateOutlet="shareholderPortalUserTableTemplate"
                        ></ng-container>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</ng-template>

<ng-template matTabContent #dealDashboardDealTableTemplate>
    <div id="search-bar-deals" class="search-bar-deals">
        <app-intadm-search
            data-testid="app-deals-search"
            placeholder="Filter Deals"
            (newSearchEvent)="performSearchDeals($event)"></app-intadm-search>
    </div>
    <div class="table-container">
        <app-deal-table
            class="deals-table"
            data-testid="app-deals-table"
            [displayedColumns]="dealDisplayedColumns"
            [dealRecords]="dealRecords"
            [searchText]="searchValueDeals"
            [page]="pageDeals"
            [pageSize]="pageSizeDeals"
            [totalRecords]="totalRecordsDeals"
            [showCreateUser]="showCreateUser"
            (pageEvent)="handlePageEventDeals($event)"
            (sortEvent)="handleSortChangeDeals($event)"
            (selected)="handleDealSelected($event)"
            (dealActivityNav)="handleDealActivityNavigation($event)"
            (dealDashboardNav)="handleDealDashboardNavigation($event)"
            (revokeAccessEvent)="handleRevokeAccessEvent($event)"
            [showRevokeAccess]="showRevokeAccess"
        ></app-deal-table>
    </div>
</ng-template>

<ng-template matTabContent #shareholderPortalUserTableTemplate>
    <div class="users-frame-div">
        <div class="users-search-bar">
            <app-intadm-search
                data-testid="app-accounts-search-users"
                placeholder="Filter by name, permission, or shareholder..."
                (newSearchEvent)="performSearchDealsUsers($event)">
            </app-intadm-search>
        </div>
    </div>
    <div class="table-container">
        <app-shareholder-deal-user-table
            class="shareholder-deal-user-table"
            [displayedColumns]="shareholderDealUserDisplayedColumns"
            [records]="shareholderDealUserRecords"
            [searchText]="searchValueDealsUsers"
            [page]="page"
            [pageSize]="pageSize"
            [totalRecords]="totalRecordsDealsUsers"
            [fetching]="fetchingDealsUsers"
            [showRevokeAccess]="showRevokeAccess"
            (userClickedEvent)="handleUserClickedEventDealsUsers($event)"
            (shareholderClickedEvent)="handleShareholderClickedEventDealsUsers($event)"
            (pageEvent)="handlePageEventDealsUsers($event)"
            (sortEvent)="handleSortChangeDealsUsers($event)"
            (revokeAccessEvent)="handleRevokeAccessEvent($event)"
        ></app-shareholder-deal-user-table>
    </div>
</ng-template>

<ng-template #multipleUsers>
    <!-- <div id="slideoutMultipleUsers" class="slideout" [class.show-create-user]="showCreateUser">
        <app-user-slider-panel
            data-testid="create-user-slideout"
            [isOpen]="showCreateUser"
            [dealRecords]="dealRecords"
            (closeSliderEvent)="closeSliderPanel()"
            [userToEdit]="userToEdit"
            [editMode]="editMode"
            [allUsersEditMode]="allUsersEditMode"
            [allUsersEditDealRecords]="allUsersEditDealRecords"
            [addExistingUserRecords]="addExistingUserRecords"
            [addExistingUserMode]="addExistingUserMode"
            [addExistingUserDealRecords]="addExistingUserDealRecords"
            (addExistingUserSelectedUserIdEvent)="handleAddExistingUserSelectedUserId($event)"
            (addExistingUserButtonClickEvent)="handleAddExistingUserButtonClick()"
            (newUserButtonClickEvent)="handleNewUserButtonClick()"
            >
        </app-user-slider-panel>
    </div> -->
    <ng-container *ngTemplateOutlet="showCreateUser ? createUserForm : null">
    </ng-container>
    <div *ngIf="!showCreateUser" class="multiple-users-container">
        <div id="header-bar" class="header-bar">
            <div class="header-text-div">
                <span id="header-text" class="header-text">Users</span>
            </div>
        </div>
        <div class="main">
            <div class="frame-div">
                <div id="search-bar" class="search-bar">
                    <app-intadm-search
                        data-testid="app-accounts-search"
                        placeholder="Filter by name, email..."
                        (newSearchEvent)="performSearch($event)">
                    </app-intadm-search>
                </div>
                <div *ngIf="showAddUserMenu()" class="button-div">
                    <div data-testid="add-user" [matMenuTriggerFor]="addUserMenu" class="button-elem">
                        <span class="button-label">Add User</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </div>
                </div>
                <mat-menu #addUserMenu="matMenu" xPosition="before" yPosition="below">
                    <div *appAccessCheckSection="permissionCreateUser" data-testid="create-user-option" mat-menu-item class="menu-option" (click)="createUserClick()">Create User</div>
                    <div *appAccessCheckSection="permissionAddUser" data-testid="add-existing-user-option" mat-menu-item class="menu-option" (click)="addExistingUser()">Add Existing User</div>
                </mat-menu>
            </div>
            <div class="table-container">
                <app-user-table
                    class="users-table"
                    data-testid="app-user-table"
                    [displayedColumns]="userDisplayedColumns"
                    [showCreateUser]="showCreateUser"
                    (userToEditEvent)="handleUserToEditEvent($event)"
                    (editModeEvent)="handleEditModeEvent($event)"
                    [userRecords]="userRecords"
                    [searchText]="searchValue"
                    [page]="page"
                    [pageSize]="pageSize"
                    [totalRecords]="totalRecords"
                    [fetching]="fetching"
                    (pageEvent)="handlePageEvent($event)"
                    (sortEvent)="handleSortChange($event)"
                    (selected)="handleUserSelected($event)"
                    (userActionNav)="handleUserAction($event)"
                ></app-user-table>
            </div>
        </div>
    </div>


    <!-- <div id="header-bar" class="header-bar">
        <div class="header-text-div">
            <span id="header-text" class="header-text">Users</span>
        </div>
    </div>
    <div class="main">
        <div class="frame-div">
            <div id="search-bar" class="search-bar">
                <app-intadm-search
                    data-testid="app-accounts-search"
                    placeholder="Filter by name, email..."
                    (newSearchEvent)="performSearch($event)">
                </app-intadm-search>
            </div>
            <div *ngIf="showAddUserMenu()" class="button-div">
                <div data-testid="add-user" [matMenuTriggerFor]="addUserMenu" class="button-elem">
                    <span class="button-label">Add User</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
            </div>
            <mat-menu #addUserMenu="matMenu" xPosition="before" yPosition="below">
                <div *appAccessCheckSection="permissionCreateUser" data-testid="create-user-option" mat-menu-item class="menu-option" (click)="createUser()">Create User</div>
                <div *appAccessCheckSection="permissionAddUser" data-testid="add-existing-user-option" mat-menu-item class="menu-option" (click)="addExistingUser()">Add Existing User</div>
            </mat-menu>
        </div>
        <div class="table-container">
            <app-user-table
                class="users-table"
                data-testid="app-user-table"
                [displayedColumns]="userDisplayedColumns"
                [showCreateUser]="showCreateUser"
                (userToEditEvent)="handleUserToEditEvent($event)"
                (editModeEvent)="handleEditModeEvent($event)"
                [userRecords]="userRecords"
                [searchText]="searchValue"
                [page]="page"
                [pageSize]="pageSize"
                [totalRecords]="totalRecords"
                [fetching]="fetching"
                (pageEvent)="handlePageEvent($event)"
                (sortEvent)="handleSortChange($event)"
                (selected)="handleUserSelected($event)"
                (userActionNav)="handleUserAction($event)"
            ></app-user-table>
        </div>
    </div> -->
</ng-template>

<ng-template #createUserForm>
    <div id="header-bar" class="header-bar">
        <div class="header-text-div">
            <span id="header-text" class="header-text">Create User</span>
        </div>
    </div>
    <div class="create-user-main">
        <form class="user-form" id="user-form" [formGroup]="userForm" (ngSubmit)="onSubmit(userForm)">
            <div class="grouping">
                <div class="info-section">                
                    <span class="form-primary-text">Personal Information</span>
                    <span class="form-secondary-text">Contact information that syncs across applications</span>
                </div>
                <div class="form-fields-group">
                    <div class="form-fields">
                        <div class="personal-info-fields-container">
                            <div class="text-field">
                                <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                    <mat-label>First Name</mat-label>
                                    <input data-testid="firstName" formControlName="firstName" matInput>
                                    <mat-error *ngIf="userForm.get('firstName').invalid">
                                      First name required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="text-field">
                                <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic"> 
                                    <mat-label>Last Name</mat-label>
                                    <input data-testid="lastName" formControlName="lastName" matInput>
                                    <mat-error *ngIf="userForm.get('lastName').invalid">
                                      Last name required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="text-field">
                                <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                    <mat-label>Email Address</mat-label>
                                    <input data-testid="email" formControlName="email" matInput>
                                    <mat-error *ngIf="userForm.get('email').invalid && !userForm.controls.email.hasError('emailAlreadyExists')">
                                      Please enter a valid email address
                                    </mat-error>
                                    <mat-error *ngIf="userForm.controls.email.hasError('emailAlreadyExists')">
                                      User already exists within the system
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="text-field">
                                <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                    <mat-label>Phone Number</mat-label>
                                    <input data-testid="phoneNumber" formControlName="phoneNumber" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grouping">
                <div class="info-section">                
                    <span class="form-primary-text">Deal Dashboard Access</span>
                    <span class="form-secondary-text">Manage user roles on Deals in Deal Dashboard</span>
                </div>
                <div class="form-fields-group">
                    <div class="form-fields">
                        <div class="add-access-button-container">
                            <div class="add-access-button" (click)="addDealAccessClick()">
                                <span class="form-fields-button-label">Add Deal Access</span>
                            </div>
                        </div>
                        <div formArrayName="dealAccess" class="access-fields-container">
                                <ng-container *ngFor="let dealAccessForm of dealAccess.controls; let i = index">
                                    <div [formGroup]="dealAccessForm" class="access-form" attr.data-testid="deal-access-row-{{i}}">
                                        <mat-form-field appearance="outline" floatLabel="always" class="access-form-field">
                                            <mat-label>Deal Access</mat-label>
                                            <mat-chip-grid #chipGrid aria-label="Deal dashboard deal access selection">
                                                <mat-chip-row *ngFor="let deal of dealDashboardDealsRow[i]; trackBy:identifyDeal" (removed)="removeDealChip(deal, i)">
                                                  {{deal}}
                                                  <button matChipRemove [attr.aria-label]="'remove ' + deal">
                                                    <mat-icon>cancel</mat-icon>
                                                  </button>
                                                </mat-chip-row>
                                            </mat-chip-grid>
                                            <input #dealDashboardDealInput matInput formControlName="deal" [matAutocomplete]="autoDeal"
                                                   [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                   (matChipInputTokenEnd)="addDealChip($event)">
                                            <mat-autocomplete #autoDeal="matAutocomplete" (optionSelected)="dealSelected($event, i)">
                                                <mat-option *ngFor="let deal of filteredDealDashboardDeals | async; trackBy:identifyDeal" [value]="deal">{{deal}}</mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="dealAccessForm.get('deal').invalid">
                                                Deal required
                                            </mat-error>    
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" floatLabel="always" class="access-form-field">
                                            <mat-label>Role</mat-label>
                                            <mat-select data-testid="role" (selectionChange)="onRoleChange($event, 'dealAccess', i)" formControlName="role">
                                                <mat-select-trigger>
                                                  {{dealDashboardCurrentRoles[i]?.name}}
                                                </mat-select-trigger>
                                                  <mat-option *ngFor='let role of roleRecords;'
                                                              [value]="role.id"><div class="role-options">{{role.name}}</div>
                                                  <div class="role-description">{{role.description}}</div>
                                                  </mat-option>
                                              </mat-select>
                                              <mat-error *ngIf="dealAccessForm.get('role').invalid">
                                                Role required
                                              </mat-error>                                        
                                        </mat-form-field>
                                        <div class="x-dismiss-div">
                                            <span id="x-dismiss"
                                                  class="material-symbols-outlined x-dismiss"
                                                  attr.data-testid="x-dismiss-deal-access-row-{{i}}"
                                                  (click)="deleteDealAccessRow(i)"
                                                  >close
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grouping">
                <div class="info-section">                
                    <span class="form-primary-text">Shareholder Portal Access</span>
                    <span class="form-secondary-text">Manage Shareholder user roles on Deals in Shareholder Portal</span>
                </div>
                <div class="form-fields-group">
                    <div class="form-fields">
                        <div class="add-access-button-container">
                            <div class="add-access-button" (click)="addShareholderAccessClick()">
                                <span class="form-fields-button-label">Add Shareholder Access</span>
                            </div>    
                        </div>
                        <div formArrayName="shareholderAccess" class="access-fields-container">
                            <ng-container *ngFor="let shareholderAccessForm of shareholderAccess.controls; let i = index">
                                <div [formGroup]="shareholderAccessForm" class="access-form" attr.data-testid="shareholder-access-row-{{i}}">
                                    <mat-form-field appearance="outline" floatLabel="always" class="access-form-field">
                                        <mat-label>Shareholder</mat-label>
                                        <input #shareholderInput matInput formControlName="shareholder" [matAutocomplete]="shareholderAuto">
                                        <mat-autocomplete #shareholderAuto="matAutocomplete" (optionSelected)="shareholderSelected($event)">
                                            <mat-option *ngFor="let shareholder of filteredShareholderRecords | async; trackBy:identifyShareholder" [value]="shareholder">{{shareholder}}</mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="shareholderAccessForm.get('shareholder').invalid">
                                            Shareholder required
                                        </mat-error>    
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" floatLabel="always" class="access-form-field">
                                        <mat-label>Deal</mat-label>
                                        <input #shareholderDealInput matInput formControlName="deal" [matAutocomplete]="autoShareholderDeal">
                                        <mat-autocomplete #autoShareholderDeal="matAutocomplete" (optionSelected)="shareholderDealSelected($event)">
                                            <mat-option *ngFor="let deal of filteredShareholderPortalDeals | async; trackBy:identifyDeal" [value]="deal">{{deal}}</mat-option>
                                        </mat-autocomplete>   
                                        <mat-error *ngIf="shareholderAccessForm.get('deal').invalid">
                                            Deal required
                                        </mat-error>                                     
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" floatLabel="always" class="access-form-field">
                                        <mat-label>Role</mat-label>
                                        <mat-select data-testid="role" (selectionChange)="onRoleChange($event, 'shareholderAccess', i)" formControlName="role">
                                            <mat-select-trigger>
                                                {{shareholderPortalCurrentRoles[i]?.name}}
                                            </mat-select-trigger>
                                                <mat-option *ngFor='let role of shareholderRoleRecords;'
                                                            [value]="role.id"><div class="role-options">{{role.name}}</div>
                                                </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="shareholderAccessForm.get('role').invalid">
                                            Role required
                                        </mat-error>                                        
                                    </mat-form-field>
                                    <div class="x-dismiss-div">
                                        <span id="x-dismiss"
                                              class="material-symbols-outlined x-dismiss"
                                              attr.data-testid="x-dismiss-shareholder-access-row-{{i}}"
                                              (click)="deleteShareholderAccessRow(i)"
                                              >close
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>                
                </div>
            </div>
            <div class="user-form-actions">
                <div *ngIf="userForm.invalid && userForm.touched" class="user-form-alert-div">
                    <mat-icon aria-hidden="false" aria-label="error icon">error_outline</mat-icon>
                    <div class="user-form-alert-text">
                        Please complete all required fields
                    </div>
                </div>
                <div class="user-form-actions-buttons">
                    <div class="cancel-button" (click)="cancelClicked(userForm)">
                        <span class="actions-button-label">Cancel</span>
                    </div>
                    <div class="create-save-button" data-testid="user-form-save-button" (click)="onSubmit(userForm, 'save')">
                        <span class="actions-button-label">Save</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
