<div id="portfolios-container">
    <div class="tablesContainer">
        <div class="tableContainer">
            <app-table-template
                [TableTitle]="portfolioTitle"
                [TableData]="portfolios"
                [TableHeaders]="TableHeaders"
                [TableActions]="tableActions"
                [RowActions]="portfoliosActions"
            ></app-table-template>
        </div>
    </div>
</div>
