export interface navigationLinks {
    label: string;
    route: string;
    flag: string;
}

export const currencies = [
    {
        value: 'USD',
        label: 'USD - US Dollar',
    },
    {
        value: 'EUR',
        label: 'EUR - Euro',
    },
    {
        value: 'GBP',
        label: 'GBP - British Pound',
    },
    {
        value: 'CAD',
        label: 'CAD - Canadian Dollar',
    },
    {
        value: 'CHF',
        label: 'CHF - Swiss Franc',
    },
    {
        value: 'AUD',
        label: 'AUD - Australian Dollar',
    },
    {
        value: 'JPY',
        label: 'JPY - Japanese Yen',
    },
    {
        value: 'INR',
        label: 'INR - Indian Rupee',
    },
    {
        value: 'SEK',
        label: 'SEK - Swedish Krone',
    },
    {
        value: 'NOK',
        label: 'NOK - Norwegian Krone',
    },
    {
        value: 'ILS',
        label: 'ILS - Israeli Shekel',
    },
];

export const states = [
    {
        value: '',
        label: 'Unselect',
    },
    {
        value: 'AL',
        label: 'AL - Alabama',
    },
    {
        value: 'AK',
        label: 'AK - Alaska',
    },
    {
        value: 'AR',
        label: 'AR - Arkansas',
    },
    {
        value: 'AZ',
        label: 'AZ - Arizona',
    },
    {
        value: 'CA',
        label: 'CA - California',
    },
    {
        value: 'CO',
        label: 'CO - Colorado',
    },
    {
        value: 'CT',
        label: 'CT - Connecticut',
    },
    {
        value: 'DC',
        label: 'DC - District of Columbia',
    },
    {
        value: 'DE',
        label: 'DE - Delaware',
    },
    {
        value: 'FL',
        label: 'FL - Florida',
    },
    {
        value: 'GA',
        label: 'GA - Georgia',
    },
    {
        value: 'HI',
        label: 'HI - Hawaii',
    },
    {
        value: 'IA',
        label: 'IA - Iowa',
    },
    {
        value: 'ID',
        label: 'ID - Idaho',
    },
    {
        value: 'IL',
        label: 'IL - Illinois',
    },
    {
        value: 'IN',
        label: 'IN - Indiana',
    },
    {
        value: 'KS',
        label: 'KS - Kansas',
    },
    {
        value: 'KY',
        label: 'KY - Kentucky',
    },
    {
        value: 'LA',
        label: 'LA - Louisiana',
    },
    {
        value: 'MA',
        label: 'MA - Massachusetts',
    },
    {
        value: 'MD',
        label: 'MD - Maryland',
    },
    {
        value: 'ME',
        label: 'ME - Maine',
    },
    {
        value: 'MI',
        label: 'MI - Michigan',
    },
    {
        value: 'MN',
        label: 'MN - Minnesota',
    },
    {
        value: 'MO',
        label: 'MO - Missouri',
    },
    {
        value: 'MS',
        label: 'MS - Mississippi',
    },
    {
        value: 'MT',
        label: 'MT - Montana',
    },
    {
        value: 'NC',
        label: 'NC - North Carolina',
    },
    {
        value: 'ND',
        label: 'ND - North Dakota',
    },
    {
        value: 'NE',
        label: 'NE - Nebraska',
    },
    {
        value: 'NH',
        label: 'NH - New Hampshire',
    },
    {
        value: 'NJ',
        label: 'NJ - New Jersey',
    },
    {
        value: 'NM',
        label: 'NM - New Mexico',
    },
    {
        value: 'NV',
        label: 'NV - Nevada',
    },
    {
        value: 'NY',
        label: 'NY - New York',
    },
    {
        value: 'OH',
        label: 'OH - Ohio',
    },
    {
        value: 'OK',
        label: 'OK - Oklahoma',
    },
    {
        value: 'ILORS',
        label: 'OR - Oregon',
    },
    {
        value: 'PA',
        label: 'PA - Pennsylvania',
    },
    {
        value: 'PR',
        label: 'PR - Puerto Rico',
    },
    {
        value: 'RI',
        label: 'RI - Rhode Island',
    },
    {
        value: 'SC',
        label: 'SC - South Carolina',
    },
    {
        value: 'SD',
        label: 'SD - South Dakota',
    },
    {
        value: 'TN',
        label: 'TN - Tennessee',
    },
    {
        value: 'TX',
        label: 'TX - Texas',
    },
    {
        value: 'UT',
        label: 'UT - Utah',
    },
    {
        value: 'VA',
        label: 'VA - Virginia',
    },
    {
        value: 'VT',
        label: 'VT - Vermont',
    },
    {
        value: 'WA',
        label: 'WA - Washington',
    },
    {
        value: 'WI',
        label: 'WI - Wisconsin',
    },
    {
        value: 'WV',
        label: 'WV - West Virginia',
    },
    {
        value: 'WY',
        label: 'WY - Wyoming',
    },
];

export const bankTransferTypes = [
    {
        value: 'Manual',
        label: 'Manual Upload',
    },
    {
        value: 'Direct',
        label: 'Direct Upload',
    },
];
