import { ConfigurationService } from './services/configuration.service';
import { IConfiguration } from './configuration.model';
import { AuthService } from './services/auth.service';

const CONFIG_JSON_PATH: string = '/assets/config/config.json';

export function initConfigAndAuthServer(
    configService: ConfigurationService,
    authService: AuthService,
): () => Promise<void> {
    return () => {
        return new Promise(async (resolve, reject) => {
            const rawConfig = await fetch(window.location.origin + CONFIG_JSON_PATH + `?t=${new Date().getTime()}`);
            const configJSON: IConfiguration = await rawConfig.json();
            configService.config = configJSON;
            if (
                configService.config.oidcConfig &&
                configService.config.oidcConfig.issuer &&
                configService.config.oidcConfig.issuer !== '' &&
                configService.config.oidcConfig.clientId &&
                configService.config.oidcConfig.clientId !== ''
            ) {
                await authService.establishAuthorizationServer(configService.config);
                authService.initializeSession();
                resolve();
            } else {
                reject(
                    // TODO: make this error more helpful for devs, what information is missing, for example
                    new Error('missing necessary OIDC information from config, unable to contact identity provider'),
                );
            }
        });
    };
}
