import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { navigationLinks } from './shared/shared-types';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-lance',
    templateUrl: './lance.component.html',
    styleUrls: ['./lance.component.scss'],
})
export class LanceComponent implements OnDestroy {
    public activeLink = '';

    private unsubscribe: Subscription;

    public links: navigationLinks[] = [
        {
            label: 'LOAN AGENCY PAYMENT',
            route: 'loan-agency-payments',
            flag: 'lance',
        },
        {
            label: 'ISSUERS',
            route: 'issuers',
            flag: 'issuers',
        },
        {
            label: 'PORTFOLIOS',
            route: 'portfolios',
            flag: 'portfolios',
        },
        {
            label: 'ACCOUNTS',
            route: 'accounts',
            flag: 'accounts',
        },
        {
            label: 'LENDER MANAGEMENT',
            route: 'lender-management',
            flag: 'lenderManagement',
        },
    ];

    constructor(private router: Router) {
        this.unsubscribe = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlSplit = event.urlAfterRedirects.split('/');
                setTimeout(() => {
                    this.activeLink = urlSplit[urlSplit.length - 1];
                });
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.unsubscribe();
    }
}
