<div id="issuer-container">
    <div class="selectBankContainer">
        <mat-form-field>
            <mat-label>Bank</mat-label>
            <mat-select [formControl]="bankControl">
                <mat-option>
                    <ngx-mat-select-search
                        placeholderLabel="Search bank..."
                        [formControl]="bankFilterCtrl"
                    ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                    {{ bank.display_name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="showTable" class="tablesContainer">
        <div class="tableContainer">
            <app-table-template
                [TableTitle]="issuerTitle"
                [TableData]="issuersAccounts"
                [TableHeaders]="TableHeaders"
                [TableActions]="tableActions"
                [RowActions]="issuerActions"
            ></app-table-template>
        </div>
    </div>
</div>
