export interface IPain00100103Json {
    GrpHdr_Authstn_Cd: GroupHeaderAuthorizationCode;
    GrpHdr_InitgPty_Id: string;
    GrpHdr_MsgId: string;
    GrpHdr_CreDtTm: string;
    PmtInf_PmtInfId: string;
    PmtInf_ReqdExctnDt: string;
    PmtInf_PmtMtd: PaymentMethodCode;
    PmtInf_PmtTpInf_SvcLvl_Cd: string;
    ChrgBr: ChargeBearerTypeCode;
    Dbtr_Nm: string;
    Dbtr_PstlAdr_StrtNm: string;
    Dbtr_PstlAdr_BldgNb: string;
    Dbtr_PstlAdr_PstCd: string;
    Dbtr_PstlAdr_TwnNm: string;
    Dbtr_PstlAdr_CtrySubDvsn: string;
    Dbtr_PstlAdr_Ctry: string;
    DbtrAcct_Id_Othr_Id: string;
    DbtrAgt_FinInstnId_BIC: string;
    DbtrAgt_FinInstnId_PstlAdr_Ctry: string;
    PmtInfo_CdtTrfTxInf_Array: ICdtTrfTxInf[];
    GrpHdr_NbOfTxs: string;
}

export interface ICdtTrfTxInf {
    // TODO: composite interface eventually?
    PmtId_InstrId: string;
    Amt_InstdAmt: string;
    PmtId_EndToEndId: string;
    // creditor fields
    Cdtr_Nm: string;
    CdtrAcct_Id_Other_Id?: string;
    CdtrAcct_Id_IBAN?: string;
    CdtrAgt_FinInstnId_PstlAdr_Ctry: string;
    Cdtr_PstlAdr_StrtNm: string;
    Cdtr_PstlAdr_BldgNb: string;
    Cdtr_PstlAdr_PstCd: string;
    Cdtr_PstlAdr_TwnNm: string;
    Cdtr_PstlAdr_CtrySubDvsn: string;
    Cdtr_PstlAdr_Ctry: string;
    // BIC and ABA fields - only one or the other
    CdtrAgt_FinInstnId_BIC?: string;
    CdtrAgt_FinInstnId_ClrSysMmbId_MmbId?: string;
    // intermediary fields - optional
    IntrmyAgt1Acct_Id_IBAN?: string;
    IntrmyAgt1_FinInstnId_BIC?: string;
    IntrmyAgt1_FinInstnId_PstlAdr_Ctry?: string;
    IntrmyAgt1_FinInstnId_ClrSysMmbId_MmbId?: string;
    IntrmyAgt1Acct_Id_Other_Id?: string;
    // Unstructured fields for FFC
    RmtInf_Unstrd0?: string;
    RmtInf_Unstrd1?: string;
    RmtInf_Unstrd2?: string;
    RmtInf_Unstrd3?: string;
}

export enum GroupHeaderAuthorizationCode {
    AUTH = 'AUTH',
    FDET = 'FDET',
    FSUM = 'FSUM',
    ILEV = 'ILEV',
}

export enum PaymentMethodCode {
    CHK = 'CHK',
    TRF = 'TRF',
    TRA = 'TRA',
}

export enum ChargeBearerTypeCode {
    DEBT = 'DEBT',
    CRED = 'CRED',
    SHAR = 'SHAR',
    SLEV = 'SLEV',
}
