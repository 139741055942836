<mat-card>
    <mat-card-title>{{ portfolioModalData.title }}</mat-card-title>
    <div class="card__container">
        <ng-container
            *ngTemplateOutlet="portfolioModalData.action === 'delete' ? deletePortfolioSection : createEditPortfolioSection"
        ></ng-container>
    </div>
</mat-card>
<ng-template #createEditPortfolioSection>
    <mat-card-content>
        <div class="portfolio-details">
            <h3>Portfolio Details</h3>
            <form class="portfolio-form" [formGroup]="portfolioForm" (ngSubmit)="savePortfolio()">
                <mat-form-field>
                    <mat-label>WSO Portfolio ID</mat-label>
                    <input matInput type="number" formControlName="wsoId" />
                    <mat-error *ngIf="portfolioForm.controls.wsoId.hasError('required')">
                        WSO Portfolio ID is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="portfolioForm.controls.wsoId.hasError('pattern')">
                        WSO Portfolio ID must be a <strong>number</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Portfolio Name</mat-label>
                    <input matInput type="text" formControlName="portfolioName" />
                    <mat-error *ngIf="portfolioForm.controls.portfolioName.hasError('required')">
                        Portfolio Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="portfolioForm.controls.portfolioName.hasError('maxlength')">
                        Portfolio Name must be less than 40 characters
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Portfolio Description</mat-label>
                    <textarea matInput type="text" formControlName="portfolioDescription"></textarea>
                    <mat-error *ngIf="portfolioForm.controls.portfolioDescription.hasError('maxlength')">
                        Portfolio Description must be less than 200 characters
                    </mat-error>
                </mat-form-field>
                <button type="submit" mat-flat-button color="primary" [disabled]="!portfolioForm.valid">Submit</button>
            </form>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button
            mat-flat-button
            [color]="(areChangesSaved | async) ? 'primary' : 'warn'"
            (click)="close()"
            [matTooltip]="modalTooltip"
            [matTooltipDisabled]="areChangesSaved | async"
            matTooltipPosition="before"
        >
            Close
        </button>
    </mat-card-actions>
</ng-template>
<ng-template #deletePortfolioSection>
    <mat-card-content>
        <div class="modal-body">
            Are you sure to delete the portfolio: <span class="portfolio-info">{{portfolioModalData.selectedPortfolio.name}}</span>
            (Portfolio Id: {{portfolioModalData.selectedPortfolio.wso_portfolio_id}})
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="modal-footer">
            <button mat-flat-button (click)="close()"  mat-flat-button color="primary">Cancel</button>
            <button mat-flat-button (click)="deleteRecord()"  mat-flat-button color="primary">Submit</button>
        </div>
    </mat-card-actions>
</ng-template>

