import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanceRoutingModule } from './lance-routing.module';
import { LanceComponent } from './lance.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CoreModule } from '../core/core.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { IssuersComponent } from './issuers/issuers.component';
import { PortfolioModalComponent } from './portfolios/portfolio-modal/portfolio-modal.component';
import { TableTemplateComponent } from '../components/table-template/table-template.component';
import { PortfoliosComponent } from './portfolios/portfolios.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { LoanAgencyComponent } from './loan-agency/loan-agency.component';
import { AccountsComponent } from './accounts/accounts.component';
import { IssuerModalComponent } from './issuers/issuer-modal/issuer-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccountModalComponent } from './accounts/account-modal/account-modal.component';
import { LenderManagementComponent } from './lender-management/lender-management.component';
import { LenderTableComponent } from './lender-management/lender-table/lender-table.component';
import { LenderModalComponent } from './lender-management/lender-modal/lender-modal.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
    declarations: [
        LanceComponent,
        LoanAgencyComponent,
        IssuersComponent,
        PortfoliosComponent,
        AccountsComponent,
        TableTemplateComponent,
        PortfolioModalComponent,
        IssuerModalComponent,
        LenderModalComponent,
        AccountModalComponent,
        LenderManagementComponent,
        LenderTableComponent,
    ],
    imports: [
        CommonModule,
        LanceRoutingModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        CoreModule,
        MatButtonModule,
        MatDividerModule,
        MatDialogModule,
        MatTableModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatInputModule,
        HttpClientModule,
        MatTabsModule,
        MatCardModule,
        MatIconModule,

        MatProgressSpinnerModule,
        NgxMatSelectSearchModule,
        TableModule,
        ToastModule,
        CalendarModule,
        SliderModule,
        MultiSelectModule,
        ContextMenuModule,
        DialogModule,
        ButtonModule,
        DropdownModule,
        ProgressBarModule,
        InputTextModule,
        FileUploadModule,
        ToolbarModule,
        RatingModule,
        RadioButtonModule,
        InputNumberModule,
        ConfirmDialogModule,
        InputTextareaModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatButtonToggleModule,
        FormsModule,
        CheckboxModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LanceModule {}
