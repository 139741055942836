<div id="loan-agency-container" class="file-action">
    <form [formGroup]="loanAgencyPaymentForm" (ngSubmit)="submit()" #formDirective="ngForm">
        <div class="payment-details-selection">
            <mat-form-field appearance="fill">
                <mat-label>Bank</mat-label>
                <mat-select formControlName="targetBank" (selectionChange)="onBankChange()" required>
                    <mat-option *ngFor="let bank of banks" [value]="bank.id">
                        {{ bank.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *appFlags="'allowBankTransferTypeSelection'">
                <mat-form-field appearance="fill" *ngIf="loanAgencyPaymentForm.get('targetBank').value === 'cfg'" >
                    <mat-label>Transfer Type</mat-label>
                    <mat-select formControlName="transferType" required >
                        <mat-option *ngFor="let transferType of transferTypelist" [value]="transferType.value">
                            {{ transferType.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <mat-form-field appearance="fill" *ngIf="loanAgencyPaymentForm.get('targetBank').value === 'hsbc'">
                <mat-label>Payment date</mat-label>
                <input
                    matInput
                    formControlName="targetDate"
                    required
                    [min]="currentDate"
                    [max]="cutOffDate"
                    [matDatepickerFilter]="weekendDatesFilter"
                    [matDatepicker]="datePicker"
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <app-file-upload formControlName="paymentCsv" [fileType]="'.csv'"></app-file-upload>
        <button [disabled]="!(loanAgencyPaymentForm.valid && hasPaymentsRights())" mat-raised-button type="submit">
            Submit
        </button>
    </form>
    <div class="progress-spinner" *ngIf="submitting">
        <mat-spinner></mat-spinner>
    </div>
</div>

<ng-template #warningDialog let-dialogData>
    <h2 mat-dialog-title>Import Warnings</h2>
    <div mat-dialog-content>
        <ng-container *ngIf="dialogData.warningRecords?.length > 0">
            <h3>Records with warnings found:</h3>
            <table mat-table [dataSource]="dialogData.warningRecords" class="mat-elevation-z8 confirm-table">
                <ng-container matColumnDef="lineNumber">
                    <th mat-header-cell *matHeaderCellDef>Line Number</th>
                    <td mat-cell *matCellDef="let element">{{ element.lineNumber }}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="warning">
                    <th mat-header-cell *matHeaderCellDef>Warning</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngFor="let warning of element.warnings">
                            <p>{{ warning }}</p>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="record">
                    <th mat-header-cell *matHeaderCellDef>Record</th>
                    <td mat-cell *matCellDef="let element">{{ element.record }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['lineNumber', 'warning']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['lineNumber', 'warning']"></tr>
            </table>
            <mat-divider class="confirm-divider"></mat-divider>
        </ng-container>
        <ng-container *ngIf="dialogData.duplicateRecords?.length > 0">
            <h3>Duplicate Records found:</h3>
            <table mat-table [dataSource]="dialogData.duplicateRecords" class="mat-elevation-z8 confirm-table">
                <ng-container matColumnDef="line">
                    <th mat-header-cell *matHeaderCellDef>Line Number</th>
                    <td mat-cell *matCellDef="let element">{{ element.line }}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="matchedLine">
                    <th mat-header-cell *matHeaderCellDef>Duplicate Of</th>
                    <td mat-cell *matCellDef="let element">{{ element.matchedLine }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['line', 'matchedLine']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['line', 'matchedLine']"></tr>
            </table>
            <mat-divider class="confirm-divider"></mat-divider>
        </ng-container>
    </div>
    <div mat-dialog-actions>
        <p>It looks like the file you are trying to import has data that may not work as intended.</p>
        <p *ngIf="dialogData.duplicateRecords?.length > 0">&nbsp;Duplicated records will be ignored.</p>
        <p>
            {{
                dialogData.validRecordsLeftToProcess === true
                    ? 'Would you like to import the data, excluding the records with warnings reported above?'
                    : 'There are no valid records with which to proceed. Please address the issues reported above and then try again.'
            }}
        </p>
        <div
            class="confirm-exceptions-action-items"
            *ngIf="dialogData.validRecordsLeftToProcess === true; else okButton"
        >
            <button mat-raised-button (click)="onClickCancelUpload()">Cancel</button>
            <button mat-raised-button (click)="onClickAcceptWarnings()">Proceed</button>
        </div>
        <ng-template #okButton><button mat-raised-button (click)="onClickCancelUpload()">Ok</button></ng-template>
        <!-- this is to keep this button label consistent with the other scenario where the user is unable to proceed -->
    </div>
</ng-template>

<ng-template #errorDialog let-dialogData>
    <h2 mat-dialog-title>Import Exceptions</h2>
    <div mat-dialog-content>
        <ng-container *ngIf="dialogData.invalidRecords.length > 0">
            <h3>Invalid Records found:</h3>
            <table mat-table [dataSource]="dialogData.invalidRecords" class="mat-elevation-z8 confirm-table">
                <ng-container matColumnDef="lineNumber">
                    <th mat-header-cell *matHeaderCellDef>Line Number</th>
                    <td mat-cell *matCellDef="let element">{{ element.lineNumber }}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="error">
                    <th mat-header-cell *matHeaderCellDef>Error</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngFor="let error of element.errors">
                            <p>{{ error }}</p>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="record">
                    <th mat-header-cell *matHeaderCellDef>Record</th>
                    <td mat-cell *matCellDef="let element">{{ element.record }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['lineNumber', 'error']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['lineNumber', 'error']"></tr>
            </table>
            <mat-divider class="confirm-divider"></mat-divider>
        </ng-container>
    </div>
    <div mat-dialog-actions>
        <p class="confirm-exceptions-action-items">
            It looks like the file you are trying to import has data that may not work as intended. Please address the
            issues reported above and then try again.
        </p>
        <div class="confirm-exceptions-action-items">
            <button mat-raised-button (click)="onClickCancelUpload()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #citizensModal let-dialogData>
    <h2 mat-dialog-title>Citizens Import Errors</h2>
    <div mat-dialog-content>
        <ng-container *ngIf="dialogData.invalidRecords">
            <h3>{{ dialogData.invalidRecords.Status }} : {{ dialogData.invalidRecords.Message }}</h3>
            <table mat-table [dataSource]="dialogData.invalidRecords.Errors" class="mat-elevation-z8 confirm-table">
                <ng-container matColumnDef="lineNumber">
                    <th mat-header-cell *matHeaderCellDef>Line Number</th>
                    <td mat-cell *matCellDef="let element">{{ element.RowNumber }}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="error">
                    <th mat-header-cell *matHeaderCellDef>Error</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngFor="let error of element.Errors">
                            <p>
                                Validation Name : {{ error.ValidationName }}, Column : {{ error.Column }}, Message :
                                {{ error.Message }}
                            </p>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="record">
                    <th mat-header-cell *matHeaderCellDef>Record</th>
                    <td mat-cell *matCellDef="let element">{{ element.record }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['lineNumber', 'error']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['lineNumber', 'error']"></tr>
            </table>
            <mat-divider class="confirm-divider"></mat-divider>
        </ng-container>
    </div>
    <div mat-dialog-actions>
        <p class="confirm-exceptions-action-items">
            It looks like the file you are trying to import has data that may not work as intended. Please address the
            issues reported above and then try again.
        </p>
        <div class="confirm-exceptions-action-items">
            <button mat-raised-button (click)="onClickCancelUpload()">Ok</button>
        </div>
    </div>
</ng-template>
