import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { AddIssuer, EditIssuer, Issuer, IssuersAccountsByBank } from './issuers.types';
import { handleMissingServerError } from '../../../core/utils/http-response-handlers';

@Injectable({
    providedIn: 'root',
})
export class IssuersService {
    private serverName: string = 'Services Bank Payments API';

    public getIssuersByBank(bank: string): Observable<Issuer[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bankPaymentsHost,
            'banks',
            bank,
            'issuers',
        ].join('/');
        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getIssuersAccountsByBank(bank: number): Observable<IssuersAccountsByBank[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bankPaymentsHost,
            'banks',
            bank,
            'issuers-accounts',
        ].join('/');
        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public addIssuer(issuer: AddIssuer): Observable<Issuer> {
        const requestUrl = [this.configurationService.envConfig.getValue().bankPaymentsHost, 'issuers'].join('/');
        return this.http
            .post(requestUrl, issuer, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public editIssuer(issuer: EditIssuer): Observable<Issuer> {
        const requestUrl = [this.configurationService.envConfig.getValue().bankPaymentsHost, 'issuers', issuer.id].join(
            '/',
        );
        return this.http.put(requestUrl, issuer).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public deleteIssuer(issuerId: number): Observable<{}> {
        const requestUrl = [this.configurationService.envConfig.getValue().bankPaymentsHost, 'issuers', issuerId].join(
            '/',
        );
        return this.http.delete(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}
}
