import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiFile, BfdFile, BfdRequest, BfdResponse, DownloadList } from './shared';
import { HttpErrorResponse } from '@angular/common/http';
import { BfdFileExplorerService } from './shared/bfd-file-explorer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-bank-file-explorer',
    templateUrl: './bank-file-explorer.component.html',
    styleUrls: ['./bank-file-explorer.component.scss'],
})
export class BankFileExplorerComponent implements OnInit, AfterViewInit {
    public bfdFiles: BfdFile[] = [];
    public bfdResponse: BfdResponse;
    public bfdRequest: BfdRequest;
    public pathParts: string[] = [];
    public pathHome = '';
    public pathRoot = '/';
    public totalRecords = 0;
    public page = 0;
    public pageSize = 100;
    public downloadLabelDefault = 'Download';
    public downloadLabel = this.downloadLabelDefault;
    public downloadSelectedLabel = 'Download Selected';
    public downloadInProgress = false;
    public fetching = true;

    private pathDisplayRoot = 'S3';
    private selectedFiles: string[] = [];

    constructor(
        private bfdFileExplorerService: BfdFileExplorerService,
        private snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.bfdRequest = {
            fullPath: '/',
            searchText: '',
            page: this.page,
            pageSize: this.pageSize,
        };
        this.pathParts = [this.pathDisplayRoot];
    }

    ngAfterViewInit() {
        this.performSearch(this.bfdRequest.searchText);
    }

    public pathClick(dirName: string) {
        if (dirName === this.pathHome || dirName === this.pathDisplayRoot) {
            this.bfdRequest.fullPath = this.pathRoot;
            this.pathParts = [this.pathDisplayRoot, this.pathHome];
        } else {
            const idx = this.pathParts.indexOf(dirName);
            this.pathParts = this.pathParts.slice(0, idx + 1);
            this.bfdRequest.fullPath = this.pathParts.slice(2, idx + 1).join('/');
        }

        this.performSearch(this.bfdRequest.searchText);
    }

    public handleDirClick(row: BfdFile) {
        if (this.bfdRequest.fullPath === this.pathRoot) {
            this.bfdRequest.fullPath = row.path;
        } else {
            this.bfdRequest.fullPath += '/' + row.path;
        }

        this.pathParts.concat(row.path);
        this.performSearch(this.bfdRequest.searchText);
    }

    public handlePageEvent(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.page = e.pageIndex;

        this.performSearch(this.bfdRequest.searchText);
    }

    public handleSortEvent(s: Sort) {
        // Sort object when sorting is turned off: { active: "name", direction: "" }
        if (s.direction) {
            this.bfdRequest.sortField = s.active;
            this.bfdRequest.sortDir = s.direction;
            // Reset the page back to 0
            this.bfdRequest.page = 0;

            this.performSearch(this.bfdRequest.searchText);
        } else {
            // Do not fetch if sorting has been turned off
            this.bfdRequest.sortField = null;
            this.bfdRequest.sortDir = null;
        }
    }

    public performSearch($event: string) {
        this.bfdRequest.searchText = $event;
        this.bfdRequest.page = this.page;
        this.bfdRequest.pageSize = this.pageSize;
        this.bfdFiles = [];

        this.fetching = true;
        this.bfdFileExplorerService.get(this.bfdRequest).subscribe({
            next: (res: any) => {
                // setTimeout() fixes this error: Expression has changed after it was checked.
                setTimeout(() => {
                    this.fetching = false;
                });
                if (!res) {
                    return;
                }
                this.bfdResponse = res.body;
                if (!this.pathHome) {
                    this.pathHome = this.bfdResponse.s3FilesBucket;
                }

                this.pathParts = [this.pathDisplayRoot, this.pathHome];

                if (this.bfdRequest.fullPath != this.pathRoot) {
                    this.pathParts = this.pathParts.concat(this.bfdRequest.fullPath.split('/'));
                }

                const temp: BfdFile[] = [];
                let name: string;
                let displayName: string;
                if (this.bfdResponse.files.length > 0) {
                    const contentRange = res.headers.get('content-range'); // "1-3/3"
                    this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);

                    this.bfdResponse.files.forEach((file: ApiFile) => {
                        let ext =
                            file.name?.lastIndexOf('.') >= 0
                                ? file.name?.substring(file.name?.lastIndexOf('.') + 1)
                                : '-';
                        // if the extension is numeric or more than 5 characters,
                        // then it is a bad extension and just display the default.
                        if (ext.length >= 5 || +ext >= 0) {
                            ext = '-';
                        }

                        if (this.bfdRequest.searchText) {
                            if (this.bfdRequest.fullPath.indexOf(this.pathRoot) === 0) {
                                name = file.name;
                            } else {
                                name = file.name.substring(this.bfdRequest.fullPath.length + 1);
                            }
                        } else {
                            name =
                                file.name?.lastIndexOf('/') >= 0
                                    ? file.name?.substring(file.name?.lastIndexOf('/') + 1)
                                    : file.name;
                        }

                        if (name.trim().length === 0) {
                            return;
                        }

                        if (file.isDir || ext !== '-') {
                            displayName = name;
                        } else if (ext === '-') {
                            // If not a directory and there is no valid extension,
                            // then default it to .txt.
                            ext = 'txt';
                            displayName = name + '.txt';
                        }

                        temp.push({
                            name,
                            displayName,
                            ext,
                            path: file.name,
                            isDir: file.isDir,
                            lastModified: file.lastModified,
                            size: file.size,
                        });
                    });
                }

                this.bfdFiles = temp;
            },
            error: (error) => this.handleError(error),
        });
    }

    public handleRowsSelected(downloadList: DownloadList) {
        let name: string;

        Object.keys(downloadList).forEach((filename) => {
            if (this.bfdResponse.fullPath === this.pathRoot) {
                // this file is at the root level so do not pre-pend the full path.
                name = filename;
            } else {
                name = this.bfdResponse.fullPath + '/' + filename;
            }

            // Make sure the name does not already exist from the root level or with
            // the full path prepended to avoid adding a filename that does not exist.
            if (
                downloadList[filename].selected &&
                this.selectedFiles.indexOf(name) < 0 &&
                this.selectedFiles.indexOf(filename) < 0
            ) {
                this.selectedFiles.push(name);
            } else if (!downloadList[filename].selected && this.selectedFiles.indexOf(name) >= 0) {
                this.selectedFiles.splice(this.selectedFiles.indexOf(name), 1);
            }
        });
    }

    public handleDownloadClick() {
        if (!this.downloadInProgress) {
            this.downloadInProgress = true;
        } else if (this.selectedFiles.length > 0) {
            let filename = 'temp.xlsx';
            this.bfdFileExplorerService.download(this.selectedFiles).subscribe({
                next: (res: any) => {
                    const errorMessage = `${res?.statusText}`;

                    switch (res.status) {
                        case 200:
                        case 204:
                            filename = res.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0];
                            if (!filename) {
                                filename = 'default filename ' + new Date().toISOString();
                            }

                            break;
                        case 400:
                        case 401:
                        case 403:
                        default:
                            throw new Error(errorMessage);
                    }

                    let binaryData = [];
                    binaryData.push(res.body);
                    let downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: res.type }));
                    if (filename) {
                        downloadLink.setAttribute('download', filename);
                    }
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    this.selectedFiles = [];
                    this.downloadInProgress = false;
                },
                error: (error) => this.handleError(error),
            });
        } else {
            this.downloadInProgress = false;
        }
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        this.selectedFiles = [];
        this.downloadInProgress = false;

        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
        });
    }
}
