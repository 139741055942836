import { Component, OnInit } from '@angular/core';
import {
    AdminRequestHelper,
    Auth0APIConfig,
    DealRecord,
    DealSearchColumns,
    GetType,
    IntAdmPermissions,
    InternalAdminService,
    ModalCloseConfig,
    ModalConfigData,
    RequestDealUserRole,
    RoleRecord,
    ShareholderDealUser,
    ShareholderRecord,
    SingleFullDealRecord,
    UserActionConfig,
    UserActionEnum,
    UserDealRel,
    UserRecord,
    UserSearchColumns,
} from '../shared';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { InternalAdminModalComponent } from '../internal-admin-modal/internal-admin-modal.component';
import { ConfigurationService } from '../../core/services/configuration.service';
import { AccessTypes, AuthService } from '../../core/services/auth.service';
import { AvailableColumns } from '../shareholder-deal-user-table/shareholder-deal-user-table.columns.types';
import { UserAvailableColumns } from '../user-table/user-table.column.types';
import { DealAvailableColumns } from '../deal-table/deal-table.column.types';

@Component({
    selector: 'app-deals',
    templateUrl: './deals.component.html',
    styleUrls: ['./deals.component.scss'],
})
export class DealsComponent implements OnInit {
    public dealRecords: DealRecord[];
    public userRecords: UserRecord[];
    public addExistingUserRecords: UserRecord[];
    public shareholderRecords: ShareholderRecord[];
    public userDealRelRecords: UserDealRel[];
    public roleRecords: RoleRecord[];
    public revokeAccessUser: UserRecord;
    public showRevokeAccess: boolean;
    // Single Deal ng-component
    public selectedDealId: number;
    public selectedDeal: SingleFullDealRecord;

    // Multiple Deals ng-component
    public tabAll = 'all';
    public tabMine = 'mine';
    public tabSelected: string = this.tabAll;

    public searchValue: string;
    public searchValueUsers: string;
    public page: number;
    public pageUsers: number;
    public pageDealsUsers: number;
    public pageSize: number;
    public pageSizeUsers: number;
    public pageSizeDealsUsers: number;
    public totalRecords: number;
    public totalRecordsUsers: number;
    public totalRecordsDealsUsers: number;
    public showCreateUser: boolean = false;
    public userToEdit: UserRecord;
    public editMode: boolean;
    public addExistingUserMode: boolean;
    public internalAdminModal: MatDialogRef<InternalAdminModalComponent>;
    public permissionCreateUser = IntAdmPermissions.userCreate;
    public permissionAddUser = IntAdmPermissions.userAdd;
    public fetching = true;
    public fetchingDealsUsers = true;
    public shareholderDealUserRecords: ShareholderDealUser[];
    public searchValueDealsUsers: string;
    public shareholderDealUserDisplayedColumns: string[] = [
        AvailableColumns.User,
        AvailableColumns.Permission,
        AvailableColumns.Shareholder,
        AvailableColumns.Actions,
    ];
    public userDisplayedColumns: string[] = [
        UserAvailableColumns.User,
        UserAvailableColumns.Role,
        UserAvailableColumns.Email,
        UserAvailableColumns.Status,
        UserAvailableColumns.Actions,
    ];
    public multipleDealsDisplayedColumns: string[] = [
        DealAvailableColumns.NsId,
        DealAvailableColumns.Name,
        DealAvailableColumns.ProjectName,
        DealAvailableColumns.Seller,
        DealAvailableColumns.Buyer,
        DealAvailableColumns.ClosingDate,
        DealAvailableColumns.Actions,
    ];
    public selectedIndex = 0;

    private requestHelper: AdminRequestHelper;
    private requestHelperUsers: AdminRequestHelper;
    private requestHelperDealsUsers: AdminRequestHelper;
    private requestHelperRoles: AdminRequestHelper;

    private userModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '500px',
        minHeight: '200px',
        autoFocus: 'first-header',
    };

    constructor(
        private internalAdminService: InternalAdminService,
        private snackBar: MatSnackBar,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private configurationService: ConfigurationService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.showRevokeAccess = true;
        this.tabSelected = this.tabAll;
        this.requestHelper = new AdminRequestHelper(GetType.deals);
        this.requestHelperUsers = new AdminRequestHelper(GetType.users);
        this.requestHelperDealsUsers = new AdminRequestHelper(GetType.shareholders);
        this.requestHelperRoles = new AdminRequestHelper(GetType.roles);
        this.page = this.requestHelper.page;
        this.pageUsers = this.requestHelperUsers.page;
        this.pageDealsUsers = this.requestHelperDealsUsers.page;
        this.pageSize = this.requestHelper.size;
        this.pageSizeUsers = this.requestHelperUsers.size;
        this.pageSizeDealsUsers = this.requestHelperDealsUsers.size;

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                if (!params) {
                    return;
                } else if (params['clear']) {
                    this.selectedDeal = null;
                    this.selectedDealId = null;
                } else if (params['tabSelected']) {
                    this.selectedDealId = null;
                    this.selectedDeal = null;

                    this.tabSelected = null;
                    this.tabClick(params['tabSelected']); // Let tabClick() handle setting correct values and retrieving deals.
                } else if (params['dealId']) {
                    this.selectedDealId = params['dealId'];
                    this.fetchSingleDeal(this.selectedDealId);

                    this.requestHelperUsers.did = params['dealId'];
                    this.requestHelperUsers.page = 0;
                    this.fetchUsers();
                }
            },
            error: (error) => this.handleError(error),
        });

        this.fetchDeals();
        this.fetchRoles();
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;

        if (this.searchValue && this.searchValue.trim().length > 0) {
            this.requestHelper.srchVal = this.searchValue;
            this.requestHelper.srchFields = DealSearchColumns;
        } else {
            this.requestHelper.srchVal = null;
            this.requestHelper.srchFields = null;
        }
        // What if they had already initiated a search, paged to page N, then
        // adjusted the search value. Should it be reset to 0 again?
        this.requestHelper.page = 0;

        this.fetchDeals();
    }

    public performSearchUsers(searchVal: string) {
        this.searchValueUsers = searchVal;

        if (this.searchValueUsers && this.searchValueUsers.trim().length > 0) {
            this.requestHelperUsers.srchVal = this.searchValueUsers;
            this.requestHelperUsers.srchFields = UserSearchColumns;
        } else {
            this.requestHelperUsers.srchVal = null;
            this.requestHelperUsers.srchFields = null;
        }
        // What if they had already initiated a search, paged to page N, then
        // adjusted the search value. Should it be reset to 0 again?
        this.requestHelperUsers.page = 0;

        this.fetchUsers();
    }

    public tabClick(tabName: string) {
        if (this.tabSelected === tabName) {
            return;
        }

        this.tabSelected = tabName;

        if (this.tabSelected === this.tabAll) {
            // If fetching all deals clear out searching for deals by id, deal id, user id or shareholder id
            this.requestHelper.id = 0;
            this.requestHelper.uid = 0;
            this.requestHelper.did = 0;
            this.requestHelper.sid = 0;
        } else if (this.tabSelected === this.tabMine) {
            this.requestHelper.uid = +this.userService.user.sub;
        }

        this.fetchDeals();
    }

    public handlePageEvent(e: PageEvent) {
        this.requestHelper.size = e.pageSize;
        this.requestHelper.page = e.pageIndex;

        this.fetchDeals();
    }

    public handleUserToEditEvent(e: UserRecord) {
        this.userToEdit = e;
    }

    public handleEditModeEvent(e: boolean) {
        this.editMode = e;
        this.showCreateUser = true;
    }

    public handleUsersPageEvent(e: PageEvent) {
        this.requestHelperUsers.size = e.pageSize;
        this.requestHelperUsers.page = e.pageIndex;

        this.fetchUsers();
    }

    public handleSortChange(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelper.sortField = s.active;
            this.requestHelper.sortDir = s.direction;
            // Reset the page back to 0
            this.requestHelper.page = 0;

            this.fetchDeals();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelper.sortField = null;
            this.requestHelper.sortDir = null;
        }
    }

    public handleUsersSortChange(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelperUsers.sortField = s.active;
            this.requestHelperUsers.sortDir = s.direction;
            // Reset the page back to 0
            this.requestHelperUsers.page = 0;

            this.fetchUsers();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperUsers.sortField = null;
            this.requestHelperUsers.sortDir = null;
        }
    }

    public handleDealSelected(did: number) {
        this.requestHelperUsers.did = did;
        this.requestHelperUsers.page = 0;
        this.requestHelperDealsUsers.did = did;
        this.requestHelperDealsUsers.page = 0;

        this.fetchSingleDeal(did);
        this.fetchUsers();
        this.fetchShareholders();
        this.router.navigate(['/internal-admin/deals'], {
            queryParams: { clear: null },
            queryParamsHandling: 'merge',
        });
    }

    public handleUserSelected(uid: number) {
        const temp = this.userRecords.find((user) => user.id === uid);

        this.router.navigate(['/internal-admin/users'], {
            queryParams: { userId: uid },
        });
    }

    public handleUserAction(config: UserActionConfig) {
        switch (config.action) {
            case UserActionEnum.activity: {
                this.router.navigate(['/internal-admin/activity'], {
                    queryParams: {
                        userName:
                            this.userRecords[config.arrayIndex].firstName +
                            ' ' +
                            this.userRecords[config.arrayIndex].lastName,
                    },
                });

                break;
            }
            case UserActionEnum.resetMfa: {
                const name =
                    this.userRecords[config.arrayIndex].firstName + ' ' + this.userRecords[config.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.userModalConfigTemplate,
                    data: {
                        title: 'Reset MFA Method?',
                        messageHTML: `Would you like to reset the Multi-Factor Authentication method for <b>${name}</b>?`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.resetMfa,
                        id: this.userRecords[config.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.sendPwReset: {
                const userName =
                    this.userRecords[config.arrayIndex].firstName + ' ' + this.userRecords[config.arrayIndex].lastName;

                const tempConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.userModalConfigTemplate,
                    data: {
                        title: 'Send Password Reset?',
                        messageHTML: `Would you like to send a password reset for <b>${userName}</b>?`,
                        userName,
                        dealName: null,
                        action: UserActionEnum.sendPwReset,
                        id: this.userRecords[config.arrayIndex].id,
                        email: this.userRecords[config.arrayIndex].email,
                    },
                };
                this.openModal(tempConfig);

                break;
            }
            case UserActionEnum.resendInv: {
                const name =
                    this.userRecords[config.arrayIndex].firstName + ' ' + this.userRecords[config.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.userModalConfigTemplate,
                    data: {
                        title: 'Resend Invitation',
                        messageHTML: `Would you like to resend the Deal Dashboard invitation to <b>${name}</b>?`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.resendInv,
                        id: this.userRecords[config.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.deactivate: {
                const name =
                    this.userRecords[config.arrayIndex].firstName + ' ' + this.userRecords[config.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.userModalConfigTemplate,
                    data: {
                        title: 'Confirm Account Deactivation',
                        messageHTML: `This will revoke all access and deactivate <b>${name}</b>.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.deactivate,
                        id: this.userRecords[config.arrayIndex].id,
                        deactivateFromUserTable: true,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.block: {
                const name =
                    this.userRecords[config.arrayIndex].firstName + ' ' + this.userRecords[config.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.userModalConfigTemplate,
                    data: {
                        title: 'Confirm Authentication Block',
                        messageHTML: `This will “block” the user in Auth0 without modifying deal permissions.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.block,
                        id: this.userRecords[config.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.unblock: {
                const name =
                    this.userRecords[config.arrayIndex].firstName + ' ' + this.userRecords[config.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.userModalConfigTemplate,
                    data: {
                        title: 'Unblock Authentication',
                        messageHTML: `This will remove the Blocked status in Auth0, allowing users to authenticate.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.unblock,
                        id: this.userRecords[config.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
        }
    }

    public handleUserClickedEventDealsUsers(uid: number) {
        // will need to pass in the real uid when it exists on the record
        this.router.navigate(['/internal-admin/users'], {
            queryParams: { userId: this.userRecords[0].id },
        });
    }

    public handleShareholderClickedEventDealsUsers(sid: number) {
        this.router.navigate(['/internal-admin/shareholders'], {
            queryParams: { sid: sid },
        });
    }

    public handlePageEventDealsUsers(e: PageEvent) {
        this.requestHelperDealsUsers.size = e.pageSize;
        this.requestHelperDealsUsers.page = e.pageIndex;

        this.fetchShareholders();
    }

    public handleSortChangeDealsUsers(s: Sort) {
        if (s.direction) {
            this.requestHelperDealsUsers.sortField = s.active;
            this.requestHelperDealsUsers.sortDir = s.direction;
            // Reset the page back to 0
            this.requestHelperDealsUsers.page = 0;

            this.fetchShareholders();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperDealsUsers.sortField = null;
            this.requestHelperDealsUsers.sortDir = null;
        }
    }

    public createUser() {
        this.handleEditModeEvent(false);
        this.showCreateUser = true;
    }

    public addExistingUser() {
        this.handleEditModeEvent(false);
        this.showCreateUser = true;
        this.addExistingUserMode = true;
        this.requestHelperUsers.did = 0;
        this.internalAdminService.getUsers(this.requestHelperUsers).subscribe({
            next: (res: any) => {
                this.addExistingUserRecords = res.body;
                for (let i = 0; i < this.userRecords.length; i++) {
                    const index = this.addExistingUserRecords.findIndex((user) => user.id === this.userRecords[i].id);
                    this.addExistingUserRecords.splice(index, 1);
                }
                this.addExistingUserRecords.sort((a, b) => a.firstName.localeCompare(b.firstName));
            },
            error: (error) => this.handleError(error),
        });
    }

    public handleAddExistingUserButtonClick() {
        this.closeSliderPanel();
        this.addExistingUser();
    }

    public handleNewUserButtonClick() {
        this.closeSliderPanel();
        this.createUser();
    }

    public handleRevokeAccessEvent(user: UserRecord) {
        this.revokeAccessUser = user;
        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.userModalConfigTemplate,
            data: {
                title: 'Confirm Revoke User Access',
                action: UserActionEnum.revokeAccess,
                messageHTML: "This will remove the user's assigned role to this deal.",
                id: user.id,
            },
        };
        this.openModal(modalConfig);
    }

    public closeSliderPanel() {
        this.showCreateUser = false;
        this.addExistingUserMode = false;
        this.requestHelperUsers.did = this.selectedDealId;
        this.fetchUsers();
    }

    private fetchSingleDeal(did: number) {
        this.selectedDealId = did;

        // retrieve all values for a single deal record, which has joins to other
        // tables not required by the deals table. Since pagination, sorting, and
        // searching are not required, a new request helper is created to leave the
        // original untouched.
        const tempRequestHelper = new AdminRequestHelper(GetType.deals);
        tempRequestHelper.id = did;

        this.internalAdminService.getDeals(tempRequestHelper).subscribe({
            next: (res: any) => {
                if (res.body && Array.isArray(res.body) && res.body.length === 1) {
                    this.selectedDeal = res.body[0];
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    public dealActivityClick(name?: string) {
        this.router.navigate(['/internal-admin/activity'], {
            queryParams: {
                dealName: name ? name : this.selectedDeal.name,
            },
        });
    }

    public dealDashboardClick(did?: number) {
        console.log('*** DealsComponent.dealDashboardClick()');
        console.log('*** DealsComponent.dealDashboardClick()');
    }

    public handleDealActivityNavigation($event) {
        const selectedDeal = this.dealRecords[$event];
        this.dealActivityClick(selectedDeal.name);
    }

    public handleDealDashboardNavigation($event) {
        const selectedDeal = this.dealRecords[$event];
        this.dealDashboardClick(selectedDeal.id);
    }

    public openInNewTab($event, url: string) {
        // This is to prevent the click event from propagating up.
        $event?.stopPropagation();
        $event?.preventDefault();
        window.open(url, '_blank').focus();
    }

    public getDealNsUrl(nsId: number): string {
        const nsContactUrl = this.configurationService.envConfig.getValue().nsCustomerUrl;

        return nsContactUrl && nsId ? nsContactUrl.replace(/INSERTIDHERE/, nsId.toString()) : '';
    }

    public getDealSfUrl(sfId: string): string {
        const sfOpportunityUrl = this.configurationService.envConfig.getValue().sfOpportunityUrl;

        return sfOpportunityUrl && sfId ? sfOpportunityUrl.replace(/INSERTIDHERE/, sfId) : '';
    }

    public showAddUserMenu(): boolean {
        return (
            this.authService.checkAccess(this.permissionCreateUser) === AccessTypes.FULL ||
            this.authService.checkAccess(this.permissionAddUser) === AccessTypes.FULL
        );
    }

    private fetchDeals() {
        this.fetching = true;
        this.internalAdminService.getDeals(this.requestHelper).subscribe({
            next: (res: any) => {
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.dealRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchUsers() {
        this.internalAdminService.getUsers(this.requestHelperUsers).subscribe({
            next: (res: any) => {
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsUsers = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.userRecords = res.body;
                if (this.selectedDealId) {
                    const userIds = this.userRecords.map((user) => user.id);
                    this.fetchUserDealRel(userIds);
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    /**
     * The "single shareholder" comes from the original list of shareholders,
     * so this search is to retrieve the linked deals and users.
     * @private
     */
    private fetchShareholders() {
        this.fetchingDealsUsers = true;

        this.internalAdminService.getShareholdersDealsUsers(this.requestHelperDealsUsers).subscribe({
            next: (res: any) => {
                this.fetchingDealsUsers = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsDealsUsers = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.shareholderDealUserRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchUserDealRel(userIds: number[]) {
        const dealIds: number[] = [this.selectedDealId];
        this.internalAdminService.getUserDealRel(userIds, dealIds).subscribe({
            next: (res: any) => {
                this.userDealRelRecords = res.body;
                console.log(userIds);
                console.log(userIds.length);
                for (let i = 0; i < userIds.length; i++) {
                    const foundIndex = this.userRecords.findIndex((deal) => deal.id === userIds[i]);
                    console.log(this.userDealRelRecords.find((userDealRel) => userDealRel.child_id == userIds[i]));
                    const foundRoleId = this.userDealRelRecords.find(
                        (userDealRel) => userDealRel.child_id == userIds[i],
                    ).role_id;
                    this.userRecords[foundIndex].role = this.roleRecords.find((role) => role.id == foundRoleId).name;
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchRoles() {
        this.internalAdminService.getRoles(this.requestHelperRoles).subscribe({
            next: (res: any) => {
                this.roleRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.internalAdminModal = this.matDialog.open(InternalAdminModalComponent, modalConfig);
        this.internalAdminModal.componentInstance.confirmButtonEvent.subscribe((emmitedValue: ModalCloseConfig) => {
            this.internalAdminModal.close();

            const auth0Config: Auth0APIConfig = {
                userId: emmitedValue.id,
                action: modalConfig.data.action,
                byId: this.userService.user.sub,
                byName: this.userService.user.name,
            };

            switch (emmitedValue.action) {
                case UserActionEnum.resetMfa: {
                    this.internalAdminService.userAuth0(auth0Config).subscribe({
                        next: (res: any) => {
                            if (res.status >= 200 && res.status <= 204) {
                                this.snackBar.open(
                                    `${modalConfig.data.userName}'s multi-factor authentication method has been reset.`,
                                    undefined,
                                    {
                                        duration: 6000,
                                    },
                                );
                            } else {
                                const msg = `Unable to reset the MFA method for ${modalConfig.data.userName}. Error: ${res.message}`;
                                console.error(msg);
                                this.snackBar.open(msg, undefined, {
                                    duration: 10000,
                                });
                            }
                        },
                        error: (error) => this.handleError(error),
                    });

                    break;
                }
                case UserActionEnum.sendPwReset: {
                    this.internalAdminService.userAuth0(auth0Config).subscribe({
                        next: (res: any) => {
                            if (res.status >= 200 && res.status <= 204) {
                                const msg = `Email sent with password reset link.`;
                                this.snackBar.open(msg, undefined, {
                                    duration: 6000,
                                });
                            } else {
                                const msg = `Unable to send password reset for ${modalConfig.data.userName}. Error: ${res.message}`;
                                console.error(msg);
                                this.snackBar.open(msg, undefined, {
                                    duration: 10000,
                                });
                            }
                        },
                        error: (error) => this.handleError(error),
                    });

                    break;
                }
                case UserActionEnum.resendInv: {
                    this.internalAdminService.userAuth0(auth0Config).subscribe({
                        next: (res: any) => {
                            if (res.status >= 200 && res.status <= 204) {
                                this.snackBar.open(`Invitation to ${modalConfig.data.userName} resent`, undefined, {
                                    duration: 6000,
                                });
                            } else {
                                const msg = `Unable to resend the invitation to ${modalConfig.data.userName}. Error: ${res.message}`;
                                console.error(msg);
                                this.snackBar.open(msg, undefined, {
                                    duration: 10000,
                                });
                            }
                        },
                        error: (error) => this.handleError(error),
                    });

                    break;
                }
                case UserActionEnum.deactivate: {
                    this.internalAdminService.userAuth0(auth0Config).subscribe({
                        next: (res: any) => {
                            if (res.status >= 200 && res.status <= 204) {
                                this.snackBar.open(`${modalConfig.data.userName} has been deactivated.`, undefined, {
                                    duration: 6000,
                                });

                                if (this.selectedDeal) {
                                    this.fetchUsers();
                                }
                            } else {
                                const msg = `Unable to deactivate ${modalConfig.data.userName}. Error: ${res.message}`;
                                console.error(msg);
                                this.snackBar.open(msg, undefined, {
                                    duration: 10000,
                                });
                            }
                        },
                        error: (error) => this.handleError(error),
                    });

                    break;
                }
                case UserActionEnum.block: {
                    this.internalAdminService.userAuth0(auth0Config).subscribe({
                        next: (res: any) => {
                            if (res.status >= 200 && res.status <= 204) {
                                this.snackBar.open(`User Authentication Blocked`, undefined, {
                                    duration: 6000,
                                });

                                if (this.selectedDeal) {
                                    this.fetchUsers();
                                }
                            } else {
                                const msg = `Unable to block ${modalConfig.data.userName}. Error: ${res.message}`;
                                console.error(msg);
                                this.snackBar.open(msg, undefined, {
                                    duration: 10000,
                                });
                            }
                        },
                        error: (error) => this.handleError(error),
                    });

                    break;
                }
                case UserActionEnum.unblock: {
                    this.internalAdminService.userAuth0(auth0Config).subscribe({
                        next: (res: any) => {
                            if (res.status >= 200 && res.status <= 204) {
                                this.snackBar.open(`User Authentication Unblocked`, undefined, {
                                    duration: 6000,
                                });

                                if (this.selectedDeal) {
                                    this.fetchUsers();
                                }
                            } else {
                                const msg = `Unable to unblock ${modalConfig.data.userName}. Error: ${res.message}`;
                                console.error(msg);
                                this.snackBar.open(msg, undefined, {
                                    duration: 10000,
                                });
                            }
                        },
                        error: (error) => this.handleError(error),
                    });

                    break;
                }
                case UserActionEnum.revokeAccess: {
                    const config: RequestDealUserRole = {
                        dealId: this.selectedDealId,
                        userId: this.revokeAccessUser.id,
                        byId: this.userService.user.sub,
                        byName: this.userService.user.name,
                    };
                    this.internalAdminService.deleteUserFromDeal(config).subscribe({
                        next: (res: any) => {
                            if (res.status >= 200 && res.status <= 204) {
                                this.closeSliderPanel();
                                const msg = 'User access revoked';
                                this.snackBar.open(msg, undefined, {
                                    duration: 10000,
                                });
                            } else {
                                const msg = 'Error occurred while revoking access';
                                console.error(msg);
                                this.snackBar.open(msg, undefined, {
                                    duration: 10000,
                                });
                            }
                        },
                        error: (error) => this.handleError(error),
                    });
                }
            }
        });
        this.internalAdminModal.componentInstance.cancelButtonEvent.subscribe((emmitedValue) => {
            if (emmitedValue) {
                this.internalAdminModal.close();
            }
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        error['stack'] ? console.error(error['stack']) : console.error(error);

        let msg: string;
        if (error?.status >= 400 && error?.status < 500) {
            msg = error?.error?.message ? error.error.message : 'Server error';
        } else {
            msg = 'Server error';
        }

        this.snackBar.open(msg, undefined, {
            duration: 10000,
        });
    }
}
