<div id="lender-management-container">
    <div *ngIf="showTable" class="tablesContainer">
        <div class="tableContainer">
            <app-lender-table
                [TableTitle]="lendersTitle"
                [TableData]="lenderListItems"
                [TableHeaders]="TableHeaders"
                [TableActions]="tableActions"
                [RowActions]="rowActions"
                [DetailActions]="detailActions"
            ></app-lender-table>
        </div>
    </div>
</div>
