<header class="admin__header">
    <mat-toolbar color="primary">
        <div class="left-action">
            <h1 class="admin-icon-header">
                <img [src]="'assets/SRSA Logo KO.png'" alt="SRSA logo"/>
<!--                <a class="home_link" href="/"> Admin </a>-->
            </h1>
            <span id="export-span"
                  class="material-symbols-outlined app-menu"
                  (click)="toggleSidebar()"
                  *ngIf="(isAuthenticated$ | async)"
            >menu</span>
        </div>
        <div class="right-action">
            <button
                mat-flat-button
                color="primary"
                (click)="logout()"
                class="button"
                *ngIf="(isDoneLoading$ | async) && (isAuthenticated$ | async)"
            >
                Log Out
            </button>
            <button
                mat-flat-button
                color="primary"
                (click)="login()"
                class="button"
                *ngIf="(isDoneLoading$ | async) && !(isAuthenticated$ | async)"
            >
                Log In
            </button>
        </div>
    </mat-toolbar>
</header>
