<div class="lance">
    <nav mat-tab-nav-bar  mat-stretch-tabs="false" [tabPanel]="tabPanel">
        <ng-container *ngFor="let link of links">
          <a *appFlags="link.flag" mat-tab-link [routerLink]=link.route
          [active]="activeLink === link.route">{{link.label}}</a>
        </ng-container>
    </nav>
    <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
    </mat-tab-nav-panel>
</div>
