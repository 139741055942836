<mat-card>
    <mat-card-title>{{ lenderModalData.title }}</mat-card-title>
    <div class="card__container">
        <ng-container
            *ngTemplateOutlet="lenderModalData.action === 'delete' ? deleteLenderSection : createEditLenderSection"
        ></ng-container>
    </div>
</mat-card>

<ng-template #createEditLenderSection>
    <mat-card-content>
        <div class="account-details">
            <form class="account-form" [formGroup]="lenderForm" (ngSubmit)="saveLender()">
                <div class="account-info">
                    <mat-form-field class="lender_field">
                        <mat-label>WSO Lender ID</mat-label>
                        <input matInput type="number" formControlName="wsoLenderID" />
                        <mat-error *ngIf="lenderForm.controls.wsoLenderID.hasError('required')">
                            WSO Lender ID is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="lenderForm.controls.wsoLenderID.hasError('pattern')">
                            WSO Lender ID must be a <strong>number</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="lender_field">
                        <mat-label>Tax Form Type</mat-label>
                        <mat-select formControlName="taxForm" >
                            <mat-option *ngFor="let taxForm of taxFormlist" [value]="taxForm.value">
                                {{ taxForm.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="lender_field">
                        <mat-label>Legal Name of Lending Entity</mat-label>
                        <input matInput type="text" formControlName="lenderName" />
                        <mat-error *ngIf="lenderForm.controls.lenderName.hasError('required')">
                            Lender Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="lenderForm.controls.lenderName.hasError('maxlength')">
                            Lender Name must be less than 40 characters
                        </mat-error>
                    </mat-form-field>
                    <span class="file-upload-modal">
                        <app-file-upload formControlName="taxFileUpload" [fileType]="'*'" [buttonText]="buttonTextToDisplay"
                                         [isDisabledFlag]="lenderForm.controls.taxForm.value === ''" [tempFilename]="primaryTaxFormName"></app-file-upload>
                    </span>
                </div>
                <div>
                    <span class="lender_field checkbox-padding">
                    <mat-checkbox formControlName="isEntity" checked>Is Entity?</mat-checkbox>
                    </span>
                </div>
            </form>
        </div>

        <mat-divider></mat-divider>

    </mat-card-content>
    <mat-card-actions align="end">
        <button type="submit"  mat-flat-button color="primary" [disabled]="true">Bulk Upload</button>

        <button
            mat-flat-button
            [color]="(areChangesSaved | async) ? 'primary' : 'warn'"
            (click)="closeModal()"
            [matTooltip]="modalTooltip"
            [matTooltipDisabled]="areChangesSaved | async"
            matTooltipPosition="before"
        >
            Cancel
        </button>
        <button type="submit" (click)="saveLender()" mat-flat-button color="primary" [disabled]="!lenderForm.valid">Submit</button>
    </mat-card-actions>
</ng-template>

<ng-template #deleteLenderSection>
</ng-template>
