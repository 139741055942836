<mat-card appearance="outlined" class="mat-card">
    <div id="title-bar" class="title-bar">
        <mat-card-title
            [class.mat-card-title-create]="modalData.action === 'create'"
            [class.mat-card-title-edit-delete]="modalData.action === 'edit' || modalData.action === 'delete' || modalData.action === 'export'"
        >
            {{ modalData.title }}
        </mat-card-title>
        <span id="x-dismiss"
              (click)="closeModal()"
              class="material-symbols-outlined x-dismiss"
              data-testid="x-dismiss">
            close
        </span>
    </div>
    <div *ngIf="modalData.action !== 'export'" class="card__container">
        <ng-container
            *ngTemplateOutlet="createEditBrokerageAccountSection"
        ></ng-container>
    </div>
    <div *ngIf="modalData.action === 'export'" class="card__container">
        <ng-container
            *ngTemplateOutlet="exportSection"
        ></ng-container>
    </div>
</mat-card>

<ng-template #createEditBrokerageAccountSection>
    <mat-card-content>
        <div *ngIf="modalData.action === 'create'" id="subtitle" class="subtitle">Common Brokerage</div>
        <div class="account-details">
            <form class="account-form" [formGroup]="form" (ngSubmit)="submit()" (keydown.enter)="submit()">
                <div class="account-info">
                    <mat-form-field subscriptSizing="dynamic" class="brokerage_account_field" data-testid="countryCodeMFF">
                        <mat-label>Country/ Country Code</mat-label>
                        <input id="countryCodeInput" matInput type="text" formControlName="countryCode" data-testid="countryCode"/>
                        <mat-error *ngIf="form.controls.countryCode.hasError('required')">
                            Country Code is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="form.controls.countryCode.hasError('minlength')">
                            Country Code must be 2 characters
                        </mat-error>
                        <mat-error *ngIf="form.controls.countryCode.hasError('maxlength')">
                            Country Code must be 2 characters
                        </mat-error>
                        <mat-error *ngIf="form.controls.countryCode.hasError('pattern')">
                            Country Code must be alphabetic
                        </mat-error>
                        <mat-error *ngIf="form.controls.countryCode.hasError('lexisnexis')">
                            {{ lexisnexisComment }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="account-info">
                    <mat-form-field class="brokerage_account_field" data-testid="brokerageNameMFF">
                        <mat-label>Brokerage Account Name</mat-label>
                        <input id="brnmInput" matInput type="text" formControlName="brokerageName" data-testid="brokerageName"/>
                        <mat-error *ngIf="form.controls.brokerageName.hasError('required')">
                            Brokerage Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="form.controls.brokerageName.hasError('maxlength')">
                            Brokerage Name must be less than 100 characters
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="account-info">
                    <mat-form-field class="brokerage_account_field">
                        <mat-label>Brokerage Account Number</mat-label>
                        <input matInput type="text" formControlName="brokerageAccountNumber" data-testid="brokerageAccountNumber"/>
                        <mat-error *ngIf="form.controls.brokerageAccountNumber.hasError('required')">
                            Brokerage Account is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="form.controls.brokerageAccountNumber.hasError('maxlength')">
                            Brokerage Account must be less than 50 characters
                        </mat-error>
                        <mat-error *ngIf="form.controls.brokerageAccountNumber.hasError('lexisnexis')">
                            {{ lexisnexisComment }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="account-info">
                    <mat-form-field class="brokerage_account_field">
                        <mat-label>Bank Routing Number</mat-label>
                        <input matInput type="text" formControlName="bankRoutingNumber" data-testid="bankRoutingNumber"/>
                        <mat-error *ngIf="form.controls.brokerageAccountNumber.hasError('required')">
                            Bank Routing Number is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="form.controls.bankRoutingNumber.hasError('maxlength')">
                            Bank Routing Number must be less than 50 characters
                        </mat-error>
                        <mat-error *ngIf="form.controls.bankRoutingNumber.hasError('lexisnexis')">
                            {{ lexisnexisComment }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="account-info">
                    <mat-form-field class="brokerage_account_field">
                        <mat-label>Bank Name (Optional)</mat-label>
                        <input matInput type="text" formControlName="bankName" data-testid="bankName"/>
                        <mat-error *ngIf="form.controls.bankName.hasError('maxlength')">
                            Bank Name must be less than 100 characters
                        </mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div *ngIf="bankValidationResultsJson" id="ln-response-json" class="response-json" data-testid="ln-response-json">
                <span class="response-json-header" data-testid="response-json-header">LexisNexis response {{ bankValidationStatus }}</span>
                <div id="response-json-box" class="response-json-box" data-testid="response-json-box">
                    <pre id="response-json-pre" class="response-json-pre" data-testid="response-json-box">{{ bankValidationResultsJson | json }}</pre>
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button
            class="modal-button"
            mat-flat-button
            (click)="closeModal()"
            [matTooltip]="modalTooltip"
            [matTooltipDisabled]="areChangesSaved | async"
            matTooltipPosition="before"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary"
            [class.modal-button-primary-disabled] = "isDisabled"
            [disabled]="isDisabled"
            type="submit"
            (click)="submit()"
            mat-flat-button
            data-testid="btnSave"
        >
            <span *ngIf="validating" class="icon-button">
                <span class="material-symbols-outlined">autorenew</span>
                <span class="modal-button-label">{{validateText}}</span>
            </span>
            <span *ngIf="saveWithError" class="icon-button">
                <span class="material-symbols-outlined">info</span>
                <span class="modal-button-label">{{saveWithErrorText}}</span>
            </span>
            <span *ngIf="!validating && !saveWithError" class="icon-button">
                <span class="modal-button-label">{{submitButtonText}}</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>

<ng-template #exportSection>
    <form [formGroup]="exportForm">
        <mat-card-content class="exportas-card-content">
            <div class="exportas-label">Export as:</div>
            <div class="exportas-data-div">
                <div class="filename-frame">
                    <div class="filename-outer-div">
                        <div class="filename-inner-div">
                            <div class="filename-input">
                                <input class="filename-value" id="filenameInput" matInput type="text" formControlName="filename" data-testid="filename"/>
                                <span class="filename-affordance">X</span>
                            </div>
                        </div>
                    </div>
                    <div class="filename-extension">
                        <div class="extension-text-field">
                            <span class="extension-input">.xlsx</span>
                        </div>
                    </div>
                </div>
                <mat-error *ngIf="exportForm.controls.filename.hasError('required')">
                    Filename is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="exportForm.controls.filename.hasError('maxLength')">
                    Maximum length is 150 characters.
                </mat-error>
            </div>
        </mat-card-content>
    </form>
    <mat-card-actions align="end">
        <button
            class="modal-button modal-button-cancel"
            mat-flat-button
            (click)="closeModal()"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary-export"
            type="submit"
            [matTooltip]="modalTooltip"
            matTooltipPosition="after"
            (click)="submit()"
            mat-flat-button
            data-testid="btnSave"
        >
            <span class="icon-button">
                <span class="modal-button-label">Export</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>
