import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../core/services/configuration.service';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';
import { IssuerAccountAssociation, IssuerAccountByIssuerID } from '../issuers/shared/issuers.types';

@Injectable({
    providedIn: 'root',
})
export class IssuerAccountsService {
    private serverName: string = 'Services Bank Payments API';

    constructor(
        private http: HttpClient,
        private configService: ConfigurationService,
    ) {}

    public getIssuerAccountsByIssuerID(issuerID: number): Observable<IssuerAccountByIssuerID[]> {
        const requestURL = `${
            this.configService.envConfig.getValue().bankPaymentsHost
        }/issuer-accounts/issuer/${issuerID}`;
        return this.http.get(requestURL).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public saveIssuerAccountAssociation(issuerID: number, accountID: number): Observable<IssuerAccountAssociation> {
        const requestURL = `${this.configService.envConfig.getValue().bankPaymentsHost}/issuer-accounts`;
        return this.http
            .post(requestURL, { issuer_id: issuerID, bank_account_id: accountID })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public deleteIssuerAccountAssociation(associationID: number): Observable<{}> {
        const requestURL = `${
            this.configService.envConfig.getValue().bankPaymentsHost
        }/issuer-accounts/${associationID}`;
        return this.http.delete(requestURL).pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
