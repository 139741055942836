import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LenderService } from './shared/lender.service';
import { DetailAction, RowAction, TableAction } from './lender-table/lender-table.component';
import { LenderModalComponent } from './lender-modal/lender-modal.component';
import {
    LenderTableListItem,
    LENDER_REVIEW_STATUSES,
    LenderTableHeaderTemplate,
    Lender,
    LenderModalConfigData,
    TAX_FORM_TYPES,
} from './shared/lenders.types';

@Component({
    selector: 'app-lender-management',
    templateUrl: './lender-management.component.html',
    styleUrls: ['./lender-management.component.scss'],
})
export class LenderManagementComponent implements OnInit, OnDestroy {
    public showTable: boolean;
    public lendersTitle = 'Lenders';
    public lenderListItems: LenderTableListItem[] = [];
    public result: boolean = false;
    public lenderModal: MatDialogRef<LenderModalComponent>;
    public resultMessage: string = '';
    private componentSubscriptions: Subscription[] = [];
    public tableActions: TableAction[] = [
        { backgroundColor: 'primary', action: this.addLender.bind(this), text: 'New' },
    ];
    public rowActions: RowAction[] = [
        { backgroundColor: 'primary', action: this.editRow.bind(this), icon: 'edit' },
        { backgroundColor: 'warn', action: this.deleteRow.bind(this), icon: 'delete' },
    ];
    public detailActions: DetailAction[] = [
        { text: 'Lender Details & Disclosures', action: this.detailsRow.bind(this) },
    ];
    public TableHeaders: LenderTableHeaderTemplate[] = [
        { label: 'WSO Id', value: 'wso_id' },
        { label: 'Lender Name', value: 'name' },
        { label: 'Primary Tax Form Type', value: 'primary_tax_form_type' },
        { label: 'Review Status', value: 'review_status_id' },
        { label: 'Notes', value: 'notes' },
    ];
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '900px',
        minHeight: '200px',
    };

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private lenderService: LenderService,
    ) {}

    ngOnInit(): void {
        this.showTable = true;

        this.getLenders();
    }

    getLenders() {
        this.result = false;
        this.resultMessage = '';
        this.lenderListItems = [];
        this.lenderService.getLenders().subscribe({
            next: (res: Lender[]) => {
                res.forEach((lender) => {
                    let tempLender: LenderTableListItem = {
                        id: lender.id,
                        wso_id: lender.wso_id,
                        name: lender.name,
                        primary_tax_form_type: lender.primary_tax_form_type,
                        review_status_id: lender.review_status_id,
                        notes: lender.notes,
                        is_entity: lender.is_entity,
                    };
                    for (let i = 0; i < LENDER_REVIEW_STATUSES.length; i++) {
                        if (tempLender.review_status_id == LENDER_REVIEW_STATUSES[i].value) {
                            tempLender.review_status_id = LENDER_REVIEW_STATUSES[i].label;
                            break;
                        }
                    }
                    this.lenderListItems.push(tempLender);
                });
            },
            error: (error: HttpErrorResponse) => {
                console.log('error', error);
            },
        });
    }

    ngOnDestroy() {
        for (let subscription of this.componentSubscriptions) {
            subscription.unsubscribe();
        }
    }

    public addLender() {
        const addRowConfig: MatDialogConfig<LenderModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Add New Lender Tax Information',
                action: 'create',
            },
        };
        this.openModal(addRowConfig);
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.lenderModal = this.matDialog.open(LenderModalComponent, modalConfig);
        this.lenderModal.afterClosed().subscribe((res: { updateData: boolean; areChangesSaved: boolean }) => {
            if (res.updateData) {
                setTimeout(() => {
                    this.snackBar.open('Fetching lender list after most recent edits', undefined, {
                        duration: 3000,
                    });
                    this.getLenders();
                }, 1500);
            }
        });
    }

    public editRow(data: LenderTableListItem) {
        const editRowConfig: MatDialogConfig<LenderModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: `Edit Lender (WSO id): ${data.wso_id}`,
                selectedLender: data,
                action: 'edit',
            },
        };
        this.openModal(editRowConfig);
    }

    public deleteRow(data: LenderTableListItem) {}

    public detailsRow(data: LenderTableListItem) {}
}
