<div id="accounts-container">
    <div class="selectBankContainer">
        <mat-form-field>
            <mat-select
            [formControl]="bankControl"
                placeholder="Bank"
                id="singleSelectId">
                <mat-option>
                    <ngx-mat-select-search
                        placeholderLabel="Search bank..."
                        [formControl]="bankFilterCtrl"
                    ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                    {{ bank.display_name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ng-container *ngIf="showAccounts" >
        <div class="newAccountContainer">
            <button type="submit" (click)="addAccount()" mat-flat-button color="primary">New Account</button>
        </div>
        <div *ngFor="let account of availableBankAccounts"  class="accounts">
            <div class="p-button-rounded newbutton p-mr-2">
                <mat-card appearance="outlined">
                    <mat-card-title>
                        <div class="card-div">
                            Account Name: {{account.name}}    
                        </div>
                        <div>
                            <button mat-mini-fab color="primary" (click)="editAccount(account)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div >
                            <button mat-mini-fab color="warn" (click)="deleteAccount(account)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-card-title>

                    <mat-card-content>
                        <table class="tableContainer" >
                            <colgroup>
                                <col span="1" style="width: 50%;">
                                <col span="1" style="width: 50%;">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td> Account Number: {{account.number}} </td>
                                    <td> Address Line1: {{account.address1}}</td>
                                </tr>
                                <tr>
                                    <td> Currency: {{account.currency}}</td>
                                    <td> Address Line2: {{account.address2}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td> Address Line3: {{account.city}}<ng-container *ngIf="account.city">,</ng-container> 
                                        {{account.state}} {{account.zip}}</td>
                                </tr>                            
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div> 
        </div>
    </ng-container>
</div>
