import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../core/services/configuration.service';
import { BrokerageAccount, ExportConfig } from './brokerage-account.types';

@Injectable({
    providedIn: 'root',
})
export class BrokerageAccountsService {
    private serverName: string = 'Common Brokerage Accounts API';

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    public get(): Observable<BrokerageAccount[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().brokerageAccountsHost,
            'brokerage-accounts',
            this.configurationService.envConfig.getValue().brokerageAccountsApiVer,
        ].join('/');

        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public add(account: BrokerageAccount): Observable<BrokerageAccount> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().brokerageAccountsHost,
            'brokerage-accounts',
            this.configurationService.envConfig.getValue().brokerageAccountsApiVer,
        ].join('/');

        return this.http
            .post(requestUrl, account, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public delete(accountID: number): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().brokerageAccountsHost,
            'brokerage-accounts',
            this.configurationService.envConfig.getValue().brokerageAccountsApiVer,
            accountID,
        ].join('/');

        return this.http.delete(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public update(account: BrokerageAccount): Observable<BrokerageAccount> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().brokerageAccountsHost,
            'brokerage-accounts',
            this.configurationService.envConfig.getValue().brokerageAccountsApiVer,
        ].join('/');

        return this.http
            .put(requestUrl, account, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public export(exportConfig: ExportConfig): Observable<Response> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().brokerageAccountsHost,
            'brokerage-accounts',
            this.configurationService.envConfig.getValue().brokerageAccountsApiVer,
            'export',
        ].join('/');

        return this.http
            .post(requestUrl, exportConfig, {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
